.docCard {
  position: relative;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  padding: 2rem;
  border-radius: 1rem;

  .purpleShape {
    position: absolute;
    width: 25%;
    height: 55%;
    left: 0.5rem;
    top: 0.4375rem;
    border-radius: 0.5rem;
  }

  .greenShape {
    position: absolute;
    width: 11%;
    height: 48%;
    right: 0.5rem;
    top: 5.7975rem;
    border-radius: 0.5rem;
  }

  .docName {
    font-size: 1.25rem;
    font-weight: 700;
  }
}

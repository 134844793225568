@import "src/index.scss";

.confirmCode {
  display: flex;
  align-items: center;
  justify-content: center;

  .confirmCodeForm {
    text-align: center;
    background-color: $gray-6;
    width: 40%;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    .alertError {
      text-align: left;
      button {
        width: auto;
      }
    }

    .underlinedLink {
      text-decoration: underline;
      cursor: pointer;
    }

    .formInputs {
      display: flex;
      flex-direction: column;
      gap: 0.75rem;
    }

    .codeInput {
      text-align: left;
    }

    button {
      width: 100%;
    }
    .confirmCodeBtn {
      width: 100%;
      text-align: center;
      margin-top: 2rem;
      .spanBtn {
        color: $primary-1_dark;
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
}

@media (max-width: $tabletBreakpoint) {
  .confirmCode {
    .confirmCodeForm {
      width: 100%;
      margin: 0 0.625rem;
    }
  }
}

@import "src/index.scss";

section.emergencyNumbers {
  @media (min-width: $tabletBreakpoint) and (max-width: 81.25rem) {
    padding: 1.5rem 0;
	}
  max-width: 75rem;
  margin-bottom: 1.5rem;
  flex-direction: row;
  @media only screen and (max-width: $tabletBreakpoint) {
    flex-direction: column;
    padding-left: 0;
    padding-right: 0;
  }
  justify-content: space-between;
  flex-wrap: nowrap !important;
  gap: 1.5rem;
  padding: 1.5rem;
  padding-bottom: 1.5rem;

  > [class*="card"]:first-child {
    width: clamp(30.625rem, 57.5%, 43.0625rem);
    flex-direction: column;
    padding: 2.5rem;
    background-color: $secondary-1;
    [class*="textContainer"] {
      width: clamp(18.75rem, 94.9%, 35.875rem);
      h4 {
        margin-bottom: 0.625rem;
      }
      p {
        margin-bottom: 1.5rem;
      }
    }

    [class*="imageContainer"] {
      width: clamp(18.75rem, 77.5%, 28.375rem);
    }
  }

  .cards {
    width: clamp(18.75rem, 40.5%, 30.4375rem);
    margin: auto 0;
    padding: 2.5rem 2.125rem;
    height: min-content;
    border-radius: 2rem;
    background-color: #f9f8ff;

    > [class*="card"] {
      width: 100%;
      flex-direction: row-reverse;
      border-radius: 0.75rem;
      padding: 0.75rem;
      justify-content: start;
      background-color: $bg_main;
      &:not(:last-child) {
        margin-bottom: 0.75rem;
      }
      [class*="textContainer"] {
        width: 100%;
        ul {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          li {
            height: min-content;
            
          }
          li:first-child {
            width: clamp(11.875rem, 59.7%, 12.6875rem);
            margin-right: auto;
            font-size: 0.8125rem;
            font-weight: 600;
            line-height: 1.0156rem;
          }
          li:nth-child(2) {
            max-width: 8.125rem;
            padding: 0.1875rem 0.5rem;
            border-radius: 0.3125rem;
            font-size: 0.8125rem;
            font-weight: 500;
            color: #035b4e;
            background-color: #d1f2de;
          }

          li:nth-child(3) {
            font-size: 0.875rem;
            font-weight: 500;
          }
        }
      }

      &:last-child {
        ul {
          li:last-child {
            margin-top: 0.5rem;
            max-width: 100%;
            font-size: 0.875rem;
            font-weight: 500;
            padding: 0;
            background-color: $bg_main;
            color: black;
          }
        }
      }

      [class*="imageContainer"] {
        margin: auto 0;
        img {
          width: 2.75rem;
          height: 2.75rem;
        }
      }
    }
  }

  @media only screen and (max-width: $tabletBreakpoint) {
    margin-bottom: 4rem;

    > [class*="card"]:first-child {
      width: 100%;
      margin-bottom: 1.25rem;
      padding: 2rem 1.25rem;
      [class*="textContainer"] {
        width: 100%;
      }

      [class*="imageContainer"] {
        width: 90%;
        max-width: none;
      }
    }

    .cards {
      width: 100%;
      padding: 1.25rem;
      > [class*="card"] {
        li {
          width: 100%;
          margin-bottom: 0.5rem;
        }

        &:last-child {
          ul {
            li:last-child {
              margin-top: 0;
            }
          }
        }
      }
    }
  }
}

@import "src/index.scss";
main {
  max-width: 100vw;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0 0.5rem;
  @media only screen and (max-width: $tabletBreakpoint) {
    padding: 0 1rem;
  }
}
.aboutUs {
  @media (max-width: 81.25rem) {
    margin: 0 3.125rem;
  }
  @media (max-width: $mobileBreakpoint) {
    margin: 0 auto;
  }
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  * {
    text-align: initial;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  margin: 0 auto;
  max-width: 85rem;
  justify-content: center;
  box-sizing: border-box;
  background-color: white;
  [class*="introductorySection"] {
    [class*="card"] {
      [class*="textContainer"] {
        [class*="p"]{
          margin: 0 !important;
        }
      }
    }
  }

  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 85rem;
    margin: 0 auto;
  }

  > [class*="Footer"] {
    margin: 0 auto;
    margin-bottom: 1.5rem;
    padding: 2.5rem 4.25rem;
    @media only screen and (max-width: $tabletBreakpoint) {
      padding: 1.25rem;
    }
  }

  //stains
  [class*="introductorySection"] {
    h1{
      text-align: center;
    }
    overflow-x: hidden;
    @media only screen and (max-width: $tabletBreakpoint) {
      [class*="card"] {
        padding-bottom: 0;
      }
    }
    > [class*="stain"] {
      width: 5rem;
      top: 2.5rem;
      left: 96%;
    }
    [class*="TextContainer"] {
      [class*="stain"] {
        &:nth-last-child(2) {
          width: 11%;
          top: 1.25rem;
          left: -2.5rem;
          transform: rotate(155deg);
        }
        &:last-child {
          width: 31.4%;
          right: -31.4%;
          top: 2.5rem;
        }
      }
      @media only screen and (max-width: $tabletBreakpoint) {
        overflow: hidden;
        p {
          margin-bottom: 3.125rem;
        }
        [class*="stain"] {
          &:nth-last-child(2) {
            width: 11%;
            bottom: -1.125rem;
            left: 0;
          }
          &:last-child {
            width: 16.25rem;
            right: -3.75rem;
            top: initial;
            bottom: -4.375rem;
            transform: rotate(10deg);
          }
        }
      }

      @media only screen and (max-width: 23.4375rem) {
        [class*="stain"] {
          &:nth-last-child(2) {
            width: 21%;
          }
        }
      }
    }
  }
}

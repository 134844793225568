@import "src/index.scss";

.register {
  padding: 7rem 7.5rem 5rem;
  position: relative;

  .pwInfo {
    font-size: 0.75rem;

    ul {
      margin: 0;
      padding-left: 0.75rem;
    }
  }

  .privacyDisclaimer {
    font-size: 0.625rem;
  }

  .registerCard {
    background-color: #f7fafd;
    padding: 2.5rem;
    display: flex;
    justify-content: space-between;
    align-items: start;
    border-radius: 2rem;

    .instruction {
      width: 36%;
      display: flex;
      flex-direction: column;
      gap: 2.5rem;

      .topPart {
        display: flex;
        flex-direction: column;
        align-items: start;
        gap: 0.625rem;

        span {
          font-size: 2rem;
          line-height: 2.4rem;
          font-weight: 600;
          text-align: start;
        }

        p {
          text-align: start;
          font-size: 1rem;
          font-weight: 400;
          line-height: 1.6rem;
          color: #3d3762;
        }
      }

      .bottomPart {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;

        .bottomRow {
          display: flex;
          align-items: center;
          justify-content: start;
          text-align: start;
          gap: 0.75rem;

          .iconWrapper {
            flex-shrink: 0;
            box-sizing: border-box;
            width: 2.75rem;
            height: 2.75rem;
            padding: 0.625rem;
            border-radius: 6.25rem;
            background-color: $secondary-1;
            color: $primary-1;
          }
        }

        .questionCardImage {
          max-width: 75%;
          width: 100%;
          margin: 0 auto;
          object-fit: cover;
        }
      }
    }

    .form {
      text-align: left;
      width: 40%;
      background-color: $bg_main;
      padding: 2rem 1.5rem;
      border-radius: 1rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 2rem;

      .inputsWrapper {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .textInputWrapper {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
        }

        .checkboxWrapper {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;

          .checkbox {
            div {
              color: $gray-2;
              border: 0;
              padding: 0;
              text-align: start;
              height: fit-content;

              &:focus-within {
                border: 0;
                padding: 0;
                outline: 0;
              }
            }
          }
        }

        .btn {
          padding: 0.625rem 1.25rem;
        }
      }

      .login {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;

        .spacer {
          border-top: 0.0625rem solid #ebebec;
          width: 6.25rem;
        }

        div {
          font-size: 1rem;
          color: #3d3762;
        }
      }
    }
  }

  .purpleShape,
  .greenShape {
    position: absolute;
  }

  .greenShape {
    width: 7.9375rem;
    height: 5.4375rem;
    left: 19%;
    top: 3%;
  }

  .purpleShape {
    width: 5.875rem;
    height: 6.75rem;
    left: 37%;
    top: 82%;
  }
}

.passwordInput {
  position: relative;

  .showPw {
    cursor: pointer;
    color: $primary-1;
    font-weight: 600;
    font-size: 0.875rem;
    position: absolute;
    right: 1rem;
  }
}

@media (max-width: $tabletBreakpoint) {
  .register {
    position: relative;
    padding: 1.25rem 1rem;
    padding-top: calc(10vh + 0.625rem);
    background-color: $blue_bg;

    .registerError {
      max-width: 23.75rem;
    }

    .registerCard {
      padding: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 2rem;

      .instruction {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        margin-bottom: 1rem;

        .topPart {
          display: flex;
          flex-direction: column;
          align-items: start;
          gap: 0.625rem;

          span {
            line-height: normal;
            font-size: 1.5rem;
            font-weight: 600;
            text-align: start;
          }

          p {
            display: none;
          }
        }

        .bottomPart {
          display: flex;
          flex-direction: column;
          gap: 1rem;

          .bottomRow {
            .iconWrapper {
              flex-shrink: 0;
              box-sizing: border-box;
              width: 2.75rem;
              height: 2.75rem;
              padding: 0.625rem;
              border-radius: 6.25rem;
              background-color: $secondary-1;
              color: $primary-1;
            }
          }

          .questionCardImage {
            display: none;
          }
        }
      }

      .form {
        width: calc(100% - 2rem);
        background-color: $bg_main;
        padding: 1.25rem 1rem;
        border-radius: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2rem;

        .inputsWrapper {
          display: flex;
          flex-direction: column;
          gap: 1rem;

          .textInputWrapper {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
          }

          .checkboxWrapper {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;

            .checkbox {
              div {
                color: $gray-2;
                border: 0;
                padding: 0;
                text-align: start;
                height: fit-content;

                &:focus-within {
                  border: 0;
                  padding: 0;
                  outline: 0;
                }
              }
            }
          }

          .btn {
            padding: 0.625rem 1.25rem;
          }
        }

        .login {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 1rem;

          .spacer {
            border-top: 0.0625rem solid #ebebec;
            width: 6.25rem;
          }

          div {
            font-size: 1rem;
            color: #3d3762;
          }
        }
      }
    }

    .purpleShape,
    .greenShape {
      display: none;
      position: absolute;
    }
  }
  .passwordInput {
    position: relative;

    .showPw {
      cursor: pointer;
      color: $primary-1;
      font-weight: 600;
      font-size: 0.875rem;
      position: absolute;
      right: 1rem;
    }
  }
}

@import "src/index.scss";
.footer {
	display: flex;
	flex-direction: column;
	width: clamp(17.5rem, 100%, 75rem);
	margin: 0 auto 2.5rem;
	padding: 2.5rem 4.25rem;
	border-radius: 2rem;
	background-color: $gray_bg;

	.footerTop {
		display: flex;
		justify-content: space-between;
		margin-bottom: 2.25rem;
		.footerTopLeft {
			[class*="ImageContainer"] {
				width: 11rem;
				height: 3.6875rem;
				margin-bottom: 1.5rem;
			}
			[class*="TextContainer"] {
				max-width: 17.8125rem;
				p {
					font-size: 0.875rem;
					color: $gray-2;
				}
			}
		}
		.footerTopRight {
			width: 23rem;
			display: flex;
			justify-content: space-between;
			font-size: 0.875rem;
			color: $gray-1;
			[class*="TextContainer"] {
				ul {
					li:not(:first-child) {
						cursor: pointer;
					}
					li:first-child {
						font-weight: 600;
					}
					li:not(:last-child) {
						margin-bottom: 1.25rem;
					}
				}
				&:last-child {
					li:nth-child(3) {
						color: #c3012f;
					}
				}
			}
		}
	}

	.footerBottom {
		display: flex;
		padding-top: 0.9375rem;
		font-size: 0.875rem;
		justify-content: space-between;
		border-top: 0.0625rem solid #d5d5db;
		color: $gray-1;
		.footerBottomLeft {
			[class*="TextContainer"] {
				ul {
					padding: 0;
					display: flex;
					line-height: 2rem;
					li:not(:last-child) {
						margin-right: 1.5rem;
					}
				}
			}
		}
		.footerBottomRight {
			display: flex;
			gap: 0.75rem;
			span {
				margin-right: 0.375rem;
				line-height: 2rem;
			}
			[class*="ImageContainer"] {
				gap: 0.75rem;
				> div {
					display: flex;
					justify-content: center;
					align-items: center;
					width: 2rem;
					border-radius: 50%;
					background-color: $secondary-1;
					img {
						width: 0.875rem;
						height: 0.875rem;
						cursor: pointer;
					}
					li:not(:last-child) {
						margin-right: 1.5rem;
					}
				}
			}
		}
	}

	@media only screen and (max-width: $tabletBreakpoint) {
		&.footerMargin {
			margin-bottom: 3.75rem !important;
		}

		padding: 1.25rem;
		.footerTop {
			flex-direction: column;
			.footerTopLeft {
				margin: 0 auto 2.25rem auto;
				display: flex;
				flex-direction: column;
				[class*="ImageContainer"] {
					width: 11rem;
					height: 3.6875rem;
					margin: 0 auto 1.5rem auto;
				}
				p {
					text-align: center;
				}
			}
			.footerTopRight {
				width: 100%;
				margin: 0 auto;
			}
		}
		.footerBottom {
			padding: 0;
			flex-direction: column;
			.footerBottomLeft {
				order: 2;
				ul {
					flex-wrap: wrap;
					justify-content: center;
				}
			}
			.footerBottomRight {
				flex-direction: column;
				margin-bottom: 2.25rem;
				span {
					text-align: center;
				}
				div {
					height: 2rem;
					justify-content: center;
				}
			}
		}
	}
}

@import "src/index.scss";

.chatPage {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  gap: 1.25rem;
  box-sizing: border-box;
  background-color: $blue_bg;

  .onboardingTitle {
    font-size: 1.5rem;
    font-weight: 600;
  }

  .onboardingButton {
    width: fit-content;
    align-self: center;
  }

  .medicalDocumentsContainer {
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;

    &::-webkit-scrollbar {
      display: none;
    }

    .name {
      font-size: 2rem;
      font-weight: 600;
      line-height: 2.375rem;
      text-align: start;
      padding: 1rem;
    }
  }

  .connectDescription {
    font-size: 1rem;
  }

  .stripeWarningButton {
    align-self: center;
    width: fit-content;
  }

  .therapistOnboarding {
    padding: 2.5rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    background-color: #ffffff;
    text-align: start;
    border-radius: 1rem;
  }

  .mobileHeader {
    display: none;
  }

  .chatWrapper {
    width: 56vw;
  }
  .chatDocSheet {
    width: 23vw;
  }
}

@media (max-width: $tabletBreakpoint) {
  .chatPage {
    padding: 0;

    .onboardingTitle {
      font-size: 1.25rem;
    }

    .medicalDocumentsContainer {
      margin-top: 4.375rem;

      .name {
        font-size: 1.5rem;
        text-align: center;
        padding: 1rem 0;
      }
    }

    .chatMenu {
      position: absolute;
      top: 0.625rem;
      left: -85%;
      width: 85%;
      z-index: 99;
      background-color: $bg_main;
      transition: transform 1.5s;
      transition-timing-function: ease;
      &.open {
        transform: translate(100%);
      }
    }

    .chatWrapper {
      margin-top: 4.375rem;
    }

    .mobile {
      margin-top: 0;
    }

    .chatDocSheet {
      display: none;

      &.open {
        display: block;
        position: fixed;
        bottom: 0;
        left: 0;
        top: 4.375rem;
        width: calc(100% - 2rem);
        height: calc(100dvh - 6.25rem);
        padding: 1rem;
        background-color: $bg_main;
        z-index: 2;
      }
    }
  }
}

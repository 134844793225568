@import "src/index.scss";

.form {
  width: clamp(17.5rem, 50%, 34.9375rem);
  display: flex;
  flex-wrap: wrap;
  .spinner {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .errorForm {
    width: 100%;
    display: flex;
    align-items: center;
    [class*="genericForm"] {
      padding: 1.25rem;
      justify-content: space-between;

      [class*="description"],
      [class*="title"] {
        text-align: center;
      }
    }
  }
  > div {
    span {
      margin-bottom: 0.25rem;
      font-size: 0.875rem;
      font-weight: 500;
      letter-spacing: 0em;
      text-align: left;
      color: #808093;
    }
  }
  .inputName,
  .inputSurname {
    width: clamp(1.1374rem, 49%, 17.1875rem);
  }
  .inputName {
    margin-right: auto;
  }

  .inputEmail,
  .textArea {
    width: 100%;
  }
  .inputEmail {
    margin: 1.5rem 0;
  }
  .textArea {
    width: 100%;
    textarea {
      min-height: 11.4375rem;
    }
  }

  button {
    margin-top: 1rem;
    width: 9.6875rem;
    margin-bottom: 2.5rem;
    > [class*="Button_icon"] {
      display: flex;
      margin: 0;
      margin-left: 1.125rem;
      width: 1.5rem;
      height: 1.25rem;
    }
  }

  @media only screen and (max-width: $tabletBreakpoint) {
    width: 100%;
    .inputName,
    .inputSurname {
      width: 100%;
    }
    .inputName {
      margin-bottom: 1.5rem;
    }
    button {
      width: 100%;
    }
  }
}

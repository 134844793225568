@import "src/index.scss";


.firstAppointmentBookingContainer {
  padding: 10vh 1rem;
  font-size: 1rem;
  line-height: 1.4375rem;
  text-align: start;
  color: $gray-text-lighter;
  background: #f7fafd;
  display: flex;
  justify-content: center;

  .warning {
    text-align: center;
  }

  .suggestedTherapistsWrapper {
    display: flex;
    justify-content: space-around;
    gap: 2rem;
    width: 100%;
    overflow-x: auto;
    flex-shrink: 0;
  }

  .appointmentBooking {
    box-sizing: border-box;
    display: flex;
    padding: 2.5rem 3.75rem;
    gap: 4.375rem;
    background: white;
    margin-top: 1.5rem;
    border-radius: 0.9375rem;
    height: fit-content;
    max-width: 81.25rem;
    width: 100%;

    .therapistContainer {
      display: flex;
      flex-direction: column;
      gap: 3rem;
      position: sticky;
      top: 3.125rem;
      width: 100%;
      max-width: 31.25rem;
      height: fit-content;

      .therapistInfoWrapper {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        max-width: 23.75rem;
        width: 100%;
      }

      .therapistInfoContainer.suggestedTherapists {
        height: 100%;
      }

      .therapistInfoContainer {
        display: flex;
        flex-direction: column;
        position: relative;
        overflow: hidden;
        border-radius: 0.9375rem;
        border: 0.0625rem solid #d1f2de;
        padding: 1.25rem;
        gap: 1rem;

        &:before {
          position: absolute;
          top: -2.5rem;
          left: -1.25rem;
          scale: 1.2;
          z-index: 0;
          content: url("../../theme/images/firstAppointmentBlue.svg");
        }

        &:after {
          position: absolute;
          bottom: -1.25rem;
          right: -1.25rem;
          scale: 1.2;
          z-index: 0;
          content: url("../../theme/images/firstAppointmentGreen.svg");
        }

        .therapistWrapper {
          position: relative;
          z-index: 2;
          display: flex;
          justify-content: space-between;
          gap: 1rem;


          .therapistInfo {
            display: flex;
            flex-direction: column;

            .therapistName {
              font-weight: 700;
              text-transform: capitalize;
            }

            span {
              font-size: 0.75rem;
              font-weight: 600;
            }

            .therapistInfoCard {
              display: flex;
              gap: 0.5rem;
              width: fit-content;
              margin-top: 0.75rem;
              background: $secondary-2;
              color: $primary-2;
              padding: 0.375rem 0.625rem;
              border-radius: 0.625rem;
              font-size: 0.875rem;

              img {
                width: 1.5rem;
                height: 1.5rem;
              }

              .aboutDoc {
                font-size: 0.8125rem;
              }

              &.isPsychiatry {
                background: transparent;
              }
            }

            .experienceWrapper {
              display: flex;
              align-items: center;
              margin-top: 0.75rem;
              margin-left: 0.375rem;
              white-space: nowrap;

              span {
                font-size: 1rem;
                margin-right: 0.25rem;
              }

              img {
                width: 2rem;
                height: 2rem;
              }
            }
          }

          .profilePicture {
            width: 4.6875rem;
            height: 4.6875rem;
            object-fit: contain;
          }
        }

        .interventionArea {

          .title {
            font-weight: bold;
            margin-bottom: 0.5rem;
          }

          .areas {
            display: flex;
            flex-wrap: wrap;
            gap: 1rem;
          }

          .areasBtn {
            font-size: 0.875rem;
            background-color: $gray_bg;
            color: $gray-2;

            border: 0;
            border-radius: 1.5625rem;
            padding: 0.5rem 0.9375rem;
          }
        }

        .infoBlock {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          position: relative;
          z-index: 2;

          .graduate {

            .graduateTitle {
              font-weight: bold;
            }
          }
        }

        .changeTherapistButton {
          font-size: 0.875rem;
          padding: 0.75rem;
          margin-top: 1.5rem;
          width: fit-content;
          z-index: 2;

          &.temporary {
            margin-top: auto;
          }
        }

        .infoBlockButton {
          position: relative;
          z-index: 2;
          align-self: end;
          display: none;
        }
      }

      .appointmentWrapper {
        display: flex;
        flex-direction: column;
        align-content: center;
        flex-wrap: wrap;
        align-items: stretch;
        border-radius: 0.9375rem;
        border: 0.0625rem solid #d1f2de;
        padding: 1.25rem;
        gap: 1rem;

        .appointmentDateWrapper {
          display: flex;
          gap: 0.5rem;
          flex-wrap: wrap;
          justify-content: center;
          align-self: center;
          text-transform: capitalize;

          .appointmentDate {
            display: flex;
            gap: 0.5rem;
          }

          span {
            display: flex;
            column-gap: 0.5rem;
            white-space: nowrap;
            font-weight: 600;
          }

          .appointmentName {
            font-weight: bold;
            white-space: nowrap;
          }
        }

        .appointmentButton {
          width: fit-content;
          align-self: center;
        }
      }
    }

    .selfBookingHeaderWrapper {
      display: flex;
      width: 100%;
      justify-content: space-between;

      .button {
        width: 1.5rem;
        height: 1.5rem;
        padding: 0;
      }

      .selfBookingHeader {
        display: flex;
        flex-direction: column;
        align-items: center;

        .title {
          font-weight: bold;
        }
      }
    }

    .selfBookingContainer {
      border-radius: 0.9375rem;
      border: 0.125rem solid #9EBBFF;
      padding: 1.25rem;
      width: 100%;

      .selfBookingChatContainer {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .date {
          color: #737882;
          background-color: #f1f2f4;
          margin: 0 auto;
          padding: 0.25rem 0.625rem;
          border-radius: 1.25rem;
          text-align: center;
        }

        .messageWrapper {
          display: flex;
          flex-direction: column;

          .message {
            background-color: #e7e1fe;
            border-radius: .625rem .625rem .625rem 0;
            margin: .3125rem auto .3125rem .3125rem;
            max-width: 80%;
            padding: 1rem;
            word-break: break-word;
          }

          .hour {
            text-align: start;
            font-weight: 400;
            font-size: 0.8125rem;
            color: $gray-4;
          }
        }

        .slotsContainer {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          max-width: 70%;
          width: 100%;
          margin: 1rem auto 0;

          .dateWrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            font-weight: 400;
            line-height: 1rem;
            gap: 0.25rem;

            .slotDate {
              font-weight: 600;
            }
          }

          .button {
            padding: 10px 24px;
            border-radius: 1.375rem;
            height: auto;
            gap: 0.5rem;
            font-weight: 500;
            background-color: #C4C4C4;
            width: fit-content;
            align-self: center;
            margin-top: 1rem;
          }
        }

        .Input {
          max-height: 3.375rem;
          display: flex;
          align-items: stretch;
          padding: 0.625rem 0 0.625rem 0.625rem;
          border: 0.125rem solid $border_color;
          border-radius: 1.6875rem;
          margin: 5rem 1rem 0 1rem;

          textarea {
            min-height: auto !important;
            max-height: 3.375rem !important;
          }
        }

        .appointmentWrapper {
          display: none;
        }

      }
    }

    .appointmentBlock {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1.5rem;
      width: 100%;
    }

    .appointmentContainer {
      border-radius: 0.9375rem;
      border: 0.0625rem solid $secondary-1;
      padding: 1.25rem;
      width: 100%;

      .appointmentTitle {
        margin: 1rem 0;
      }

      .appointmentHeader {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        margin-top: 1rem;
        margin-bottom: 1.5rem;
      }

      .appointmentBlockButton {
        margin: 2rem auto 0 auto;
      }
    }

    p {
      display: flex;
      font-size: medium;
      font-weight: bold;
      white-space: wrap;
    }

    h2 {
      margin-bottom: 1.25rem;
    }

    .dateCard {
      display: flex;
      width: 100%;
      flex-direction: column;
      gap: 1rem;

      .dateCardTitle {
        display: flex;
        gap: 0.5rem;
        text-transform: capitalize;
        padding-bottom: 0.5rem;
        border-bottom: 0.0625rem solid $secondary-1;

        span {
          font-weight: 600;
        }
      }

      .slotsContainer {
        display: grid;
        grid-template-columns: repeat(4, minmax(5.625rem, 1fr));
        grid-column-gap: 1rem;
        grid-row-gap: 1rem;
      }
    }

    .slot {
      display: flex;
      justify-content: center;
      padding: 0.75rem 0;
      width: 100%;
      border-radius: 0.75rem;
      cursor: pointer;
      font-weight: 600;
      box-sizing: border-box;
    }

    .slot.preview {
      border-radius: 20px;
    }

    .slot.psychiatry {
      border: 0.125rem solid #97E3D6;
    }

    .slot.single {
      border: 0.125rem solid $secondary-1;
    }

    .slot:hover {
      background-color: $secondary-1;
    }

    .slot.psychiatry:hover {
      background-color: #CDF5EF;
    }

    .slot.selected.psychiatry {
      background-color: #97E3D6;
    }

    .slot.selected.single {
      background-color: $secondary-1;
    }

    .slot.disabled {
      background-color: $gray-5;
      border-color: $gray-5 !important;
      color: $gray-4;
      cursor: not-allowed;
    }

    .slot.disabled:hover {
      background-color: $gray-5 !important;
    }
  }
}

@media (max-width: $tabletBreakpoint) {
  .firstAppointmentBookingContainer {

    &.isFirstRegister {
      padding: 10vh 1rem 1rem 1rem;
    }

    &.selectedDateTime {
      padding: 10vh 1rem 160px 1rem;
    }

    .suggestedTherapistsWrapper {
      flex-direction: column;
      justify-content: normal;
      height: fit-content;
      overflow-x: unset;
      gap: 1rem;
    }


    .billingModal {
      flex-direction: column;
      z-index: 10001;
    }

    .appointmentBooking {
      padding: 2.25rem 1.5rem 5rem;
      flex-direction: column-reverse;
      margin-top: 0.5rem;
      gap: 1.5rem;
      width: 100%;
      max-width: 43.75rem;

      &.isFirstRegister {
        padding: 2.25rem 1.5rem 1.5rem !important;
      }

      .therapistContainer {
        position: relative;
        top: 0rem;
        max-width: 43.75rem;

        .therapistInfoWrapper {
          max-width: unset;
          width: 100%;
        }

        .therapistInfoContainer {
          width: auto;

          &:after {
            bottom: -3.75rem;
          }


          .infoBlock {
            display: none;
          }

          .infoBlock.visible {
            display: flex;
          }

          .infoBlockButton {
            display: flex;
          }

          .changeTherapistButton {
            margin-top: 0;
          }
        }

        .therapistInfoContainer.visible {
          &:after {
            bottom: -1.25rem;
          }
        }

        .appointmentWrapper {
          position: fixed;
          bottom: 0rem;
          left: 0rem;
          width: calc(100vw - 2.625rem) !important;
          background: white;
          box-shadow: 0rem 0.25rem 3.375rem 0rem #0000000f;
          border: unset;
          border-top: 0.0625rem solid #d1f2de;
          z-index: 100;
        }
      }

      .appointmentBlock {
        width: 100%;
      }

      .appointmentContainer {
        width: auto;
      }

      .slot {
        padding: 10px 0 !important;
      }

      .dateCard {

        .slotsContainer {
          grid-template-columns: repeat(auto-fill, minmax(5.625rem, 1fr));
        }
      }

    }

    .selfBookingContainer {
      border: none !important;
      padding: 0 !important;

      .slotsContainer {

        margin: 0 !important;
        max-width: 100% !important;
      }
    }

    .Input {
      margin: 5rem 0 0 0rem !important;
    }
  }

}
@import "./../../index.scss";

.userProfile {
  flex-grow: 1;
  display: flex;
  background-color: $bg_main;
  border-radius: 1rem;
  .sections {
    width: 25%;
    min-width: 17.5rem;
    max-width: 17.5rem;
    position: relative;
    .topPart {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.25rem;
      margin: 2.5rem 0;
      button {
        width: fit-content;
      }
      .userName {
        font-size: 1.25rem;
        font-weight: 600;
        line-height: 1.5rem;
      }
      .userMail {
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.25rem;
      }
      button {
        text-decoration: underline;
        font-size: 0.875rem;
        font-weight: 600;
        line-height: 1.0625rem;
      }
    }
    .bottomPart {
      .reapeatOnboarding {
        position: absolute;
        bottom: 2.5rem;
        box-sizing: content-box;
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.1875rem;
        cursor: pointer;
        display: flex;
        justify-content: start;
        align-items: center;
        gap: 0.375rem;
        padding: 0.625rem 1.5rem;
        color: $primary-1-60;
        .icon {
          width: 1.25rem;
          height: 1.25rem;
        }
      }
      ul {
        margin: 0;
        padding: 0;
        li {
          height: 3.25rem;
          box-sizing: border-box;
          font-size: 1rem;
          font-weight: 500;
          line-height: 1.1875rem;
          cursor: pointer;
          display: flex;
          justify-content: start;
          align-items: center;
          gap: 0.375rem;
          padding: 0.625rem 1.5rem;
          color: $primary-1-60;
          &.active {
            background-color: $secondary-1;
            color: $gray-1;
          }
          .icon {
            width: 1.25rem;
            height: 1.25rem;
          }
        }
      }
    }
  }
  .exitBtn {
    display: flex;
    justify-content: center;
  }
  .infoContainer {
    display: flex;
    flex-grow: 1;
  }
}

@media (max-width: $tabletBreakpoint) {
  .userProfile {
    display: flex;
    background-color: $bg_main;
    border-radius: 1rem;

    .sections {
      display: none;
      width: 100%;
      height: 90vh;
      margin-top: 4.375rem;
      min-width: none;
      max-width: none;
      &.visibleSection {
        display: block;
      }
      .topPart {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
        margin: 2.5rem 0;
        .userName {
          font-size: 1.25rem;
          font-weight: 600;
          line-height: 1.5rem;
        }
        .userMail {
          font-size: 0.875rem;
          font-weight: 400;
          line-height: 1.25rem;
        }
        button {
          text-decoration: underline;
          font-size: 0.875rem;
          font-weight: 600;
          line-height: 1.0625rem;
        }
      }
      .bottomPart {
        ul {
          margin: 0;
          padding: 0;
          li {
            font-size: 1rem;
            font-weight: 500;
            line-height: 1.1875rem;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 0.375rem;
            padding: 0.625rem 1.5rem;
            color: $primary-1-60;
            &.active {
              background-color: transparent;
              color: $primary-1-60;
            }
            .icon {
              width: 1.25rem;
              height: 1.25rem;
            }
          }
        }
      }
    }
    .infoContainer {
      display: none;
      margin-top: 4.375rem;
      margin-bottom: 1rem;

      &.infoContainerIsVisible {
        display: flex;
      }
    }
  }
}

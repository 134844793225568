@import "../../../index.scss";

.savedCard {
  background-color: $gray_bg;
  border-radius: 0.75rem;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  &.selectedSavedCard {
    outline: 0.125rem solid $primary-1;
  }
  &.selectableSavedCard {
    cursor: pointer;
  }
  .actionButton {
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
  .actionButtonContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    .defaultLabel {
      font-size: 0.8125rem;
      border-radius: 0.25rem;
      padding: 0.1875rem 0.375rem;
      box-sizing: border-box;
      background-color: rgb(223, 234, 255);;
      color: $primary-1;
    }
  }
  .cardInfoContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    .cardInfo {
      display: flex;
      flex-direction: column;
      gap: 0.375rem;
    }
  }
}
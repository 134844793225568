@import "src/index.scss";

.fileWidget {
  border: 0.1875rem solid $primary-1_light;
  border-radius: 1rem;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 28.125rem;
  box-sizing: border-box;
  .widgetInfo {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    width: 60%;
  }
  .text {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.375rem;
    margin-bottom: 0;
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
  }
  .widgetFileDownload {
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.25rem;
    color: $primary-1_dark;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
}

@import "src/index.scss";

.login {
  position: relative;
  background-color: #f7fafd;
  display: flex;
  align-items: center;
  justify-content: center;
  .retrivePwForm{
    background-color: $bg_main;
  }

  .loginCard {
    width: 34%;
    background-color: $bg_main;
    padding: 2rem 1.5rem;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2rem;
    box-shadow: 0rem 0.25rem 3.375rem 0rem #0000000f;

    .titleWrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.75rem;
      .title {
        color: $gray-1;
      }
      .description {
        color: #3d3762;
        width: 70%;
      }
      .storageSupportDescription {
        color: orange;
        width: 90%;
      }
    }

    form {
      button {
        margin-top: 2rem;
        width: 100%;
      }
    }
    .inputsWrapper {
      display: flex;
      flex-direction: column;
      text-align: left;
      gap: 0.5rem;

      button {
        margin-top: 0;
        width: fit-content;
        justify-content: start;
        font-size: 0.875rem;
        font-weight: 400;
        text-decoration: underline;
        padding: 0;
      }

      .password {
        position: relative;
        .showPw {
          cursor: pointer;
          color: $primary-1_dark;
          font-weight: 600;
          font-size: 0.75rem;
          position: absolute;
          right: 1rem;
        }
      }
    }

    .register {
      font-size: 0.875rem;
      color: #3d3762;
      span {
        color: $primary-1_dark;
        text-decoration: underline;
        font-weight: 500;
        cursor: pointer;
      }
    }
  }
}

@media (max-width: $tabletBreakpoint) {
  .login {
    position: relative;
    background-color: #f7fafd;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;

    .retrivePwForm{
      margin: 0 0.625rem;
    }

    .loginCard {
      width: calc(100% - 1.25rem);
      margin: 0 0.625rem;
      background-color: $bg_main;
      padding: 2rem 1.5rem;
      border-radius: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 2rem;
      box-shadow: 0rem 0.25rem 3.375rem 0rem #0000000f;

      .titleWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.75rem;
        .title {
          color: $gray-1;
        }
        .description {
          color: #3d3762;
          width: 100%;
        }
        .storageSupportDescription {
          color: orange;
          width: 100%;
        }
      }

      .inputsWrapper {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        button {
          justify-content: start;
          font-size: 0.875rem;
          font-weight: 400;
          text-decoration: underline;
          padding: 0;
        }

        .password {
          position: relative;
          .showPw {
            cursor: pointer;
            color: $primary-1_dark;
            font-weight: 600;
            font-size: 0.75rem;
            position: absolute;
            right: 1rem;
          }
        }
      }

      .register {
        font-size: 0.875rem;
        color: #3d3762;
        span {
          color: $primary-1_dark;
          text-decoration: underline;
          font-weight: 500;
          cursor: pointer;
        }
      }
    }
  }
}

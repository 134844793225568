@import "src/index.scss";

.therapistRegister {
  padding: 5% 25%;
  padding-top: 0;
  margin-top: calc(5vh + 5%);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem;

  .pageNumber {
    width: 8.125rem;
  }

  .multiSelectError {
    background-color: $error-background;
    div {
      background-color: $error-background !important;
      outline-color: $error-outline !important;
      border-color: $error-border !important;
    }
  }

  .multiSelectErrorLabel {
    color: $error-text;
    font-size: 0.875rem;
  }

  .hiddenButton {
    display: none;
  }

  .formPage {
    display: flex;
    flex-direction: column;
    gap: 0.9375rem;
  }

  .form {
    width: 100%;
    box-sizing: border-box;
    .phoneInputWrapper {
      display: flex;
      align-items: flex-end;
      gap: 0.5rem;
      [class*="inputWrapper"]:first-child {
        width: 7.5rem;
        label {
          display: block;
        }
      }
      [class*="inputWrapper"]:last-child {
        flex: 1;
      }
    }

    .errorLabel {
      font-size: 0.875rem;
      text-align: start;
      color: #9f3a38;
      margin-top: -0.9375rem;
    }
    .tankyouMessage {
      padding-top: 1.5rem;
    }
    .note {
      font-size: 0.7rem;
    }
    .multiselect {
      font-size: 0.875rem;
      text-align: start;
      color: $gray-3;
    }
    .answrWrapper {
      display: flex;
      flex-direction: column;
      gap: 0.75rem;
      [class*="Input"] {
        height: auto !important;
      }
      .label {
        font-size: 0.875rem;
        text-align: start;
        color: $gray-3;
      }
      .multiSelectErrorLabel {
        color: $error-text;
      }
    }
  }

  .btnWrapper {
    padding-bottom: 5%;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    .progressBar {
      margin-top: 0.875rem;
      width: 50%;
    }
  }

  .outsideBtnWrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 0.5rem;
  }
}

@media (max-width: $mobileBreakpoint) {
  .therapistRegister {
    padding: 0 0.625rem;
    padding-top: calc(10vh + 2.5rem);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.75rem;
    .form {
      width: 100%;
      box-sizing: border-box;

      .phoneInputWrapper {
        display: flex;
        align-items: flex-end;
        gap: 0.5rem;
        [class*="inputWrapper"]:first-child {
          width: 7.5rem;
          label {
            display: block;
            width: 400%;
          }
        }
        [class*="inputWrapper"]:last-child {
          flex: 1;
        }
      }
      .answrWrapper {
        display: flex;
        flex-direction: column;
        gap: 0.75rem;
        .label {
          color: $gray-3;
        }
      }
    }

    .btnWrapper {
      padding: 0.625rem;
      width: calc(100% - 1.25rem);
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
      .progressBar {
        margin-top: 0.875rem;
        width: 50%;
      }
    }
    .outsideBtnWrapper {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      gap: 0.5rem;
    }
  }
}

@media (min-width: $mobileBreakpoint) and (max-width: $tabletBreakpoint) {
  .therapistRegister {
    padding: 0 0.625rem;
    padding-top: 0;
    margin-top: calc(10vh + 2.5rem);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.75rem;
    .form {
      width: 100%;
      box-sizing: border-box;
      .phoneInputWrapper {
        display: flex;
        align-items: flex-end;
        gap: 0.5rem;
        [class*="inputWrapper"]:first-child {
          width: 7.5rem;
          label {
            display: block;
            width: 400%;
          }
        }
        [class*="inputWrapper"]:last-child {
          flex: 1;
        }
      }
      .answrWrapper {
        display: flex;
        flex-direction: column;
        gap: 0.75rem;
        .label {
          color: $gray-3;
        }
      }
    }

    .btnWrapper {
      padding: 0.625rem;
      width: calc(100% - 1.25rem);
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
      .progressBar {
        margin-top: 0.875rem;
        width: 50%;
      }
    }
    .outsideBtnWrapper {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      gap: 0.5rem;
    }
  }
}

@import "src/index.scss";

.privacy {
  padding-left: 10%;
  padding-right: 10%;
  margin-bottom: 3.125rem;
  text-align: start;

  @media (max-width: 81.25rem) {
    padding-left: 3.125rem;
    padding-right: 3.125rem;
  }

  @media (max-width: $mobileBreakpoint) {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  span {
    font-weight: 600;
    &.underline {
      text-decoration: underline;
    }
  }
  h2 {
    text-align: center;
    @media (max-width: $mobileBreakpoint) {
      font-size: 1.75rem;
    }
  }
  .subtitle {
    text-align: center;
    margin-bottom: 2.5rem;
  }
  .subArticle {
    margin-left: 3.25rem;
    font-size: 1.125rem;
    font-weight: 600;
  }

  .articlesWrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-align: start;
    .article {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      .articleTitle {
        margin-left: 2rem;
        font-size: 1.25rem;
        font-weight: 600;
      }
      .articleDescription {
        .listLetter {
          list-style-type: lower-alpha;
        }
        .listRomanNumber {
          list-style-type: lower-roman;
        }
        // a {
        //   color: $gray-1;
        //   text-decoration: none;
        //   &:active,
        //   :visited {
        //     color: $gray-1;
        //   }
        // }
      }
    }
  }
  .footer {
    padding-top: 1rem;
    margin-top: 1rem;
    border-top: 0.0625rem solid;
  }
}
.termsCondition {
  li {
    margin-bottom: 0.5rem;
  }
}

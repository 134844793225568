@import "src/index.scss";

section.improvedQualityOfLife {
  > [class*="Text"] {
    margin-bottom: 2.5rem;
    width: clamp(23.4375rem, 85%, 63.75rem);
    text-align: center;
  }
  .cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 75rem;
    > [class*="Card"] {
      margin-bottom: 1.5rem;
      padding: 2.5rem;
      justify-content: space-between;
      flex-wrap: nowrap;
      > [class*="TextContainer"] {
        width: clamp(18.75rem, 48%, 35.875rem);
        h4 {
          margin-bottom: 0.625rem;
        }
        p:not(:last-child) {
          margin-bottom: 2rem;
        }
      }

      &:first-child {
        background-color: #e7e1fe;
        [class*="stain"] {
          width: 71.6%;
          top: 2.5625rem;
          right: 9.4375rem;
          @media only screen and (max-width: $tabletBreakpoint) {
            width: clamp(8.125rem, 61%, 11.1875rem);
            right: initial;
            top: initial;
            bottom: 0.625rem;
            left: -1.25rem;
          }
        }

        [class*="ImageContainer"] {
          width: 45%;
        }
      }
      &:nth-child(2) {
        background-color: $secondary-1;
        [class*="stain"] {
          width: 38.2%;
          bottom: -2.5rem;
          right: 22.125rem;
          @media only screen and (max-width: $tabletBreakpoint) {
            max-width: 7.9375rem !important;
            right: initial;
            bottom: -1.25rem;
            left: -2.25rem;
          }
        }
        [class*="ImageContainer"] {
          width: 45%;
        }
      }
      &:nth-child(3) {
        background-color: #f7fafd;
        [class*="stain"] {
          width: 5.25rem;
          top: -2.125rem;
          left: -6rem;
          @media only screen and (max-width: $tabletBreakpoint) {
            width: 3.9375rem;
            top: 0rem;
            left: -3.625rem;
          }
        }
        [class*="ImageContainer"] {
          width: 46%;
        }
      }
      &:nth-child(4) {
        background-color: #cdf5ef;
        > [class*="TextContainer"] {
          width: 100%;
          p {
            margin-bottom: 0;
          }
        }
      }
    }

    .bottomCards {
      display: flex;
      padding: 0 !important;
      justify-content: space-between;
      > [class*="Card"] {
        padding: 2.5rem;
        h4 {
          margin-bottom: 0.625rem;
        }
        &:first-child {
          width: clamp(23.9375rem, 100%, 49.5625rem);
          flex-direction: column;
          margin: 0 1.5rem 0 0;
          margin-bottom: 0;
          justify-content: space-between;
          background-color: $secondary-1;
          > [class*="TextContainer"] {
            width: clamp(21.375rem, 100%, 35.875rem);
          }
          > [class*="ImageContainer"] {
            width: 65%;
			max-width: none;
          }
        }
        &:last-child {
          width: 23.9375rem;
          flex-direction: column;
          margin-bottom: 0;
          background-color: $gray_bg;
          > [class*="ImageContainer"] {
            margin-top: 3.1875rem;
			max-width: none;
			width: 100%;
            img {
              max-width: 15.625rem;
            }
            [class*="stain"] {
              width: 5rem;
              right: -1.875rem;
              bottom: 1.875rem;
              @media only screen and (max-width: $tabletBreakpoint) {
                right: initial;
                bottom: 3.125rem;
                left: 12.625rem;
              }
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: $tabletBreakpoint) {
    width: 100%;
    > [class*="Text"] {
      margin-bottom: 2.5rem;
      width: 100%;
      text-align: center;
    }
    .cards {
      width: 100%;
      > [class*="Card"] {
        flex-direction: column;
        padding: 2rem 1.25rem;
        h4 {
          font-size: 1.5rem;
        }
        > [class*="TextContainer"] {
          width: 100%;
          margin-bottom: 1.5rem;
        }

        &:first-child {
          [class*="ImageContainer"] {
            width: 100%;
            max-width: none;
          }
        }

        &:nth-child(2) {
          [class*="ImageContainer"] {
            width: 100%;
            max-width: none;
          }
        }

        &:nth-child(3) {
          flex-direction: column-reverse;
          [class*="ImageContainer"] {
            margin-bottom: 1.5rem;
            width: 100%;
            max-width: none;
          }
          > [class*="TextContainer"] {
            margin-bottom: 0;
          }
        }
        &:nth-child(4) {
          > [class*="TextContainer"] {
            margin-bottom: 0;
          }
        }
        img {
          max-width: 100%;
        }
      }

      .bottomCards {
        > [class*="Card"] {
          padding: 2rem 1.25rem;

          &:first-child {
            width: 100%;
            flex-direction: column;
            margin: 0 0 1.5rem;
            > [class*="TextContainer"] {
              width: 100%;
            }
            > [class*="ImageContainer"] {
              margin-top: 3.1875rem;
              width: 100%;
              max-width: none;
            }
          }
          &:last-child {
            width: 100%;
            flex-direction: column;
            background-color: $gray_bg;
            > [class*="ImageContainer"] {
              // max-width: 28.375rem;
            }
          }
        }
      }
    }
  }
}

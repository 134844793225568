@import "src/index.scss";

.widget {
  padding: 1rem;
  border: 0.1875rem solid $primary-1_light;
  border-radius: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  min-width: 17.25rem;

  .title {
    text-align: center;
    font-weight: 600;
    color: $primary-1;
  }

  .moveRequestLabel {
    width: fit-content;
    height: auto;
    padding: 0.1875rem 0.375rem;
    border-radius: 0.25rem;
    text-transform: uppercase;
    background-color: $bg_warning_color;
    color: $writing_warning_color;
    font-size: 0.8125rem;
    font-weight: 600;
    display: flex;
    gap: 0.25rem;
  }

  .paidLabel {
    width: fit-content;
    height: auto;
    padding: 0.1875rem 0.375rem;
    border-radius: 0.25rem;
    text-transform: uppercase;
    background-color: $bg_confirm_color;
    color: $writing_confirm_color;
    font-size: 0.8125rem;
    font-weight: 600;
    display: flex;
    gap: 0.25rem;
  }

  .dateWrapper {
    display: flex;
    .calendar {
      width: 87%;
      display: flex;

      .icon {
        background-color: $secondary-1-40;
        color: $primary-1;
        width: 2.75rem;
        height: 2.75rem;
        border-radius: 6.25rem;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          width: 1.5rem;
          height: 1.5rem;
        }
      }

      .date {
        text-align: start;
        margin-left: 0.75rem;
        .day {
          font-weight: 600;
        }
        .hour {
          font-weight: 400;
        }
      }
    }

    .shapes {
      position: relative;
      // width: 33%;
      .circleShape,
      .trangleShape {
        position: absolute;
      }

      .circleShape {
        height: 1rem;
        width: 1rem;
        left: -0.625rem;
        top: 1.5625rem;
      }

      .trangleShape {
        height: 1.75rem;
        width: 1.75rem;
        top: 0rem;
        left: 0.875rem;
        transform: rotate(36.87deg);
      }
    }
  }

  .infoBoxTherapist {
    width: fit-content;
    border-radius: 0.25rem;
    padding: 0.1875rem 0.375rem;
  }

  .btnWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1rem;

    .firstBtn {
      width: 24%;
      text-decoration: underline;
      color: $gray-1;
    }

    .secondBtn {
      width: 100%;
      text-align: center;
      line-height: 1;

      &.hidden {
        opacity: 50%;
      }
    }
  }
  .infoBox {
    box-shadow: none;
    border-radius: 1rem;
  }
}

@media (max-width: $tabletBreakpoint) {
  .widget {
    min-width: none;
    .btnWrapper {
      flex-wrap: nowrap;
    }
  }
}

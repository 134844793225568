@import "src/index.scss";

.chooseTherapist {
  z-index: unset;
  padding: 1.25rem 4%;
  padding-top: calc(10vh + 1.25rem);
  background-color: #f7fafd;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.5rem;
  overflow-y: auto;
  scrollbar-width: auto;

  box-sizing: border-box;
  height: 90vh;

  .ChooseTherapistSkeleton {
    height: 23.75rem;
    width: 19.375rem;
    border-radius: 1rem;
  }

  .changeEmailForm {
    display: flex;
    flex-direction: column;
    gap: 2.125rem;

    .formInputs {
      display: flex;
      flex-direction: column;
      gap: 0.75rem;
    }

    .formBtnWrapper {
      display: flex;
      flex-direction: column;
      gap: 0.75rem;

      button {
        margin: 0;
      }
    }
  }

  .chooseTherapistModal {
    z-index: 10001 !important;
  }


  .welcomeWrapper {
    .welcome {
      max-width: 37.5rem;
      //margin-top: 1.5rem;
      padding: 2rem;
      border-radius: 1rem;
      box-shadow: 0rem 0.25rem 3.375rem 0rem rgba(0, 0, 0, 0.0588235294);
      background-color: #ffffff;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 2rem;

      .description {
        display: flex;
        flex-direction: column;
        gap: 1.25rem;

        .descriptionWrapper {
          width: 100%;
          display: flex;
          flex-direction: column;
          height: auto;
          box-sizing: border-box !important;
          align-items: center;

          .icon {
            height: auto;
            max-width: 12.5rem;
            width: 100%;

            &.first {
              max-width: 9.375rem;

              &.psychiatry {
                transform: scaleX(-1);
              }
            }
          }

          label {
            font-size: 1.125rem;
          }
        }

        .QuestionIcon {
          box-sizing: border-box;
          width: 2.75rem;
          height: 2.75rem;
          padding: 0.625rem;
          margin-right: 1rem;
          border-radius: 6.25rem;
          background-color: $secondary-1;

          .icon {
            color: $primary-1;
          }
        }

        p {
          font-size: 1.25rem;
        }

        .price {
          font-weight: 600;
        }
      }

      .btnWrapper {
        .soloBtn {
          width: 100%;
        }
      }
    }
  }

  .titleWrapper {
    .title {
      font-weight: 600;
      margin-bottom: 0.75rem;
    }

    .description {
      justify-self: center;
      width: 40vw;
      font-size: 0.875rem;
    }
  }

  .cardWrapper {
    display: flex;
    gap: 1.25rem;
  }

  .rerollBtn {
    color: $gray-1;
    text-decoration: underline;
  }
}

.chooseOtherCard {
  width: 50%;
  padding: 2.5rem;
  background-color: $bg_main;
  box-shadow: 0rem 0.25rem 3.375rem 0rem #0000000f;
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  gap: 1.5rem;

  .description {
    font-size: 0.875rem;
    text-align: start;
  }

  .wrapperBtn {
    display: flex;
    justify-content: space-between;
    gap: 1rem;

    button {
      width: 100%;
    }
  }
}

.confirmCodeForm {
  background-color: $gray-6;
  width: 40%;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .confirmCodeBtn {
    width: 100%;
    text-align: center;
    margin-bottom: 1rem;

    .spanBtn {
      color: $primary-1_dark;
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

.moreSuggestedTherapistWrapper {
  z-index: 10001;
  width: 70%;
  max-height: 90%;
  overflow-y: auto;
  background-color: $gray-6;
  padding: 2rem 1.5rem;
  border-radius: 0.8125rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;

  .title {
    width: 100%;

    p {
      width: 100%;
      text-align: center;
    }
  }

  .topPart {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .description {
    font-size: 0.875rem;
    width: 75%;
  }
}

@media (max-width: $tabletBreakpoint) {
  .chooseTherapist {
    padding: 0rem !important;
    padding-top: calc(10vh + 1.25rem) !important;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2.5rem;
    overflow-y: auto;
    box-sizing: border-box;
    margin-bottom: 5rem;

    .ChooseTherapistSkeleton {
      height: 23.75rem;
      width: 90vw;
    }

    .changeEmailForm {
      gap: 1.5rem;
    }

    .welcomeWrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;

      .welcome {
        margin-top: 0rem;
        max-width: none;
        box-shadow: 0rem 0.25rem 3.375rem 0rem #0000000f;
        gap: 0.75rem;
        padding: 1rem;
        height: auto;

        .description {
          width: 100%;
          p {
            font-size: 1rem;
          }

          .descriptionWrapper {
            .icon {
              max-width: 7.5rem;

              &.first {
                max-width: 5rem;
              }
            }

            label {
              font-size: 1rem;
            }
          }
        }

        .btnWrapper {
          z-index: 100;
          position: fixed;
          bottom: 0rem;
          left: 0rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 0.75rem;
          width: calc(100vw - 1.25rem) !important;
          border-top: 0.0625rem solid $gray-5;
          padding: 1rem 0.625rem;
          background-color: $gray-6;

          .soloBtn {
            width: 100%;
          }
        }
      }
    }

    .titleWrapper {
      .title {
        margin-bottom: 0.75rem;
        font-size: 1.5rem;
        font-weight: 600;
        line-height: 1.8125rem;
        text-align: center;
      }

      .description {
        width: 100%;
        font-size: 0.875rem;
      }
    }

    .cardWrapper {
      display: flex;
      flex-direction: column;
      gap: 1.25rem;
    }

    .rerollBtn {
      color: $gray-1;
      text-decoration: underline;
    }
  }

  .chooseOtherCard {
    width: 100%;
    margin: 0 0.625rem;
    padding: 2.5rem;
    background-color: $bg_main;
    box-shadow: 0rem 0.25rem 3.375rem 0rem #0000000f;
    display: flex;
    flex-direction: column;
    border-radius: 1rem;
    gap: 1.5rem;

    .description {
      font-size: 0.875rem;
      text-align: start;
    }

    .wrapperBtn {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 1rem;

      button {
        width: 100%;
      }
    }
  }
  .confirmCodeForm {
    background-color: $gray-6;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin: 0 1rem;
    padding: 2.5rem 1rem;

    div {
      border-radius: 0.5rem;
    }

    .confirmCodeBtn {
      margin-bottom: 1rem;
      border: 0.09375rem solid $gray-5;
      padding: 0.8125rem 1rem;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      text-align: start;

      font-size: 1rem;
      font-weight: 600;
      line-height: 1.25rem;

      .spanBtn {
        color: $gray-2;
        cursor: pointer;
        text-decoration: underline;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.25rem;
        text-align: start;
      }
    }
  }
  .moreSuggestedTherapistWrapper {
    width: 100%;
    margin: 0 0.625rem;
    height: 70%;
    overflow-y: auto;
    background-color: $gray-6;
    padding: 2rem 1.5rem;
    border-radius: 0.8125rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;

    .title {
      width: 100%;

      p {
        width: 100%;
        text-align: center;
      }
    }

    .topPart {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .description {
      font-size: 0.875rem;
      width: 75%;
    }
  }
}

@import "src/index.scss";

section.ourModel {
	max-width: 75rem;
	margin-top: 5rem;
	margin-bottom: 5rem;
	> [class*="TextContainer"] {
		align-items: center;
		width: clamp(63rem, 100%, 75rem);
		h3 {
			margin-bottom: 2.5rem;
		}

		> [class*="stain"] {
			width: 1.25rem;
			&:nth-last-child(2) {
				width: 1.25rem;
				top: 2.375rem;
				left: 2.125rem;
			}
			&:last-child {
				width: 1.25rem;
				top: 0;
				right: 7.25rem;
			}
			@media only screen and (max-width: $tabletBreakpoint) {
				&:nth-last-child(2) {
					top: 4.8125rem;
					left: -0.5rem;
				}
				&:last-child {
					top: initial;
					bottom: 2.5rem;
					right: -0.25rem;
				}
			}
		}
	}

	.cards {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		margin-bottom: 2.5rem;
		> :nth-child(even) {
			margin: 0 1.5rem;
		}
		> [class*="card"] {
			padding: 2.5rem clamp(0.5rem, 2.5%, 2rem);
			background-color: #f7fafd;
			margin-bottom: 1.5rem;
			svg {
				color: $primary-1;
			}

			> [class*="stain"] {
				width: 1.25rem;
				left: 33.6%;
				bottom: 1.875rem;
				@media only screen and (max-width: $tabletBreakpoint) {
					bottom: 0.9375rem;
				}
			}
		}
	}

	@media only screen and (max-width: $tabletBreakpoint) {
		overflow: hidden;
		> [class*="TextContainer"] {
			h3 {
				text-align: center;
			}
		}

		.cards {
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			> :nth-child(even) {
				margin: 0;
			}
			> [class*="card"] {
				width: 100%;
				margin-bottom: 1.5rem;
				padding: 2.5rem 1.5rem;
				background-color: #f7fafd;
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
}

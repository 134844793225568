@import "src/index.scss";

.h2 {
  font-size: 3rem;
  font-weight: 700;
  line-height: 3.5625rem;
  color: $gray-1;
}

.h3 {
  font-size: 2.375rem;
  font-weight: 600;
  line-height: 2.875rem;
  color: $gray-1;
}

.h4 {
  font-size: 1.75rem;
  font-weight: 600;
  line-height: 2.125rem;
  color: $gray-1;
}

.h5 {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.5rem;
  color: $gray-1;
}

.h6 {
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.625rem;
  color: $gray-1;
}

.p {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.625rem;
  color: #3d3762;
}

.span {
  display: inline-block;
}

.label {
  display: inline-block;
  line-height: 0.875rem;
  font-size: 0.875rem;
  padding: 0.6875rem 1rem !important;
  border-radius: 3.375rem;
  background-color: $gray_bg;
  color: $gray-2;
}

.link {
  text-decoration: underline;
  cursor: pointer;
}

@import "src/index.scss";

.introductorySection {
  //Card
  > div {
    padding: 6.25rem 0 3.75rem 0;
    flex-wrap: wrap;
    justify-content: space-between;

    //Card --> Text-Container
    & > [class*="TextContainer"] {
      width: clamp(18.75rem, 46.75%, 35.0625rem);
      margin-right: 0.5rem;
      label {
        margin-bottom: 1rem;
      }
      h1 {
        margin-bottom: 1rem;
        @media(max-width: $tabletBreakpoint){
          font-size: 2.5rem;
          line-height: 3rem;
        }
      }
      p {
        margin-bottom: 2.5rem;
      }
    }
    @media (max-width: $tabletBreakpoint) {
      [class*="stain"] {
        display: none;
      }
      margin-bottom: 2.5rem;
    }

    //Card --> Image-Box
    & > [class*="ImageContainer"] {
      max-height: 23.125rem;
      width: clamp(18.75rem, 52%, 34.6875rem);
      img {
        width: clamp(18.75rem, 100%, 34.6875rem);
      }
    }
  }

  &[class*="text-area-only"] {
    > div {
      > [class*="TextContainer"] {
        width: clamp(17.5rem, 66%, 49.5625rem);
        margin: 0 auto;
        label {
          align-self: center;
        }
        h2,
        p {
          text-align: center;
        }
      }
    }
  }

  @media only screen and (max-width: $tabletBreakpoint) {
    > div {
      //Card --> Text-Container
      & > [class*="TextContainer"] {
        width: 100%;
        margin-right: 0;
        margin-bottom: 2rem;
        h2 {
          font-size: 2.5rem;
        }
      }
      //Card --> Image-Box
      & > [class*="ImageContainer"] {
        max-height: initial;
        width: 100%;
        > div {
          width: 100%;
          img {
            width: 100%;
          }
        }
      }
    }

    &[class*="text-area-only"] {
      > div {
        > [class*="TextContainer"] {
          width: 100%;
          margin: 0;
          label {
            align-self: center;
          }
          h2,
          p {
            text-align: center;
          }
          h2 {
            font-size: 2.5rem;
          }
        }
      }
    }
  }

  //stains
  position: relative;
  .stain {
    position: absolute;
    @media (max-width: $tabletBreakpoint) {
      display: none;
    }
  }
}

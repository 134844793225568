@import 'src/index.scss';

.sendBtn{
  cursor: pointer;
  margin: auto 0.3125rem;
    height: 1.5rem;
    width: 1.5rem;
    border: 0;
    background-color: transparent;
    color: $primary-1_dark;
  }

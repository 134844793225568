@import "src/index.scss";

.patientPastAppointment {
  display: flex;
  border-bottom: 0.09375rem solid $gray-5;
  padding: 1.25rem 1rem;

  .date,
  .therapist,
  .price {
    flex: 1;
    text-align: start;
  }

  .title {
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 500;
    text-align: start;
    color: $gray-3;
  }

  button {
    text-decoration: underline;
  }
}

@media (max-width: $tabletBreakpoint) {
  .patientPastAppointment {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 0.5rem;
    justify-content: space-between;
    border-bottom: 0.09375rem solid $gray-5;
    padding: 1.25rem 1rem;
    text-align: start;

    .title {
      font-size: 0.875rem;
      line-height: 1.25rem;
      font-weight: 500;
      text-align: start;
      color: $gray-3;
    }

    button {
      text-decoration: underline;
    }
  }
}

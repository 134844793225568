@import "src/index.scss";

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100dvh;
  width: 100dvw;
  background-color: $primary-1-60;
  backdrop-filter: blur(0.5rem);
  z-index: 10000;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  &[class*="suggestedTherapists"] {
    max-width: 40%;
  }

  .modalContent {
    position: relative;
    background-color: #ffffff;
    padding: 2.5rem 2rem;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    gap: 2.125rem;
    max-width: 50%;
    max-height: 80vh;
    overflow-y: auto;
    width: 100%;
    box-shadow: 0 0.25rem 0.9375rem rgba(0, 0, 0, 0.3);

    &[class*="max-width-40"] {
      max-width: 40%;
    }

    &[class*="modalWithSmallHeader"] {
      margin-top: 5rem;
    }

    &[class*="suggestedTherapists"] {
      max-width: fit-content;
      margin: 5rem 1rem 0 1rem;
      width: fit-content;

    }

    &[class*="modalWithSmallHeaderMobile"] {
      margin-top: 0;
    }
  }
}


@media (max-width: $tabletBreakpoint) {
  .modal {

    .modalContent {
      margin: 0rem 0.5rem;
      max-width: 100%;
      padding: 1rem;
      max-height: calc(100% - 4.375rem);
      height: fit-content;
      gap: 1.5rem;

      &::-webkit-scrollbar {
        display: none;
      }

      &[class*="max-width-40"] {
        max-width: 100%;
      }

      &[class*="modalWithSmallHeader"] {
        margin-top: 4.375rem;
        max-height: calc(100% - 8.75rem);
        height: fit-content;
      }

      &[class*="suggestedTherapists"] {
        margin-top: 4.375rem;
        max-height: calc(100% - 8.75rem);
        height: fit-content;
        max-width: 100%;
        width: 100%;
      }

      &[class*="modalWithSmallHeaderMobile"] {
        margin-top: 4.375rem;
        max-height: calc(100% - 8.75rem);
        height: fit-content;
      }
    }
  }
}


@import "src/index.scss";

.menuBackdrop {
  .menu {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 17vw;
    height: 95dvh;
    justify-content: space-between;

    .spinner {
      position: absolute;
      top: 50%;
      left: 50%;
    }

    .onboarding,
    .onboardingDescription {
      z-index: 10001;
      background-color: $bg_main;
      position: relative;
    }

    .onboardingDescription {
      position: absolute;
      top: -0.625rem;
      left: 110%;
      width: 21.875rem;
      z-index: 10001;
      padding: 1rem;
      border-radius: 0.75rem;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;

      .triangle {
        position: absolute;
        left: -0.9375rem;
        border-top: 0.9375rem solid transparent;
        border-right: 0.9375rem solid $bg_main;
        border-bottom: 0.9375rem solid transparent;
      }

      span {
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.375rem;
        text-align: start;
        color: $gray-3;
      }

      .wrtapperBtn {
        display: flex;

        button {
          width: 100%;
        }
      }
    }

    .hideStep {
      display: none;
    }

    .closeIcon {
      display: none;
    }

    .logo {
      box-sizing: border-box;
      padding: 0 0.5rem;
      max-width: 11.875rem;
      width: 100%;
      margin-top: 0.625rem;
      margin-bottom: 2.1875rem;
      cursor: pointer;
      object-fit: cover;
    }

    .wrapperBtn {
      display: flex;
      flex-direction: column;
      height: 100%;

      .icon {
        width: 1.25rem;
        height: 1.25rem;
      }

      .chatTitle {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-grow: 1;

        .patientChatCardNewMessage {
          width: 1.125rem;
          height: 1.125rem;
          border-radius: 6.25rem;
          background-color: $primary-1;
          color: $bg_main;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 0.625rem;

          &.patientChatCardNewMessagePsychiatry {
            background-color: #06CBAD;
            color: white;
          }

          &.single {
            background-color: #2254A4;
          }

          &.couple {
            background-color: #5B4CAA;
          }

          &.underage {
            background-color: #72B4EE;
          }

          &.psychiatry {
            background-color: #4BCAB1;
          }
        }
      }

      .spacer {
        border-top: 0.0625rem solid #ebebec;
        margin: 0 1.375rem;
      }
    }
  }
}

@media (max-width: $tabletBreakpoint) {
  .menuBackdrop {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100dvw;
    height: 100dvh;
    z-index: 999;

    .menu {
      display: flex;
      width: 75vw;
      height: 100dvh;
      position: relative;
      top: 0;
      left: 0;
      background-color: $gray-6;

      &.onboardingMenu {
        transition: transform linear;
        transform: translate(0) !important;
      }

      .onboarding,
      .onboardingDescription {
        z-index: 10001;
        background-color: $bg_main;
        position: relative;
      }

      .onboardingDescription {
        position: absolute;
        left: 10%;
        top: 4.375rem;
        width: 80%;
        z-index: 10001;
        padding: 1rem;
        border-radius: 0.75rem;
        display: flex;
        flex-direction: column;
        gap: 1.5rem;

        .triangle {
          position: absolute;
          top: -1.5rem;
          left: 50%;
          border-right: 0.75rem solid transparent;
          border-bottom: 0.75rem solid $bg_main;
          border-left: 0.75rem solid transparent;
        }

        span {
          font-size: 0.875rem;
          font-weight: 400;
          line-height: 1.25rem;
          text-align: start;
        }

        .wrtapperBtn {
          display: flex;

          button {
            width: 100%;
          }
        }
      }

      .hideStep {
        display: none;
      }

      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2.5rem;
        padding: 0.75rem;
        padding-right: 0.25rem;

        .closeIcon {
          cursor: pointer;
          display: block;
          width: 2.5rem;
        }
      }

      .logo {
        width: 8.375rem;
        margin: 0;
        padding: 0;
      }
    }
  }
}

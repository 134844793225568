@import "src/index.scss";
main {
  max-width: 100vw;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0 0.5rem;
  @media only screen and (max-width: $tabletBreakpoint) {
    padding: 0 1rem;
  }
}
.faq {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  * {
    text-align: initial;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  margin: 0 auto;
  max-width: 85rem;
  justify-content: center;
  box-sizing: border-box;
  background-color: white;

  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 85rem;
    margin: 0 auto;
  }
  > [class*="IntroductorySection"] {
    h1 {
      text-align: center;
    }
    width: 100%;

    > [class*="Card"] {
      width: 100%;
      @media (max-width: $mobileBreakpoint) {
        padding-bottom: 0 !important;
      }
      > [class*="TextContainer"] {
        width: clamp(23.4375rem, 83%, 49.5625rem);
        p:last-child {
          display: none;
        }
      }
      [class*="stain"] {
        width: 28%;

        right: -2.5rem;
        bottom: 2.5rem;
      }
    }

    @media only screen and (max-width: $tabletBreakpoint) {
      width: 100%;
      > [class*="Card"] {
        justify-content: center;
        > [class*="TextContainer"] {
          p {
            margin-bottom: 0;
          }
          p:last-child {
            display: block;
          }
        }
        [class*="stain"] {
          width: 16.25rem;
          right: -3.75rem;
          top: initial;
          bottom: -4.375rem;
          transform: rotate(10deg);
        }
      }
    }
  }

  > [class*="Footer"] {
    margin: 0 auto;
    margin-bottom: 1.5rem;
    padding: 2.5rem 4.25rem;
    @media only screen and (max-width: $tabletBreakpoint) {
      padding: 1.25rem;
    }
  }
}

@import "src/index.scss";

.patientDetail {
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    display: none;
  }
  .patientAppointment {
    .tabs {

      .tabsLabel {
        max-width: 20rem;
      }
      .bottomPart {
        display: flex;
        gap: 0.625rem;
        margin-bottom: 2.5rem;
        margin-top: 1.25rem;
      }
      .emptyStateContainer {
        width: 100%;
        display: flex;
        justify-content: center;
        .emptyState {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 1.5rem;
          width: 30%;
          .iconWrapper {
            padding: 1.875rem;
            background-color: $secondary-1;
            border-radius: 100%;
          }
          .emptyStateBtn {
            padding: 0 3rem;
          }
        }
      }
      .appointmentsSection {
        text-align: start;
        margin-bottom: 1rem;
      }

      .programmedAppointment {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: 100%;
        .pastAppointmentTherapist {
          padding: 1.5rem;
          border: 0.0625rem solid $gray-5;
          border-radius: 1rem;
          width: 100%;
          box-sizing: border-box;
          .patientName {
            font-size: 1.25rem;
            font-weight: 600;
            line-height: 1.5rem;
            text-align: start;
          }
          .expired,
          .completed,
          .moved,
          .payed_moved,
          .cancelled {
            display: none;
          }
          .appointmentDetail {
            display: flex;
            gap: 0.625rem;
            font-size: 1rem;
            font-weight: 600;
            line-height: 1.25rem;
            text-transform: capitalize;
            .expired,
            .completed,
            .moved,
            .payed_moved,
            .cancelled {
              display: block;
              font-size: 0.8125rem;
              line-height: 1rem;
              padding: 0.1875rem 0.375rem;
              border-radius: 0.25rem;
            }
            .expired,
            .cancelled {
              background-color: $bg_error_color;
              color: $writing_error_color;
            }
            .completed {
              background-color: $bg_confirm_color;
              color: $writing_confirm_color;
            }
            .moved {
              background-color: $bg_warning_color;
              color: $writing_warning_color;
            }

            .payed_moved {
              background-color: $bg_warning_color;
              color: $writing_warning_color;
            }
          }
        }
        .pastAppointmentHeader {
          display: flex;
          justify-content: space-between;
          .select {
            border: 0.09375rem solid $gray-5;
            border-radius: 0.5rem;
            padding: 0.6875rem 1rem;
            font-size: 1rem;
            line-height: 1.375rem;
            color: $gray-1;
          }
        }
      }
    }
  }
}

@media (max-width: $tabletBreakpoint) {
  .patientDetail {
    flex-grow: 1;
    width: 100%;
    overflow-y: auto;
    .patientAppointment {
      .tabs {
        .tabsLabel {
          max-width: 100%;
        }
        .bottomPart {
          display: flex;
          gap: 0.625rem;
          margin-bottom: 2.5rem;
          margin-top: 2.5rem;
        }
        .emptyStateContainer {
          width: 100%;
          display: flex;
          justify-content: center;
          .emptyState {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 1.5rem;
            width: 100%;
            .iconWrapper {
              padding: 1.875rem;
              background-color: $secondary-1;
              border-radius: 100%;
            }
            .emptyStateBtn {
              padding: 0 3rem;
            }
          }
        }
        .appointmentsSection {
          text-align: start;
          margin-bottom: 1rem;
        }

        .programmedAppointment {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          .pastAppointmentTherapist {
            padding: 1.5rem;
            border: 0.0625rem solid $gray-5;
            border-radius: 1rem;
            display: flex;
            flex-direction: column;
            .patientName {
              font-size: 1.25rem;
              font-weight: 600;
              line-height: 1.5rem;
              text-align: start;
            }
            .expired,
            .completed,
            .moved,
            .payed_moved,
            .cancelled {
              display: block;
              font-size: 0.8125rem;
              line-height: 1rem;
              padding: 0.1875rem 0.375rem;
              border-radius: 0.25rem;
              width: fit-content;
              margin-bottom: 1rem;
            }
            .expired,
            .cancelled {
              background-color: $bg_error_color;
              color: $writing_error_color;
            }
            .completed {
              background-color: $bg_confirm_color;
              color: $writing_confirm_color;
            }
            .moved {
              background-color: $bg_warning_color;
              color: $writing_warning_color;
            }

            .payed_moved {
              background-color: $bg_warning_color;
              color: $writing_warning_color;
            }
            .appointmentDetail {
              display: flex;
              gap: 0.625rem;
              font-size: 1rem;
              font-weight: 600;
              line-height: 1.25rem;
              text-transform: capitalize;
              .expired,
              .completed,
              .moved,
              .payed_moved,
              .cancelled {
                display: none;
                font-size: 0.8125rem;
                line-height: 1rem;
                padding: 0.1875rem 0.375rem;
                border-radius: 0.25rem;
              }
              .expired,
              .cancelled {
                background-color: $bg_error_color;
                // background-color: #ffe1e1;
                color: $writing_error_color;
              }
              .completed {
                background-color: $bg_confirm_color;
                color: $writing_confirm_color;
              }
              .moved {
                background-color: $bg_warning_color;
                color: $writing_warning_color;
              }

              .payed_moved {
                background-color: $bg_warning_color;
                color: $writing_warning_color;
              }
            }
          }
          .pastAppointmentHeader {
            display: flex;
            justify-content: space-between;
            .select {
              border: 0.09375rem solid $gray-5;
              border-radius: 0.5rem;
              padding: 0.6875rem 1rem;
              font-size: 1rem;
              line-height: 1.375rem;
              color: $gray-1;
            }
          }
        }
      }
    }

  }
}

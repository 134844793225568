@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&family=Unbounded:wght@300;500;700&display=swap");

$font_family: "Poppins", sans-serif;
h1,h2,h3,h4,h5,h6{
  margin: 0
}
h1 {
  font-size: 3.75rem;
}
h2 {
  font-size: 3rem;
}
h3 {
  font-size: 2.5rem;
}
h4 {
  font-size: 2rem;
  line-height: 2.4rem;
  font-weight: 600;
}
h5 {
  font-size: 1.5rem;
  font-weight: 600;
}
h6 {
  font-size: 1.25rem;
}
body {
  font-size: 1rem;
}
.body2 {
  font-size: 0.75rem;
}
p {
  font-size: 0.75rem;
}

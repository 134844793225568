@import "src/index.scss";

.profile {
  width: 100%;
  background-color: #ffffff;
  border-radius: 1rem;
  overflow-y: auto;

  .accountsWrapper {
    display: flex;
    flex-wrap: wrap;
    height: fit-content;
    gap: 2rem;
    padding: 4.6875rem;

    .accountsCardWrapper {
      box-sizing: border-box;
      position: relative;
      display: flex;
      align-items: center;
      gap: 1rem;
      max-width: 30rem;
      min-height: 11.0625rem;
      width: 100%;
      border-radius: 1.375rem;
      background-color: #4F9C8D;
      transition: all .2s ease;
      cursor: pointer;

      .badge {
        position: absolute;
        top: -0.5rem;
        right: -0.5rem;
        min-width: 1.5rem;
        min-height: 1.5rem;
        border-radius: 50%;
        color: white;
        display: inline-flex;
        justify-content: center;
        align-items: center;

        span {
          padding: 0 0.25rem;
          color: white;
          font-size: 0.8125rem;
        }

        &.single {
          background-color: #2254A4;
        }

        &.couple {
          background-color: #5B4CAA;
        }

        &.underage {
          background-color: #72B4EE;
        }

        &.psychiatry {
          background-color: #4BCAB1;
        }
      }

      .accountsCardContainer {
        box-sizing: border-box;
        display: flex;
        width: 100%;
        height: 100%;
        gap: 1.5rem;
        padding: 1rem 2.8125rem 1rem 2.8125rem;

        .profilePictureWrapper {
          display: flex;
          align-items: center;
          width: fit-content;
          padding: 0.3125rem 0;

          .profilePicture {
            box-sizing: border-box;
            padding: 0.125rem;
            width: 7.1875rem;
            height: 7.1875rem;
            object-fit: scale-down;
            align-self: center;

            &.single {
              border: 0.09375rem solid #2254A4;
            }

            &.couple {
              border: 0.09375rem solid #5B4CAA;
            }

            &.underage {
              border: 0.09375rem solid #72B4EE;
            }

            &.psychiatry {
              border: 0.09375rem solid #4BCAB1;
            }
          }
        }

        .cardInfo {
          height: fit-content;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: start;
          align-self: center;
          gap: 0.5rem;

          .cardName {
            font-size: 1.5rem;
            font-weight: 700;
            line-height: 1.75rem;
            text-align: start;
          }

          .title {
            text-align: start;
            font-weight: 600;
          }

          & button {
          }
        }
      }

      &.addAccount {
        cursor: pointer;
        color: #B5B5B5;
        font-size: 1.25rem;
        font-weight: 500;
        flex-direction: column;
        justify-content: center;
        background-color: transparent;
        border: 0.1875rem solid #D5D5DB;
      }

      &.single {
        background-color: #DFEAFF;

        &.active {
          border: 0.125rem solid #2254A4;
        }
      }

      &.couple {
        background-color: #E0D9FD;

        &.active {
          border: 0.125rem solid #5B4CAA;
        }
      }

      &.underage {
        background-color: #E7F5FF;

        &.active {
          border: 0.125rem solid #72B4EE;
        }
      }

      &.psychiatry {
        background-color: #CDF5EF;

        &.active {
          border: 0.125rem solid #4BCAB1;
        }
      }

      &:hover {
        transform: translateY(-0.1875rem);
        box-shadow: 0.125rem 0.1875rem 0.625rem hsla(0, 0, 0, .1),
        -0.125rem 0.5625rem 3.75rem hsla(0, 0, 0, .1);
      }
    }
  }

  .buttonContainer {
    display: none;
  }
}

@media (max-width: $tabletBreakpoint) {
  .profile {
    position: relative;

    .accountsWrapper {
      padding: 6.875rem 2rem 8.4375rem 2rem;
      justify-content: center;
    }

    .buttonContainer {
      background: white;
      display: flex;
      justify-content: center;
      position: fixed;
      padding: 1.5625rem;
      border-top: 0.0625rem solid $gray-5;
      width: calc(100% - 3.125rem);
      bottom: 0;

      & button {
        width: 100%;
      }
    }
  }
}

@media (max-width: 37.5rem) {
  .profile {

    .accountsWrapper {
      padding: 6.875rem 2rem 7.8125rem 2rem;
      justify-content: center;
      gap: 1rem;

      .accountsCardWrapper {
        min-height: 7.5rem;

        .accountsCardContainer {
          gap: 1.25rem;
          padding: 0.5rem 1.5625rem 0.5rem 1.5625rem;

          .profilePictureWrapper {
            padding: 0.625rem 0;

            .profilePicture {
              width: 5rem;
              height: 5rem;
            }
          }

          .cardInfo {

            .cardName {
              font-size: 1rem;
              line-height: 1.125rem;
            }

            .title {
              font-size: 0.875rem;
            }

            & button {
            }
          }
        }

        &.addAccount {
          height: 7.375rem;
          font-size: 1rem;

          & img {
            width: 1.625rem;
            height: 1.625rem;
          }
        }
      }
    }
  }
}
@import "src/index.scss";

.videosWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 1rem;
  background-color: #211d32;

  .settingModal {
    background-color: $gray-6;
    max-width: 36.875rem;
    [class*="btnWrapper"] {
      display: flex;
      flex-direction: column;
      gap: 0.75rem;
    }
    .selectInput {
      font-size: 0.875rem;
      text-align: start;
      color: #808093;
      .videoCallSelect {
        height: 2.75rem;
        box-sizing: border-box;
        border: 0.125rem solid $primary-1;
        border-radius: 1rem;
        padding: 0.6875rem 1rem;
        border-radius: 0.5rem;
        border: 0.09375rem solid $gray-5;
        width: 100%;
        background-color: $gray-6;
        &::-ms-expand {
          margin-right: 0.625rem;
        }
      }
    }
  }
  .videos {
    border-radius: 0.5rem;
    height: 88rem;
    max-height: 86%;
    width: 100%;
    position: relative;
    flex-grow: 1;
    display: flex;
    gap: 1rem;
    margin-left: auto;
    margin-right: auto;
    [class*="OT_widget-container"] {
      border-radius: 0.5rem;
    }
    .coupleSubscriber {
      flex: 2;
      display: flex;
      gap: 1rem;
    }
    .subscriber {
      border-radius: 0.5rem;
      position: absolute;
      display: flex;
      flex-wrap: nowrap;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 10;
    }
    .couplePublisher {
      flex: 1;
    }
    .publisher {
      position: absolute;
      width: 25%;
      height: 25%;
      max-width: 22.5rem;
      max-height: 12.5rem;
      bottom: 1rem;
      right: 1rem;
      z-index: 100;
      border-radius: 0.1875rem;
    }
  }

  .btnWrapper {
    display: flex;
    gap: 1rem;
    // position: absolute;
    // right: 0.625rem;
    // bottom: 0.625rem;
    z-index: 100;
    justify-content: center;
    align-items: center;
    flex: 1;

    .controlBtn,
    .endCallButton {
      width: 2.75rem;
      height: 2.75rem;
      border-radius: 100%;
      background-color: #332d4e;
      color: #ffffff;
      padding: 0.625rem;
      box-sizing: border-box;
      margin-bottom: 0.625rem;
    }

    .endCallButton {
      background-color: $button_error_color;
    }
    .offButton {
      background-color: $button_error_color;
    }
    button {
      // [class*="icon"] {
      //   display: none;
      // }
    }
  }
}
.callClosed {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: #211d32;
  background-image: url("../../../public/assets/images/Sfondo.png");
  background-size: contain;
  .infoCard {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    .icon,
    .errorIcon {
      margin: 0 auto;
      width: 8rem;
      height: 8rem;
      padding: 2rem;
      box-sizing: border-box;
      border-radius: 100%;
    }
    .icon {
      background-color: $bg_confirm_color;
      color: $writing_confirm_color;
    }
    .errorIcon {
      background-color: $bg_warning_color;
      color: $writing_warning_color;
    }
    .infoText {
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.375rem;
      color: #ffffff;
    }
    .infoLink {
      cursor: pointer;
      text-decoration: underline;
    }
    .wrapperBtn {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      [class*="secondary"] {
        color: #ffffff;
        &:hover {
          color: #211d32;
        }
      }
    }
  }
}

@media (max-width: $tabletBreakpoint) {
  .videosWrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 2rem 0.5rem 0;
    background-color: #211d32;
    .videos {
      position: relative;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      .coupleSubscriber {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
      }
      .subscriber {
        position: relative;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
      }
      .couplePublisher {
        width: 100%;
      }
      .publisher {
        position: relative;
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 100%;
        bottom: 0;
        right: 0;
        z-index: 100;
        border: 0;
        border-radius: 0.1875rem;
      }
    }
    .btnWrapper {
      display: flex;
      gap: 1rem;
      z-index: 100;
      .controlBtn,
      .endCallButton {
        width: 2.75rem;
        height: 2.75rem;
        border-radius: 100%;
      }
    }
  }
}

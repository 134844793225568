@import "src/index.scss";

.SupervisedPatientRegister {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  .buttonWrapper {
    display: flex;
    gap: 0.5rem;
  }

  .formPage {
    display: flex;
    flex-direction: column;
    gap: 0.9375rem;
  }

  .pageNumber {
    width: 8.125rem;
  }
  .hiddenButton {
    display: none;
  }
  .errorLabel {
    font-size: 0.875rem;
    color: $error-text;
  }
  .textAreaError {
    background-color: $error-background;
    color: $error-text;
    border-color: $error-border;
  }
  .form {
    margin-top: 9.375rem;
    max-width: 31.25rem;
    width: 100%;
  }
  .question {
    background-color: $gray_bg;
    padding: 0.625rem 0.5rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
    border-radius: 0.5rem;
  }
  .btnWrapper {
    margin-bottom: 6.25rem;
    max-width: 31.25rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    .progressBar {
      margin-top: 0.875rem;
      width: 50%;
    }
  }
  .outsideButtonWrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 0.5rem;
  }
}

@media (max-width: $tabletBreakpoint) {
  .SupervisedPatientRegister {
    padding: 0 2.5rem;
    padding-top: calc(10vh + 2.5rem);
    justify-content: flex-start;
    .form {
      margin: 0 3.125rem;
    }
    .outsideButtonWrapper {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      gap: 1rem;
    }
  }
}
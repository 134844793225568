@import "../../../index.scss";

.therapistReview {
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  .title {
    font-size: 2rem;
    font-weight: 600;
    line-height: 2.375rem;
    text-align: start;
    margin-bottom: 1.5rem;
  }
  .paginationContainer {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: end;
  }
  .reviewContainer {
    margin-bottom: 1.25rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 0.625rem;
    margin-right: 0.625rem;
    .reviewCard {
      flex: 1 0 40%;
      div:first-child {
        height: auto;
      }
    }

  }
}

@media (max-width: $tabletBreakpoint) {
  .therapistReview {
    height: 80vh;
    padding: 10vh 0;
    .title {
      display: none;
    }
    .reviewContainer {
      .reviewCard {
        width: 100%;
      }
    }
  }
}
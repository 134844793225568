@import "./theme/colors.scss";
@import "./theme/typography.scss";
@import "./theme/breakpoints.scss";
@import "react-big-calendar/lib/css/react-big-calendar.css";
@import "react-day-picker/dist/style.css";

body {
  color: $gray-1;

  scrollbar-color: $primary-1 #f5f5f5;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    background-color: #f5f5f5;
    border-radius: 0.625rem;
    width: 0.25rem;
    height: 0.125rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $primary-1;
    border-radius: 0.625rem;
  }
}


.visibleVersionNumber {
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: lightgrey;
  font-size: 0.875rem;
  z-index: 999999;
  padding: 0 0.3125rem;
}

.hiddenVersionNumber {
  display: none;
}

.search-wrapper{
  input{
    width: 100%;
  }
}
.rdp {
  margin: 0 !important;
  .rdp-months {
    display: block;
    .rdp-month {
      .rdp-caption {
        position: relative;
        justify-content: center;
        .rdp-caption_label {
          font-size: 1.25rem;
          line-height: 1.5rem;
          font-weight: 600;
          text-transform: capitalize;
        }
        .rdp-nav_button_previous,
        .rdp-nav_button_next {
          align-items: start;
          position: absolute;
          top: 0;
          &:hover {
            background-color: transparent;
          }
        }
        .rdp-nav_button_previous {
          left: 0;
        }
        .rdp-nav_button_next {
          right: 0;
        }
      }
    }
    .rdp-table {
      margin-top: 1rem;
      .rdp-head {
        .rdp-head_row {
          .rdp-head_cell {
            font-size: 0.875rem;
            line-height: 1.25rem;
            font-weight: 400;
          }
        }
      }
      .rdp-tbody {
        .rdp-row {
          .rdp-cell {
            padding: 0 0.84375rem;
            .rdp-day {
              height: 1.75rem;
              width: 1.75rem;
              font-size: 0.875rem;
              line-height: 1.25rem;
              font-weight: 400;
            }
            .rdp-day_selected {
              background-color: $secondary-1;
              color: $gray-1;
            }
          }
        }
      }
    }
  }
}

.ant-select-single.couponSelect {
  height: 2.75rem !important;
  width: 100% !important;

  .ant-select-selector {
    border: 0.09375rem solid #c1f2ec !important;
    border-radius: 0.5rem !important;

    .ant-select-selection-placeholder {
      color: #4F9C8D !important;
      text-align: center !important;
      font-size: 1rem !important;
      padding-left: 0.75rem !important;
    }
  }

  .ant-select-arrow {
    color: #c1f2ec !important;
  }

  &:focus-within {
    outline: 0.125rem solid;
    outline-color: #4F9C8D;
    border-radius: 0.5rem;
  }
}

.couponSelect {
  .ant-select-item {
    color: #2c2c4b;
  }
}

.customSelect {
  .ant-tag {
    display: flex;
  }

  .ant-select-item-option {
    display: flex;
    align-items: center;

    .ant-select-item-option-content {
      display: flex;
      align-items: center;

      .custom-tag {
        padding: 0.125rem 0.5rem;
        border-radius: 0.75rem;

        &.psychiatry {
          background-color: #CDF5EF;
          color: #4BCAB1;
        }

        &.single {
          background-color: #DFEAFF;
          color: #2254A4;
        }

        &.couple {
          background-color: #E0D9FD;
          color: #5B4CAA;
        }

        &.underage {
          background-color: #E7F5FF;
          color: #72B4EE;
        }
      }
    }
  }
}

.noDocuments {
  font-weight: bold;
}

.filters {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 0.625rem;
  margin-bottom: 1.25rem;

  .inputsWrapper {
    display: flex;
    gap: 0.5rem;
  }
}

.warningModalContent {
  text-align: center;
  font-weight: 500;
}

@media (max-width: $tabletBreakpoint) {

  .rdp {
    .rdp-months {
      display: block;
      .rdp-month {
        .rdp-caption {
          position: relative;
          justify-content: center;
          .rdp-caption_label {
            font-size: 1.25rem;
            line-height: 1.5rem;
            font-weight: 600;
            text-transform: capitalize;
          }
          .rdp-nav_button_previous,
          .rdp-nav_button_next {
            width: 2.5rem;
            align-items: start;
            position: absolute;
            top: 0;
            &:hover {
              background-color: transparent;
            }
          }
          .rdp-nav_button_previous {
            left: 0;
          }
          .rdp-nav_button_next {
            right: 0;
          }
        }
      }
      .rdp-table {
        margin-top: 1rem;
        .rdp-head {
          .rdp-head_row {
            width: calc(100vw - 3.75rem);
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            .rdp-head_cell {
              font-size: 0.875rem;
              line-height: 1.25rem;
              font-weight: 400;
              height: 1.75rem;
              width: 1.75rem;
              padding: 0 0.3125rem;
            }
          }
        }
        .rdp-tbody {
          .rdp-row {
            width: calc(100vw - 3.75rem);
            display: block;
            .rdp-cell {
              padding: 0 0.3125rem;
              .rdp-day {
                height: 1.75rem;
                width: 1.75rem;
                font-size: 0.875rem;
                line-height: 1.25rem;
                font-weight: 400;
              }
              .rdp-day_selected {
                background-color: $secondary-1;
                color: $gray-1;
              }
            }
          }
        }
      }
    }
  }

  .filters {
    .inputsWrapper {
      &[class*="display-none"] {
        display: none;
      }
    }
  }
}

.rbc-calendar {
  // gap: 1rem;
  .rbc-toolbar {
    .rbc-btn-group:first-child {
      button {
        border: 0;
        .icon {
          width: 1rem;
          height: 1rem;
        }
      }
      button:first-child {
        padding: 0.6875rem 1rem;
        border-radius: 3.375rem;
        background-color: $gray_bg;
      }
    }
    .rbc-toolbar-label {
      font-family: "Poppins";
      font-size: 1rem;
      line-height: 1.375rem;
      text-align: start;
      text-transform: capitalize;
    }
    .rbc-btn-group:last-child {
      button {
        border: 0.09375rem solid $gray-5;
        border-radius: 0.5rem;
        padding: 0.6875rem 1rem;
        font-size: 1rem;
        line-height: 1.375rem;
        color: $gray-1;
        margin-left: 0.5rem;
        cursor: pointer;
        &.rbc-active {
          background-color: transparent;
        }
      }
    }
  }
  .rbc-month-view {
    border-radius: 0.375rem;
    .rbc-month-header {
      .rbc-header {
        border-left: 0;
        font-size: 0.75rem;
        font-weight: 600;
        line-height: 1.0625rem;
        text-transform: capitalize;
      }
    }
    .rbc-month-row {
      .rbc-row-content {
        .rbc-row {
          .rbc-date-cell {
            font-size: 0.625rem;
            font-weight: 500;
            line-height: 0.9375rem;
            text-align: left;
            padding: 0.5625rem;
          }
          .rbc-row-segment {
            div {
              background-color: transparent;
              //color: $gray-1;
              font-size: 0.75rem;
              font-weight: 600;
              line-height: 1.0625rem;
              text-align: left;
              .rbc-event-content {
                .customEvents {
                  display: flex;
                  gap: 0.25rem;
                  .eventDate {
                    display: flex;
                    gap: 0.25rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .rbc-time-view {
    border: 0;
    .rbc-time-header {
      .rbc-time-header-content {
        .rbc-row {
          .rbc-header {
            button {
              span {
                font-size: 0.8125rem;
                font-weight: 500;
                line-height: 1rem;
                text-align: left;
                color: $gray-3;
                text-transform: uppercase;
              }
            }
          }
        }
        .rbc-allday-cell {
          display: none;
        }
      }
    }
    .rbc-time-content {
      .rbc-time-gutter {
        .rbc-timeslot-group {
          flex-direction: column-reverse;
          .rbc-time-slot {
            display: flex;
            span {
              font-size: 0.8125rem;
              font-weight: 400;
              line-height: 1.125rem;
              color: $gray-3;
            }
          }
        }
      }
      .rbc-day-slot {
        .rbc-timeslot-group {
          .rbc-time-slot:last-child {
            display: none;
          }
        }
        .rbc-events-container {
          .rbc-event {
            height: 7% !important;
            width: 95% !important;
            background-color: $secondary-1;
            color: $primary-1_dark;
            border-radius: 0.3125rem;
            border: 0;
            border-left: 0.3125rem solid $primary-1_dark;
            display: flex;
            flex-direction: column-reverse;
            justify-content: center;
            flex-wrap: nowrap;
            box-sizing: content-box;
            .rbc-event-content {
              .customEvents {
                font-size: 0.875rem;
                font-weight: 600;
                line-height: 1.0625rem;
                text-align: left;
                .patientNameContainer{
                  display: flex;
                  gap: 0.25rem;
                }
                .eventDate {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
  // .rbc-month-view {
  //   border: 0;
  //   gap: 0.8125rem;
  //   .rbc-header {
  //     border: 0;
  //     font-size: 0.875rem;
  //     line-height: 1.1875rem;
  //     font-weight: 400;
  //     text-transform: uppercase;
  //   }
  //   .rbc-month-row {
  //     border: 0;
  //     justify-content: center;
  //     height: 1.75rem;
  //     flex: none;
  //     flex-basis: auto;
  //     .rbc-row-bg {
  //       border: 0;
  //       height: 1.75rem;
  //       gap: 1.25rem;
  //       .rbc-day-bg {
  //         border-radius: 100%;
  //         width: 1.75rem;
  //         height: 1.75rem;
  //         flex: none;
  //         border: 0;
  //       }
  //     }
  //     .rbc-row-content {
  //       .rbc-row {
  //         height: 1.75rem;
  //         gap: 1.25rem;
  //         .rbc-date-cell {
  //           text-align: center;
  //           width: 1.75rem;
  //           padding: 0;
  //           z-index: 9;
  //         }
  //         .rbc-off-range {
  //           border-radius: 100%;
  //         }
  //         &:nth-child(2) {
  //           position: absolute;
  //           top: 0;
  //           width: 106%;
  //           gap: 0;
  //         }
  //         .rbc-row-segment {
  //           &:has(div) {
  //             flex-basis: 0 !important;
  //             width: 1.75rem !important;
  //             .rbc-event {
  //               height: 1.75rem;
  //               width: 1.75rem;
  //               border-radius: 100%;
  //               padding: 0;
  //               background-color: $secondary-1;
  //               .rbc-event-content {
  //                 display: none;
  //               }
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
}

body {
  margin: 0;
  font-family: $font_family;
  background-color: $bg_main;
  p {
    margin: 0;
  }

  .appContainer {
    overflow-y: auto;
  }
  .multiselect-container,
  .multiSelectContainer {
    .search-wrapper {
      padding: 0.75rem 1rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      border: 0.09375rem solid $secondary-1;
      border-radius: 0.5rem;
      background-color: #ffffff;
      .chip {
        background-color: transparent;
        font-family: inherit;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.375rem;
        text-align: left;
        color: $gray-1;
        padding: 0;
        margin: 0;
      }
      .icon_down_dir::before {
        display: none;
      }
    }
    .highlightOption,
    .highlight {
      background-color: transparent;
      color: $gray-1;
    }
    .selected,
    li:hover {
      background-color: $secondary-1;
      color: $gray-1;
    }
  }
}

.loadingModal {
  color: $gray_bg;
  font-size: 2rem;
  font-weight: 600;
}

.authRoot {
  display: flex;
  padding: 1.25rem 1.25rem 1.25rem 0;
  box-sizing: border-box;
  background-color: $blue_bg;
  overflow: hidden;
}

@media (max-width: $tabletBreakpoint) {
  .activeTabInDocSheet {
    padding-right: 0 !important;
  }
  
  .authRoot {
    padding: 0;
  }
  .menuIsOpen {
    transition: transform ease;
    transform: translate(100%);
  }
}

.ant-collapse {
  .ant-collapse-item {
    .ant-collapse-header {
      font-weight: 600;
    }
    .ant-collapse-content {
      .ant-collapse-content-box {
        background-color: $bg_main;
        padding: 0;
      }
    }
  }
}

.ant-alert {
  background-color: #fff6f6;
  color: #9f3a38;
  border-color: #eb7676;
}

.ant-notification {
  z-index: 10010;
}

.ant-notification-notice-wrapper {
  border-radius: 1.5rem !important;
  margin: 0 !important;
  padding: 0 !important;
}

.info {
  text-align: start;
  border-radius: 1.5rem;
  padding: 0.625rem;
  z-index: 99;
  background-color: $gray_bg;
  // box-shadow: 0 3.125rem 1.25rem 0 $bg_main99;
  // margin: 1rem 1rem 0;
  &.warn {
    background-color: $bg_warning_color;
    color: $writing_warning_color;
  }
  &.confirm {
    background-color: $bg_confirm_color;
    color: $writing_confirm_color;
  }
  &.error {
    background-color: $bg_error_color;
    color: $writing_error_color;
  }
}

.ant-carousel {
  height: 100%;
}

.ant-carousel .slick-dots li.slick-active button {
  background: $primary-1 !important;
}

.ant-progress {
  margin: 0;
  height: fit-content;
  background-color: transparent;
}

.ant-progress .ant-progress-outer {
  position: relative;
}

.ant-progress .ant-progress-outer .ant-progress-inner {
  background-color: $secondary-1;
  border-radius: 0 !important;
  position: fixed;
  top: 10vh !important;
  @media (max-width: $tabletBreakpoint) {
    top: 5rem !important;
  }
  left: 0;
}

.ant-progress .ant-progress-outer .ant-progress-inner .ant-progress-bg {
  background-color: $primary-1_light;
  border-radius: 0 !important;
}

.ant-pagination-item-active {
  border-color: transparent !important;
  a {
    color: $primary-1_dark;
  }
}

.ant-picker {
  height: 2.75rem;
  border-color: $secondary-1;
  // $primary-1
  &.ant-picker-focused {
    border-color: $primary-1;
    border-width: 0.125rem;
  }
}

.error {
  .ant-picker {
    border-color: $error-border !important;
    border-width: 0.0625rem !important;
    outline: 0.0625rem solid $error-outline;
    background-color: $error-background;
    color: $error-text;
  }
}

input[type=password]::-ms-reveal,
input[type=password]::-ms-clear
{
  display: none;
}

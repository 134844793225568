@import "src/index.scss";

.appointmentCard {
  padding: 1.5rem;
  border: 0.0625rem solid $gray-5;
  border-radius: 1rem;
  width: 100%;
  box-sizing: border-box;
  text-align: left;

  .moveRequest {
    width: fit-content;
    height: auto;
    padding: 0.1875rem 0.375rem;
    border-radius: 0.25rem;
    text-transform: uppercase;
    background-color: $bg_warning_color;
    color: $writing_warning_color;
    font-weight: 600;
    margin-top: 1rem;
  }

  .moveRequestMobile {
    display: none;
  }
  
  .closeBtnWrapper {
    display: flex;
    justify-content: flex-end;
    .closeBtn {
      height: 2rem;
      width: 2rem;
      cursor: pointer;
    }
  }

  .patientName {
    text-align: start;
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.5rem;
  }
  .topPartMobile {
    display: none;
  }
  .topPart {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
    .nameDateWrapper {
      display: flex;
      flex-direction: column;
      align-items: start;
    }
    .date {
      display: flex;
      align-items: center;
      gap: 0.625rem;
      font-weight: 600;
      padding: 0.75rem 0;
      .toPay,
      .paid {
        width: auto;
        height: auto;
        padding: 0.1875rem 0.375rem;
        border-radius: 0.25rem;
        text-transform: uppercase;
      }
      .toPay {
        background-color: $bg_warning_color;
        color: $writing_warning_color;
      }
      .paid {
        background-color: $bg_confirm_color;
        color: $writing_confirm_color;
      }
    }

    .hidden {
      opacity: 50%;
    }
  }

  .middlePart {
    width: fit-content !important;
    padding: 0.25rem 0.5rem;
    border-radius: 0.5rem;
    font-weight: 500;

    &[class*="psychiatry"] {
      background-color: #CDF5EF !important;
      color: #4BCAB1;
    }

    &[class*="single"] {
      background-color: #DFEAFF !important;
      color: #2254A4;
    }

    &[class*="couple"] {
      background-color: #E0D9FD !important;
      color: #5B4CAA;
    }

    &[class*="underage"] {
      background-color: #E7F5FF !important;
      color: #72B4EE;
    }
  }

  .bottomPart {
    display: flex;
    flex-direction: column;
    align-items: start;
    background-color: $gray_bg;
    margin-top: 1rem;
    padding: 0.75rem;
    border-radius: 0.75rem;
    font-size: 0.875rem;
    color: $gray-2;
    text-align: start;
    margin-bottom: 1rem;
    .titleInfoCard {
      font-weight: 600;
    }

    button {
      display: flex;
      color: $gray-1;
      padding: 1rem 0;
      font-size: 0.875rem;
      text-decoration: underline;
    }
  }
  .mobileBtn {
    display: none;
  }

  &[class*="psychiatry"] {
    border-color: #4BCAB1;
  }

  &[class*="single"] {
    border-color: #2254A4;
  }

  &[class*="couple"] {
    border-color: #5B4CAA;
  }

  &[class*="underage"] {
    border-color: #72B4EE;
  }
}

@media (max-width: $tabletBreakpoint) {
  .appointmentCard {
    padding: 0.75rem;
    border: 0.0625rem solid $gray-5;
    border-radius: 1rem;
    font-size: 0.8125rem;
    font-weight: 600;
    line-height: 1rem;

    .moveRequest {
      margin-top: 0;
    }

    .moveRequestMobile {
      display: block;
    }

    .moveRequestDesktop {
      display: none;
    }

    .appointmentStatusMobile {
      display: flex;
      flex-direction: column;
      gap: 0.75rem;
    }

    .closeBtnWrapper {
      display: flex;
      justify-content: flex-end;
      .closeBtn {
        height: 2rem;
        width: 2rem;
        cursor: pointer;
      }
    }

    .patientName {
      text-align: start;
      font-size: 1.25rem;
      font-weight: 600;
      line-height: 1.5rem;
    }
    .topPart {
      display: none;
    }

    .middlePart {
      margin-top: 1rem;
    }

    .topPartMobile {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .nameDateWrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: start;
      }
      .date {
        display: flex;
        flex-direction: column-reverse;
        align-items: start;
        gap: 1rem;
        font-weight: 600;
        padding: 0;
        width: 100%;
        .dateWrapper {
          width: 100%;
          display: flex;
          justify-content: space-between;
          font-size: 1rem;
          font-weight: 600;
          line-height: 1.25rem;
        }
        .toPay,
        .paid {
          width: fit-content;
          height: auto;
          padding: 0.1875rem 0.375rem;
          border-radius: 0.25rem;
          text-transform: uppercase;
          margin-top: 0.875rem;
        }
        .toPay {
          background-color: $bg_warning_color;
          color: $writing_warning_color;
        }
        .paid {
          background-color: $bg_confirm_color;
          color: $writing_confirm_color;
        }
      }
      button {
        width: 12.6875rem;
      }
    }

    .bottomPart {
      display: flex;
      flex-direction: column;
      align-items: start;
      background-color: $gray_bg;
      margin-top: 1rem;
      padding: 0.75rem;
      border-radius: 0.75rem;
      font-size: 0.875rem;
      color: $gray-2;
      text-align: start;
      .titleInfoCard {
        font-size: 0.875rem;
        font-weight: 600;
        line-height: 1.0625rem;
      }
      .textInfoCard {
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.25rem;
      }

      button {
        width: auto;
        color: $gray-1;
        padding: 1rem 0;
        font-size: 0.875rem;
        text-decoration: underline;
      }
    }
    .mobileBtn {
      display: flex;
      width: 100%;

      &.hidden {
        opacity: 50%;
      }
    }
  }
}

@import "src/index.scss";

.patientChatCard {
  display: flex;
  justify-content: space-between;
  border-bottom: 0.0625rem solid $gray_bg;
  padding: 0.75rem;
  cursor: pointer;

  &.isChatSelected {
    background-color: $secondary-1;
  }

  &.isChatSelectedPsychiatry {
    background-color: #CDF5EF;
  }

  .patientChatCardLeft {
    width: 80%;
    text-align: left;

    .patientChatCardCalendar {
      box-sizing: border-box;
      font-size: 0.875rem;
      color: $gray-3;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      margin-bottom: 0.5rem;

      .icon {
        height: 1rem;
        width: 1rem;
      }
    }

    .patientChatCardName {
      .patientCardNameWrapper {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        margin-bottom: 0.25rem;
      }

      .wrapper {
        display: flex;
        align-items: center;
        gap: 0.5rem;

        p {
          font-weight: 500;
          color: $primary-1;
        }

        &.wrapperPsychiatry {
          p {
            color: #06cbad;
          }
        }

        .typeIcon {
          box-sizing: border-box;
          width: 1.5rem;
          height: 1.5rem;
          padding: 0.25rem;
          border-radius: 6.25rem;
          background-color: $secondary-1;

          .icon {
            color: $primary-1;
            display: block;
            width: 1rem;
            height: 1rem;

            &.iconPsychiatry {
              color: #06cbad;
            }
          }

          &.typeIconPsychiatry {
            background-color: #CDF5EF;
          }
        }

      }
      .name {
        font-weight: 600;
        font-size: 1rem;
        color: #3d3762;
      }

      .message {
        font-size: 0.875rem;
        word-break: break-word;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 10rem;
      }
    }
  }

  .patientChatCardRight {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    justify-content: flex-end;
    gap: 0.5rem;

    .patientChatCardNewMessage {
      width: 1.125rem;
      height: 1.125rem;
      border-radius: 6.25rem;
      background-color: $primary-1;
      color: $bg_main;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.625rem;

      &.patientChatCardNewMessagePsychiatry {
        background-color: #06CBAD;
        color: white;
      }
    }

    .patientChatCardDate {
      color: $gray-3;
    }
  }
}

@import "src/index.scss";

.messageWrapper {
  display: flex;
  flex-direction: column;

  .date {
    color: #737882;
    background-color: #f1f2f4;
    margin: 0 auto;
    padding: 0.25rem 0.625rem;
    border-radius: 1.25rem;
    text-align: center;
  }

  .me,
  .other {
    display: flex;
    flex-direction: column;
    text-align: start;
    .message {
      word-break: break-word;
    }
    &.widget {
      .message {
        word-break: unset;
        background-color: transparent;
      }
    }
  }
  .me {
    align-items: flex-end;
    .message,
    .movedMessage {
      max-width: 80%;
      padding: 1rem;
      border-radius: 0.625rem;
      border-bottom-right-radius: 0;
      margin: 0.3125rem;
      margin-left: 30%;
      background-color: $secondary-1;
    }
    .movedMessage {
      background-color: $bg_warning_color;
      border-radius: 0.625rem;
      margin: 0.5rem auto 0;
      font-size: 0.875rem;
    }
    .hour {
      text-align: end;
      font-weight: 400;
      font-size: 0.8125rem;
      color: $gray-4;
    }
  }

  .other {
    .message,
    .movedMessage {
      max-width: 80%;
      padding: 1rem;
      border-radius: 0.625rem;
      border-bottom-left-radius: 0;
      margin: 0.3125rem;
      margin-right: auto;
      background-color: #e7e1fe;
    }
    .movedMessage {
      background-color: $bg_warning_color;
      border-radius: 0.625rem;
      margin: 0.5rem auto 0;
      font-size: 0.875rem;
    }
    .hour {
      text-align: start;
      font-weight: 400;
      font-size: 0.8125rem;
      color: $gray-4;
    }
  }
}

@media (max-width: $tabletBreakpoint) {
  .messageWrapper {
    .me,
    .other {
      &.widget {
        .message {
          padding: 0;
          max-width: none;
          width: 80%;
        }
      }
    }
  }
}

@media (max-width: $mobileBreakpoint) {
  .messageWrapper {
    .me,
    .other {
      &.widget {
        .message {
          padding: 0;
          max-width: none;
          width: 90%;
        }
      }
    }
  }
}

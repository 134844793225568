@import "src/index.scss";

.medicalWrapper {

  .inputWrapper {
    display: none;
  }

  .textAreaWrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .input {
      text-align: start;
    }

    .button {
      width: fit-content;
      align-self: center;
    }
  }

  .buttonsContainer {
    display: flex;
    justify-content: center;
    gap: 1rem;
    text-align: start;

    .wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 1rem;

      .button {
        width: 12.125rem;
      }

      a {
        margin: 0 auto;
      }
    }
    
    .pdfInstructions {
      width: 30%;
      text-align: center;
    }

    .medicalConfirmation {
      background-color: #9effc0;
      
      &:hover {
        background-color: #75e09d;;
      }
    }

    .medicalDownload {
      background-color: #9effc0;

      &:hover {
        background-color: #75e09d;;
      }

      a {
        color: #101010;
        text-decoration: none;
      }
    }
  }

  .uploadContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;

    .buttonsContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 1rem;
    }

    .pdfPreview {
      cursor: pointer;
    }

    .button {
      a {
        color: #101010;
        text-decoration: none;
      }
    }

    .pdfViewerWrapper {
      position: relative;
      width: 250px;
      height: 353px;

      .pdfViewerBackdrop {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        cursor: pointer;
        background-color: transparent;
        z-index: 1;
      }

      iframe {
        border: none;
        overflow: auto !important;
        overflow-clip-margin: unset !important;
      }
    }
  }

  .fileWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;

    .buttonsContainer {
      display: flex;
      gap: 1rem;
    }
  }

  .container {
    padding: 0 1rem;
  }

  .bottomWrapper {
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
  }

  .collapseWrapper {
    display: none;

    .collapse {
    }
  }

    .bottomPart {
      display: flex;
      flex-direction: column;
      gap: 0.625rem;
      margin-bottom: 2.5rem;
      margin-top: 1.25rem;
      width: 100%;

      .documents {
        display: flex;
        flex-direction: column;
        gap: 1.25rem;
      }

      .month {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        border-bottom: 0.0625rem solid #ccc;
        padding-bottom: 0.625rem;

        h5 {
          text-transform: capitalize;
          align-self: start;
        }

        .previews {
          display: flex;
          gap: 0.625rem;
          flex-wrap: wrap;

          .previewContainer {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 0.5rem;
            max-width: 6.25rem;

            .preview {
              max-width: 5.3125rem;
              width: 100%;
              cursor: pointer;

              .page {
                background-color: transparent !important;

                canvas {
                  margin: 0 auto;
                }
              }

              .image {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }

              .iconWrapper {
                color: #9EBBFF;
              }
            }

            .description {
              display: flex;
              flex-direction: column;
              margin-top: auto;

              p:first-child {
                word-break: break-word;
              }
            }
          }
        }
      }
  }
}

@media (max-width: $tabletBreakpoint) {
  .medicalWrapper {
    padding: 0 1rem 1rem 1rem;

    .collapseWrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 0.5rem;

      .collapse {
        max-width: 15.625rem;
        min-width: 11.875rem;
        width: 100%;

        .collapseList {
          margin-top: 0.5rem;
          display: flex;
          gap: 0.5rem;
          flex-direction: column;
          padding: 0.5rem 0;

          > button {
            font-size: 0.8125rem;
            padding: 0.125rem 0.75rem;
          }
          }
      }

      .inputsWrapper {
        flex-shrink: 0;
        align-self: start;
        display: flex;
        gap: 0.25rem;
      }

      @media (max-width: 30rem) {
        .inputsWrapper {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
        }
      }
    }

    .bottomWrapper {
      display: none;
    }
    .buttonsContainer {
      flex-direction: column;
    }

    .fileWrapper {

      .buttonsContainer {
        flex-direction: column;
      }
    }

      .bottomPart {
        margin-top: 1rem;

        .filters {
          gap: 1rem;
        }
      }
  }
}

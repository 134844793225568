@import "src/index.scss";

.content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  text-align: start;

  .fileWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
  }
}

.firmaFile {
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
  gap: 1rem;

  a {
    max-width: 9.375rem;
    width: 100%;
    height: auto;

    .page {
      background-color: transparent !important;

      canvas {
        margin: 0 auto;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}


@media (max-width: $tabletBreakpoint) {

}

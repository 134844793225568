@import "src/index.scss";

.inputTextWrapper {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  justify-content: space-around;
  position: relative;
  margin-top: 0.5rem;
  &.onboarding {
    z-index: 10000;
    background-color: $bg_main;
    border-radius: 6.25rem;
  }

  .onboardingDescription {
    position: absolute;
    background-color: $bg_main;
    border-radius: 0.75rem;
    z-index: 10001;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    top: -350%;
    .triangle {
      position: absolute;
      bottom: -0.9375rem;
      left: 50%;
      border-left: 0.9375rem solid transparent;
      border-top: 0.9375rem solid $bg_main;
      border-right: 0.9375rem solid transparent;
    }
    span {
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.375rem;
      text-align: start;
      color: $gray-3;
    }
    button {
      width: 100%;
    }
  }

  .iconWrapper {
    border-radius: 100%;
    padding: 0.625rem;
    margin-left: 0.5rem;
    background-color: #f6f6f6;
    display: flex;
    align-items: center;
    cursor: pointer;
    .icon {
      color: $gray-1;
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  .Input {
    width: 100%;
    max-height: 3.375rem;
    display: flex;
    align-items: stretch;
    padding: 0.625rem 0 0.625rem 0.625rem;
    border: 0.125rem solid $border_color;
    border-radius: 1.6875rem;
    textarea {
      min-height: auto !important;
      max-height: 3.375rem !important;
    }
  }

  .scrollButton {
    display: flex;
    align-items: stretch;
  }

  .input {
    text-align: start;
  }

  .fileWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;

    .buttonsContainer {
      display: flex;
      justify-content: center;
      gap: 1rem;
    }
  }
}

@media (max-width: $tabletBreakpoint) {
  .inputTextWrapper {
    padding: 0 0.5rem 0 0.5rem;
    display: flex;
    align-items: center;
    gap: 0.25rem;
    justify-content: space-around;
    position: relative;
    &.onboarding {
      z-index: 10000;
      background-color: $bg_main;
      border-radius: 6.25rem;
    }

    .onboardingDescription {
      position: absolute;
      background-color: $bg_main;
      border-radius: 0.75rem;
      z-index: 10001;
      padding: 1rem;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      top: -350%;
      margin: 0 1rem;

      .triangle {
        position: absolute;
        bottom: -0.9375rem;
        left: 50%;
        border-left: 0.9375rem solid transparent;
        border-top: 0.9375rem solid $bg_main;
        border-right: 0.9375rem solid transparent;
      }
      span {
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.375rem;
        text-align: start;
      }
      button {
        width: 100%;
      }
    }

    .iconWrapper {
      border-radius: 100%;
      padding: 0.625rem;
      margin-left: 0.5rem;
      background-color: #f6f6f6;
      display: flex;
      align-items: center;
      .icon {
        cursor: pointer;
        color: $gray-1;
        width: 1.5rem;
        height: 1.5rem;
      }
    }

    .Input {
      width: 100%;
      max-height: 3.375rem;
      display: flex;
      align-items: stretch;
      padding: 0.625rem 0 0.625rem 0.625rem;
      border: 0.125rem solid $border_color;
      border-radius: 1.6875rem;
      textarea {
        min-height: auto !important;
        max-height: 3.375rem !important;
      }
    }

    .scrollButton {
      display: flex;
      align-items: stretch;
    }

    .fileWrapper {

      .buttonsContainer {
        flex-direction: column;
      }
    }
  }
}

@import "src/index.scss";

.accordion {
	width: 100%;
	padding: 1rem 1.25rem;
	background-color: $blue_bg;
	display: flex;
	flex-direction: column;
	.title {
		display: flex;
		justify-content: space-between;
		svg {
			cursor: pointer;
			width: 1.5rem;
			height: 1.5rem;

			&.iconOpen {
				transform: rotate(90deg);
			}
			&.iconClose {
				transform: rotate(0deg);
			}
		}
	}
}

@import "src/index.scss";

.waitingRoomWrapper {
  height: 100%;
  display: flex;
}
.errorForm {
  max-width: 25rem;
}
.settingModal {
  background-color: $gray-6;
  max-width: 36.875rem;
  [class*="btnWrapper"] {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }
  .selectInput {
    font-size: 0.875rem;
    text-align: start;
    color: #808093;
    .videoCallSelect {
      height: 2.75rem;
      box-sizing: border-box;
      border: 0.125rem solid $primary-1;
      border-radius: 1rem;
      padding: 0.6875rem 1rem;
      border-radius: 0.5rem;
      border: 0.09375rem solid $gray-5;
      width: 100%;
      background-color: $gray-6;
      &::-ms-expand {
        margin-right: 0.625rem;
      }
    }
  }
}

.waitingRoom {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 1.25rem;
  flex-grow: 1;
  gap: 1rem;
  background-color: $blue_bg;

  .logoContainer {
    flex: 0.5;
    .logo {
      width: 9.41875rem;
      height: 3.125rem;
    }
  }
  .card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2.25rem;
    background-color: $bg_main;
    width: 54.8125rem;
    padding: 2rem;
    border-radius: 1rem;
    .headerCard {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .title {
        font-size: 1.5rem;
        font-weight: 600;
        line-height: 1.8125rem;
      }
      span {
        font-size: 1rem;
        font-weight: 600;
        line-height: 1.25rem;
        text-decoration: underline;
        cursor: pointer;
      }
    }
    .previewWrapper {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      width: 100%;
      .publisher {
        height: 19.125rem !important;
        width: 24.125rem !important;
        border-radius: 0.5rem;
      }
      .optionWrapper {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        justify-content: center;
        align-items: stretch;

        .desktopBtn {
          margin-top: calc(2.8125rem - 0.5rem);
        }
      }
    }
  }
  .mobileBtn {
    display: none;
  }
}

@media (max-width: $tabletBreakpoint) {
  .waitingRoom {
    background-color: #ffffff;
    padding: 0;
    .logoContainer {
      width: 100%;
      box-sizing: content-box;
      box-shadow: 0rem 0.125rem 0.4375rem 0rem #0000000a;
      padding: 0.5625rem 0;
    }
    .card {
      padding: 1rem;
      box-sizing: border-box;
      overflow-y: scroll;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 2.25rem;
      background-color: $bg_main;
      width: 100%;
      padding: 1rem;
      border-radius: 1rem;
      padding-bottom: 5rem;
      .headerCard {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .title {
          font-size: 1.5rem;
          font-weight: 600;
          line-height: 1.8125rem;
        }
        span {
          font-size: 1rem;
          font-weight: 600;
          line-height: 1.25rem;
          text-decoration: underline;
          cursor: pointer;
          span {
            display: none;
          }
        }
      }
      .previewWrapper {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: 100%;
        .publisher {
          height: 21.4375rem !important;
          width: 19.125rem !important;
          margin: 0 auto !important;
        }
        .optionWrapper {
          flex: 1;
          display: flex;
          flex-direction: column;
          gap: 1rem;
          justify-content: center;
          align-items: center;
          width: 100%;
          [class*="videoCallButton"],
          button {
            width: 100%;
          }
          .desktopBtn {
            display: none;
          }
          .videoCallSelect {
            height: 2.75rem;
            box-sizing: border-box;
            border: 0.125rem solid $primary-1;
            border-radius: 1rem;
            padding: 0.6875rem 1rem;
            border-radius: 0.5rem;
            border: 0.09375rem solid $gray-5;
            width: 100%;
            background-color: $gray-6;
          }
        }
      }
    }

    .mobileBtn {
      display: block;
      position: fixed;
      bottom: 0;
      width: 100%;
      padding: 1rem 0.625rem;
      box-sizing: border-box;
      border-top: 0.0625rem solid $gray-5;
      background-color: $gray-6;
      button {
        width: 100%;
      }
    }
  }
}

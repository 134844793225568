@import "src/index.scss";

section.faqSection {
  flex-direction: column;
  width: clamp(17.5rem, 100%, 75rem);
  margin: 0 auto;
  margin-bottom: 10rem;

  > [class*="TextContainer"] {
    max-width: 49.5rem;
    margin: 0 auto 4.375rem auto;
    h3 {
      text-align: center;
    }
  }

  > [class*="accordion"] {
    background-color: $bg_main;
    border-bottom: 0.0938rem solid $gray_bg;
    padding: 0;
    width: 100%;
    margin-bottom: 1.5rem;
    > div:first-child {
      min-height: 3.125rem;
      align-items: center;

      svg {
        margin: auto 0;
      }
    }
    > div:last-child {
      > [class*="accordion"] {
        background-color: $bg_main;
        border-bottom: 0.0938rem solid $gray_bg;
        padding: 0 !important;
        > div:first-child {
          min-height: 3.125rem;
          padding-left: 1.25rem !important;
          align-items: center;
          svg {
            margin: auto 0;
          }
        }
        > div:last-child {
          padding-left: 1.25rem;
          p {
            margin-bottom: 1rem;
            font-size: 0.875rem;
            padding-left: 1.25rem !important;
          }
        }
      }
    }
  }

  button {
    margin: 3.625rem auto 0;
    width: 10.125rem;
    padding: 0.75rem, 1.25rem;
    justify-content: center;
    > [class*="Button_icon"] {
      display: flex;
      margin: 0;
      margin-left: 1.125rem;
      width: 1.5rem;
      height: 1.25rem;
      svg {
        margin-left: auto;
      }
    }
  }

  @media only screen and (max-width: $tabletBreakpoint) {
    margin-bottom: 4rem;
    > [class*="accordion"] {
      background-color: $bg_main;
      border-bottom: 0.0938rem solid $gray_bg;
      padding: 0;
      width: 100%;
      margin-bottom: 1.5rem;
      > div:first-child {
        min-height: 3.125rem;
        align-items: center;
        h6 {
          width: clamp(15.625rem, 90%, 43.75rem);
        }
        svg {
          margin: auto 0;
        }
      }
      > div:last-child {
        > [class*="accordion"] {
          background-color: $bg_main;
          border-bottom: 0.0938rem solid $gray_bg;
          padding: 0 !important;
          > div:first-child {
            min-height: 3.125rem;
            padding-left: 0.5rem !important;
            align-items: center;
            span {
              margin: 0.5rem 0;
              width: clamp(15.625rem, 90%, 43.75rem);
            }
            svg {
              margin: auto 0;
              min-width: 1.5rem;
            }
          }
          > div:last-child {
            padding-left: 0.5rem !important;
            p {
              margin-top: 0.5rem;
              padding: 0 1rem 0 0.5rem !important;
            }
          }
        }
      }
    }
  }
}

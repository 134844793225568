@import "src/index.scss";

.verifyEmail {
  background-color: $blue_bg;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.625rem;
  .verifyResult {
    background-color: white;
    max-width: 31.25rem;
    width: 100%;
  }
}
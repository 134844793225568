@import "src/index.scss";

.medicalNotesWrapper {
  display: flex;
  flex-direction: column;
  padding: 1.5rem 0rem;

  .buttonsContainer {
    display: flex;
    justify-content: center;
    gap: 1rem;
  }

  .contentWrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .note {
      display: flex;
      flex-direction: column;
      align-items: start;
      gap: 0.5rem;
      border-radius: 1.5rem;
      padding: 1rem;
      background-color: #F2F4F5;

      .dateContainer {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        color: #808093;
        justify-content: space-between;
        width: 100%;

        .dateWrapper {
          display: flex;
          flex-wrap: wrap;
          gap: 0.5rem;
        }
      }

      .content {
        text-align: start;
      }

      .icon {
        width: 1.25rem;
        height: auto;
        top: 0;
        right: 1.25rem;
        cursor: pointer;
      }
    }
  }

  .inputWrapper {
    text-align: start;
  }

  .medicalButton {
    width: fit-content;
    align-self: center;
    border-radius: 1.375rem;
    border: 0.125rem solid #9EBBFF;
    gap: 0.5rem;
    font-weight: normal;
    color: $gray-1;
  }
}

@media (max-width: $tabletBreakpoint) {
  .medicalNotesWrapper {
    padding: 1rem;

    .filters {
      gap: 1rem;
    }
  }
}
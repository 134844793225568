@import "src/index.scss";

.form {
  display: flex;
  flex-direction: column;
  text-align: start;
  gap: 1rem;

  .spinner {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .errorForm {
    width: 100%;
    display: flex;
    align-items: center;

    [class*="genericForm"] {
      padding: 1.25rem;
      justify-content: space-between;

      [class*="description"],
      [class*="title"] {
        text-align: center;
      }
    }
  }

  > div {

    span {
      margin-bottom: 0.25rem;
      font-size: 0.875rem;
      font-weight: 500;
      letter-spacing: 0em;
      text-align: left;
      color: #808093;
    }
  }

  .textArea {

    textarea {
      min-height: 11.4375rem;
    }
  }

  button {
    margin-top: 1rem;
    width: 9.6875rem;
    align-self: center;

    > [class*="Button_icon"] {
      display: flex;
      margin: 0;
      margin-left: 1.125rem;
      width: 1.5rem;
      height: 1.25rem;
    }
  }

  @media only screen and (max-width: $tabletBreakpoint) {
    width: 100%;
    .inputName,
    .inputSurname {
      width: 100%;
    }
    .inputName {
      margin-bottom: 1.5rem;
    }
    button {
      width: 100%;
    }
  }
}

.supportWrapper {
  box-sizing: border-box;
  position: fixed;
  width: 3.125rem;
  height: 3.125rem;
  inset-inline-end: 2.5rem;
  bottom: 3rem;
  border-radius: 50%;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  box-shadow: 0 0.375rem 1rem 0 rgba(0, 0, 0, 0.08), 0 0.1875rem 0.375rem -0.25rem rgba(0, 0, 0, 0.12), 0 0.5625rem 1.75rem 0.5rem rgba(0, 0, 0, 0.05);

  &.night {
    background-color: $primary-1;

    span {
      font-size: 1.75rem;
    }
  }

  &.day {
    padding: 0.25rem;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &:hover {
    transform: scale(1.1);
  }
}

@media (max-width: $mobileBreakpoint) {
  .supportWrapper {
    inset-inline-end: 1.25rem;
    bottom: calc(16px + var(--dynamic-bottom-height, 2.5rem));
  }
}
@import "src/index.scss";

.button {
  cursor: pointer;
  box-sizing: border-box;
  padding: 1rem;
  border: 0;
  border-radius: 1.0625rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 1rem;
  font-family: 'Poppins';
  line-height: 1.25rem;
  height: 2.75rem;

  .icon {
    margin: 0 0.9375rem;
    width: 1.5rem;
    height: 1.5rem;
  }

  &:disabled {
    opacity: 50%;
    cursor: not-allowed;
  }

  &[class*="small"] {
    padding: 0.25rem 1rem;
    border-radius: 1.375rem;
    border: 0.125rem solid #9EBBFF;
    height: auto;
    gap: 0.5rem;
    font-weight: normal;
    color: $gray-1;
    width: fit-content;
    align-self: center;

    .icon {
      width: 1rem;
      height: 1rem;
      margin: 0 !important;
    }
  }

  &[class*="smallFilter"] {
    padding: 0.125rem 1rem;
    border-radius: 1.375rem;
    border: 0.125rem solid #9EBBFF;
    height: auto;
    gap: 0.5rem;
    font-weight: normal;
    color: $gray-1;

    .icon {
      width: 1rem;
      height: 1rem;
      margin: 0 !important;
    }
  }
}

.primary {
  color: #101010;
  background-color: #9ebbff;
  &:hover {
    background-color: #7595e0;
  }

  &[class*="small"] {
    &:hover {
      background-color: #9ebbff;
    }
  }
}

.secondary {
  border: 0.125rem solid $gray-5;
  background-color: transparent;
  color: $primary-1_dark;
  &:hover {
    background-color: $gray-5;
    background-color: $gray_bg;
  }
}

.tertiary {
  background-color: transparent;
  color: $gray-1;
  border-radius: 0;
}

.profile {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.875rem;
  color: white;
  padding: 0.25rem 2.625rem;
  border-radius: 1rem;
  height: auto;
  gap: 0.5rem;
  background-color: #9ebbff;

  &[class*="single"] {
    background-color: #0354AA;
  }

  &[class*="couple"] {
    background-color: #5B4CAA;
  }

  &[class*="underage"] {
    background-color: #72B4EE;
  }

  &[class*="psychiatry"] {
    background-color: #4BCAB1;
  }
}

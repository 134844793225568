@import "src/index.scss";

.therapistPersonalInfo {
  padding: 2rem 1.5rem;
  flex-grow: 1;
  overflow-y: auto;

  .title {
    font-size: 2rem;
    font-weight: 600;
    line-height: 2.375rem;
    text-align: start;
    margin-bottom: 1.5rem;
  }
  .infoWrapper {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    .topPart {
      display: flex;
      flex-direction: column;
      gap: 1.25rem;
      padding: 0 0.125rem;

      .profilePicture {
        text-align: left;
      }

      .userName {
        display: flex;
        gap: 0.5rem;
        div {
          text-align: start;
          width: 100%;
          box-sizing: border-box;
          div:last-child {
            width: 100%;
          }
        }
        div:last-child {
          width: 30%;
        }
      }
      .userNumber {
        display: flex;
        gap: 0.5rem;
        text-align: start;
        div {
          width: 100%;
          box-sizing: border-box;
          div:first-child {
            width: 100%;
          }
        }
        select {
          width: 100%;
        }

        .insuranceForm {
          min-width: 40%;
          display: flex;
          gap: 0.5rem;
          align-items: end;
        }
      }

      .submitButton {
        display: flex;
        width: fit-content;
        align-self: center;
      }
    }
    .bottomPart {
      display: flex;
      flex-direction: column;
      gap: 2.5rem;
      .inputContainer {
        padding: 1.25rem;
        border-radius: 1.25rem;
        background-color: $gray_bg;
        .inputName {
          text-align: start;
          font-size: 1.25rem;
          font-weight: 600;
          line-height: 1.5rem;
          margin-bottom: 1.5rem;
        }
        .inputToModify {
          display: flex;
          align-items: flex-end;
          gap: 0.5rem;
          text-align: start;
          div {
            width: 100%;
            box-sizing: border-box;
          }
          button {
            text-decoration: underline;
          }
        }
      }
      .btnWrapper {
        display: flex;
        justify-content: end;
        gap: 0.75rem;
      }
    }
    .deleteProfile {
      color: $writing_error_color;
      text-decoration: underline;
    }
  }
  .modifyModalContent {
    background-color: $bg_main;
    padding: 2.5rem 2rem;
    border-radius: 1rem;
    width: 40%;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    .modalDescription {
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.25rem;
      text-align: start;
      span {
        font-size: 0.875rem;
        font-weight: 600;
        line-height: 1.0625rem;
      }
    }
    .motiveWrapper {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      .delMotive {
        padding: 0.6875rem 1rem;
        border-radius: 0.5rem;
        border: 0.09375rem solid $gray-5;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.375rem;
        text-align: start;
        &.active {
          background-color: #f9f8ff;
          border: 0.09375rem solid $primary-1;
        }
      }
    }
    .inputWrapper {
      border-radius: 1rem;
      background-color: $gray_bg;
      padding: 1rem;
      text-align: start;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }
    .infoBoxModal {
      border-radius: 0.5rem;
    }
    .btnWrapper {
      display: flex;
      gap: 0.75rem;
      button {
        width: 100%;
      }
    }
  }
}

@media (max-width: $tabletBreakpoint) {
  .therapistPersonalInfo {
    padding: 1.25rem;
    width: calc(100% - 2.5rem);
    .title {
      display: none;
    }
    .infoWrapper {
      .topPart {

        .profilePicture {
          text-align: center;
        }

        .userName {
          flex-direction: column;
        }
        .userNumber {
          flex-direction: column-reverse;

          .insuranceForm {
            min-width: 100%;
          }
        }
      }
    }
    .modifyModalContent {
      width: 100vw;
      margin: 0 0.5rem;
    }
  }
}

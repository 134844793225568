@import "src/index.scss";

.card {
	display: flex;
	border-radius: 2rem;
	flex-direction: row;
	position: relative;
	background-size: contain;
	&[class*="text-right"] {
		flex-direction: row-reverse;
	}

	&[class*="full-card"] {
		width: clamp(36.75rem, 100%, 75rem);
	}

	&[class*="half-card"] {
		width: clamp(30.625rem, 49%, 36.75rem);
		min-height: 20.8125rem;
		flex-wrap: wrap;
		&[class*="text-right"] {
			flex-direction: column-reverse;
		}

		.index {
			position: absolute;
			top: 0.875rem;
			right: 1.4375rem;
		}
	}

	&[class*="small-card"] {
		display: flex;
		flex-direction: column;
		width: clamp(18.75rem, 32%, 24rem);
		.cardHead {
			display: flex;
			margin-bottom: 0.75rem;
			img {
				width: 2.75rem;
				height: 2.75rem;
				background-color: $secondary-1;
				border-radius: 50%;
				padding: 0.75rem;
			}
			.iconCard {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 2.75rem;
				height: 2.75rem;
				margin-right: 0.75rem;
				background-color: $secondary-1;
				border-radius: 50%;

				svg {
					width: 1.25rem;
					height: 1.25rem;
				}
			}
		}
	}

	position: relative;
	.stain {
		position: absolute;
	}
}

@import "../../../index.scss";

.patientPayment {
  text-align: left;
  max-height: 80%;

  &[class*="modalWithSmallHeader"] {
    margin-top: 5rem;
  }

  .packageContainer {
    display: flex;
    gap: 1.25rem;
    justify-content: center;
    padding: 1.25rem;
    background-color: #f9f9f9;
    border-radius: 0.75rem;

    .card {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      gap: 1.125rem;
      box-sizing: border-box;
      background-color: #fff;
      border: 0.0625rem solid #e0e0e0;
      border-radius: 0.5rem;
      padding: 1.25rem;
      text-align: center;
      box-shadow: 0 0.25rem 0.375rem rgba(0, 0, 0, 0.1);
      transition: transform 0.2s;
      cursor: pointer;

      &:hover {
        transform: translateY(-0.3125rem);
      }

      .packageContainerTitle {
        font-size: 1.125rem;
        font-weight: bold;
        line-height: 1.375rem;
      }

      .description {
        font-size: 0.875rem;
        color: #555;
      }

      .priceContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;

        .oldPrice {
          position: relative;
          color: #999;

          &::after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            background-image: url('data:image/svg+xml;utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22100%25%22%20height%3D%22100%25%22%3E%0A%20%20%20%20%3Cline%20x1%3D%220%22%20y1%3D%22100%25%22%20x2%3D%22100%25%22%20y2%3D%220%22%20stroke%3D%22%23F00%22%20stroke-width%3D%222%22%2F%3E%0A%3C%2Fsvg%3E');
            background-size: cover;
            opacity: 0.7;
          }
        }

        .newPrice {
          font-size: 1.25rem;
          font-weight: bold;
          color: #28a745;
        }
      }

      .price {
        font-size: 1.125rem;
        font-weight: bold;
      }

      .button {
        align-self: center;
      }
    }
  }

  .classNameCloseButton {
    position: relative;
    z-index: 1;

    &:before {
      position: absolute;
      content: url("../../../theme/images/close.webp");
      top: -5.3125rem;
      left: -5.9375rem;
      scale: 0.3;
      z-index: -1;
    }
  }

  .modalHeader {
    gap: 1.875rem;
  }

  .infoBlockWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    background: $secondary-2;
    border-radius: 0.625rem;
    font-size: 0.875rem;
    width: 100%;

    .bookWrapper {
      position: relative;
      z-index: 0;
      margin-left: 1rem;

      &:before {
        position: absolute;
        content: url("../../../theme/images/book-bg.webp");
        top: -3.75rem;
        left: -2.5rem;
        rotate: 165deg;
        scale: 0.4;
        z-index: -1;
      }

      img {
        margin-top: 1rem;
        width: 6.875rem;


      }
    }

    .infoBlockDateWrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-weight: 500;
      margin-right: 1rem;

      .infoBlockDate {
        text-transform: uppercase;
        padding-bottom: 0.5rem;
        margin-bottom: 0.5rem;
        border-bottom: 0.125rem #8AD0C4 solid;
        font-weight: 900;
      }
    }
  }

  &.widget {
    background-color: #ffffff;
    padding: 2.5rem 2rem;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    gap: 2.125rem;
    max-width: 50%;
    width: 100%;
    overflow-y: auto;
    scroll-behavior: smooth;

    &.success {
      gap: 0;
    }
  }

  .title {
    color: $gray-1;
    font-size: 2rem;
    font-weight: 600;
    line-height: 2.375rem;
    margin-bottom: 1.5rem;
  }

  .subtitle {
    color: $gray-1;
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .subtitleContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.375rem;

    .subtitle {
      margin-bottom: 0;
    }

    button {
      span {
        display: none;

        &:first-child {
          display: inline;
        }
      }
    }
  }

  .text {
    color: $gray-text-lighter;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.375rem;
    margin-bottom: 1.375rem;
  }

  .savedCardsContainer {
    margin-bottom: 1.25rem;
  }

  .savedCards {
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
  }

  .addPaymentMethodModal {
    background-color: $bg-main;
    padding: 2.5rem 2rem;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    gap: 2.125rem;
    width: 36.875rem;

    .titleContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    .title {
      color: $gray-1;
      font-size: 1.5rem;
      font-weight: 600;
      line-height: 1.75rem;
      margin-bottom: 0;
    }

    .closeIcon {
      cursor: pointer;
    }
  }

  .form {
    border-radius: 1rem;
    text-align: left;
    background-color: $gray-6;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2.75rem;
    box-sizing: border-box;
    max-height: 98vh;
    height: 100%;

    .formButtons {
      display: flex;
      flex-direction: row;
      gap: 1.5rem;
      margin-top: calc(2.75rem - 1rem);

      button {
        width: 100%;
      }
    }

    .formContent {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .couponWrapper {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        margin-bottom: 1rem;

        .inputWrapper {
          box-sizing: border-box;
          width: 100%;
          position: relative;
        }

        .couponSession {
          background-color: #c1f2ec;
          padding: 0.5rem 0.5rem;
          border-bottom-left-radius: 0.5rem;
          border-bottom-right-radius: 0.5rem;
          margin-top: -8px;
          color: #4F9C8D;
          text-align: center;
        }

        .couponClose {
          position: absolute;
          width: 20px;
          height: 20px;
          top: 12px;
          right: 12px;
          cursor: pointer;
        }
      }

      .nameFieldBottomSpace {
        margin-bottom: 1rem;
      }

      .spinner {
        color: black;
        margin-bottom: 2.5rem;
      }

      .defaultCard {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        background-color: $gray-bg;
        border-radius: 0.75rem;
        padding: 1rem;

        .defaultCardInfo {
          display: flex;
          flex-direction: column;
          gap: 0.375rem;
        }

        .cardBrand {
          font-size: 0.875rem;
          font-weight: 500;
          line-height: 1.25rem;
          color: $gray-3;
        }

        .cardLast4 {
          font-size: 1rem;
          font-weight: 400;
          line-height: 1.375rem;
          color: $gray-1;
        }
      }

      .futurePayments {
        display: flex;
        flex-direction: row;
        gap: 0.375rem;
        font-size: 0.875rem;
      }
    }

    .savedPayment {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;

      .paymentCard {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: $gray_bg;
        padding: 1.25rem 1rem;

        .leftSide {
          display: flex;
          gap: 1rem;
          width: 100%;

          .iconWrapper {
            height: 2rem;
            width: 2rem;
            padding: 0.3125rem;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            border-radius: 100%;
            color: $primary-1;
            background-color: $secondary-1-60;

            .icon {
              width: 1.3rem;
            }
          }

          .paymentDetail {
            display: flex;
            flex-direction: column;

            .name {
              color: $gray-3;
              font-size: 0.875rem;
              font-weight: 500;
            }
          }
        }

        .delBtn {
          color: $gray-1;
          font-weight: 600;
          text-decoration: underline;
        }
      }

      .btnWrapper {
        display: flex;
        flex-direction: row;

        div:first-child {
          color: $gray-1;
          text-decoration: underline;
        }

        div {
          width: 100%;
          justify-content: center;
        }
      }
    }

    .cardDetail {
      border-radius: 0.5625rem;
      padding: 1rem;
      display: flex;
      flex-direction: column;
      gap: 1.1875rem;

      .creditCardHeader {
        display: flex;

        .cardIcon {
          height: 1.5rem;
          width: 1.5rem;
          margin-right: 0.5rem;
          color: $primary-1;
        }

        .title {
          font-weight: 600;
        }
      }

      .cardNumber,
      .cardExpire {
        display: flex;
        gap: 0.5rem;

        .cvc {
          width: 20%;
        }
      }

      .saveCard {
        display: flex;
        align-items: center;

        .checkbox {
          height: 1.25rem;
          width: 1.25rem;
          border: 0.0625rem solid #bbbbbb;
        }

        p {
          margin: 0 0.5rem;
          font-size: 1rem;
        }
      }
    }

    .btnWrapper {
      display: flex;
      flex-direction: column;
      gap: 2.75rem;

      .paymentMethod {
        font-weight: 600;
        color: black;
        border: 0.0625rem solid $secondary-1;

        &:hover {
          border: 0.0625rem solid $primary-1;
          background-color: $secondary-1-60;
        }
      }

      .addBtn {
        justify-content: center;
        padding: 0.625rem;
        border-radius: 6.25rem;
      }
    }
  }

  .successPaymentWrapper {
    margin-top: -0.625rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: center;

    .imgWrapper {
      position: relative;
      z-index: 0;

      &:before {
        position: absolute;
        content: url("../../../theme/images/successPaymentBlueElement.webp");
        top: 0;
        right: -3.125rem;
        rotate: 358deg;
        z-index: -1;
      }

      &:after {
        position: absolute;
        content: url("../../../theme/images/successPaymentGreenElement.webp");
        top: 2.5rem;
        left: -3.125rem;
        rotate: 170deg;
        scale: 1;
        z-index: -1;
      }

      img {
        width: 11.875rem;
      }
    }

    .title {
      font-weight: 900;
      font-size: 1.5rem;
      margin-bottom: 0;
    }

    .text {
      text-align: center;
      margin-bottom: 0;

      span {
        font-weight: 600;
      }
    }
  }

  .couponWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
  }
}

@media (max-width: $tabletBreakpoint) {
  .patientPayment {

    &.widget {
      width: auto;
      margin-right: 0.5rem;
      margin-left: 0.5rem;
      max-width: 100%;
      padding: 1rem;
      max-height: calc(100% - 8.75rem);
      gap: 1rem;
      margin-top: 4.375rem;
      height: fit-content;
    }

    &[class*="modalWithSmallHeader"] {
      margin-top: 4.375rem;
    }

    &[class*="modalFullHeight"] {
      max-height: calc(100% - 3.125rem);
      margin-top: 0;
    }

    .title {
      display: none;
      color: $gray-1;
      font-size: 2rem;
      font-weight: 600;
      line-height: 2.375rem;
      margin-bottom: 1.5rem;
    }

    .subtitle {
      color: $gray-1;
      font-size: 1.25rem;
      font-weight: 600;
      line-height: 1.5rem;
      margin-bottom: 1.5rem;
    }

    .subtitleContainer {
      display: flex;
      flex-direction: column;
      align-items: start;
      gap: 1.25rem;
      margin-bottom: 1.375rem;

      .subtitle {
        margin-bottom: 0;
      }

      button {
        span {
          display: inline;

          &:first-child {
            display: none;
          }
        }
      }
    }

    .text {
      color: $gray-text-lighter;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.25rem;
      margin-bottom: 1.375rem;
    }

    .boldText {
      color: $gray-text-lighter;
      font-size: 1rem;
      font-weight: 600;
      line-height: 1.375rem;
    }

    .savedCardsContainer {
      margin-bottom: 1.25rem;
    }

    .savedCards {
      display: flex;
      flex-direction: column;
      gap: 0.625rem;
    }

    .addPaymentMethodModal {
      background-color: $bg-main;
      padding: 2.5rem 1rem;
      margin: 0 1.25rem;
      border-radius: 1rem;
      display: flex;
      flex-direction: column;
      gap: 2.125rem;
      width: 36.875rem;

      .titleContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }

      .title {
        color: $gray-1;
        font-size: 1.5rem;
        font-weight: 600;
        line-height: 1.75rem;
        margin-bottom: 0;
      }

      .closeIcon {
        cursor: pointer;
      }
    }

    .form {

      .cardDetail {
        .cardNumber {
          flex-direction: column;
        }
      }
    }
  }
}

@media (max-width: $mobileBreakpoint) {
  .patientPayment {

    .modalHeader {
      p {
        font-size: 1.125rem;
      }
    }

    .description {
      font-size: 0.875rem;
    }

    .infoBlockDateWrapper {
      font-size: 0.75rem;
    }

    .successPaymentWrapper {
      gap: 1rem;

      .imgWrapper {
        &:before {
          top: -0.625rem;
          right: -3.75rem;
          scale: 0.8;
        }

        &:after {
          top: -0.625rem;
          left: -5rem;
          scale: 0.8;
        }

        img {
          width: 8.125rem;
        }
      }

    }

    .form {
      position: relative;

      .formButtons {
        display: block;
        position: sticky;
        bottom: -1.125rem;
        margin-left: -1rem;
        width: calc(100% + 2rem);
        padding: 1rem 0.625rem;
        box-sizing: border-box;
        border: 0.0625rem solid rgb(223, 234, 255);
        border-radius: 0.3125rem;
        background-color: $gray-6;
        z-index: 100;

        button {
          width: 100%;
        }
      }
    }

    .classNameCloseButton {
      &:before {
        top: -5.625rem;
        left: -5.9375rem;
        scale: 0.23;
        z-index: -1;
      }
    }

    .infoBlockWrapper {
      gap: 1rem;
    }

    &.widget {
      overflow-x: hidden;
    }
  }
}

@media (max-width: 35.625rem) {
  .patientPayment {
    .packageContainer {
      flex-direction: column;
    }
  }
}

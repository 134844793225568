@import "src/index.scss";

.info {
  text-align: start;
  border-radius: 1.5rem;
  padding: 0.625rem;
  z-index: 99;
  background-color: $gray_bg;
  // box-shadow: 0 3.125rem 1.25rem 0 $bg_main99;
  // margin: 1rem 1rem 0;
  &.warn {
    background-color: $bg_warning_color;
    color: $writing_warning_color;
  }
  &.confirm{
    background-color: $bg_confirm_color;
    color: $writing_confirm_color;
  }

  &.error {
    background-color: $bg_error_color;
    color: $writing_error_color;
  }

  &.infoPayment {
    width: fit-content;
    background-color: $secondary-2;
    color: #4F9C8D;
  }

  .infoWrapper {
    margin: 0 1rem;
    &.withTitle {
      display: flex;
      align-items: flex-start;
      .infoMessage {
        margin-left: 0.25rem;
      }
    }
  }

  .infoTitle {
    font-weight: 600;
    font-size: 0.875rem;
  }

  .infoTitleClose {
    font-weight: 600;
    font-size: 0.875rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .closeBtn {
      display: flex;
      justify-content: center;
      height: 2.5rem;
      width: 2.5rem;
      cursor: pointer;

      .closeIcon {
        width: 1.5rem;
      }
    }
  }

  .infoMessage {
    font-weight: 400;
    font-size: 0.875rem;
  }

  .infoMessageClickable {
    cursor: pointer;
    text-decoration: underline;
  }
}

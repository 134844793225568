@import "src/index.scss";
main {
	max-width: 100vw;
	overflow-x: hidden;
	overflow-y: auto;
	padding: 0 0.5rem;
	@media only screen and (max-width: $tabletBreakpoint) {
		padding: 0 1rem;
	}
}
.reviews {
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	* {
		text-align: initial;
		margin: 0;
		box-sizing: border-box;
	}
	margin: 0 auto;
	max-width: 85rem;
	justify-content: center;
	box-sizing: border-box;
	background-color: white;

	section {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		max-width: 85rem;
		margin: 0 auto;
	}
	> [class*="IntroductorySection"] {
		> [class*="Card"] {
			> [class*="TextContainer"] {
				width: clamp(23.4375rem, 83%, 62.1875rem);
			}
		}

		@media only screen and (max-width: $tabletBreakpoint) {
			> [class*="Card"] {
				> [class*="TextContainer"] {
					width: 100%;
				}
			}
		}
	}

	> [class*="Footer"] {
		margin: 0 auto;
		margin-bottom: 1.5rem;
		padding: 2.5rem 4.25rem;
		@media only screen and (max-width: $tabletBreakpoint) {
			padding: 1.25rem;
		}
	}
}

@import "src/index.scss";

.cardReview {
  padding: 1rem;
  margin-top: 1rem;
  text-align: left;
  background-color: #f3f0ff40;
  border-radius: 1rem;

  .topWrapper {
    display: flex;
    align-items: center;
    gap: 1.375rem;
    height: 4.25rem;

    .avatar {
      width: 4.25rem;
      height: 4.25rem;
    }

    .titleStarWrapper {
      display: flex;
      flex-direction: column;

      .starVote {
        height: 1.5rem;
        color: $primary-2;
        display: flex;

        svg {
          width: 1.25rem;
        }
      }

      .reviewTitle {
        font-weight: 600;
      }
    }
  }

  .reviewText {
    margin-top: 1rem;
    font-size: 0.87rem;
    font-weight: 400;
  }
}

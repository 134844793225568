// ------ PRIMARY ------
$primary-3: #866afb;
$primary-1_dark: #0354aa;
$primary-1_light: #9ebbff;
$primary-1-60: #7a759960;
$primary-2: #06cbad;
$primary-1: #3576bb;

// ------ SECONDARY ------
$secondary-3: #e1d9fe;
$secondary-1-40: #e1d9fe40;
$secondary-1-60: #e1d9fe60;
$secondary-2: #c1f2ec;
$secondary-2-40: #c1f2ec40;
$secondary-1: #dfeaff;

// ------ GREY SCALE ------
$gray-1: #2c2c4b;
$gray-2: #56566f;
$gray-3: #808093;
$gray-4: #ababb7;
$gray-5: #d5d5db;
$gray-6: #ffffff;

$gray-text-lighter: #3D3762;

// ------ MAIN BACKGROUND ------
$bg_main: #ffffff;
$blue_bg: #f7fafd;
$gray_bg: #f9f9f9;

// ------ WARNING ------
$bg_warning_color: #fbefe5;
$writing_warning_color: #a04f0b;

// ------ CONFIRM ------
$bg_confirm_color: #d1f2de;
$writing_confirm_color: #035b4e;

// ------ ERROR ------
$bg_error_color: #ffe7e7;
$writing_error_color: #c3012f;
$button_error_color: #CB4539;

$error-text: #9f3a38;
$error-background: #fff6f6;
$error-border: #eb7676;
$error-outline: #e0b4b4;

// ------ INPUT CHAT ------
$border_color: #dededf;

@import "src/index.scss";
.imageContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 20.625rem;
  max-width: 50%;
  img:first-child {
    max-height: 20.625rem;
	  max-width: 100%;
    width: auto !important;
  }
  .stain {
    position: absolute;
  }
}

.imagesGroupContainer {
  position: relative;
  display: flex;
  > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .stain {
    position: absolute;
  }
}

@import "src/index.scss";

.description {
  font-size: 0.875rem;
  line-height: 1.25rem;
    text-align: start;
    color: $gray-text-lighter;
  }
  .btnWrapper {
    display: flex;
    gap: 0.75rem;
    button {
      width: 100%;
    }
  }

@media (max-width: $tabletBreakpoint) {
  .description {
    font-size: 0.875rem;
    line-height: 1.25rem;
      text-align: start;
      color: $gray-text-lighter;
    }
    .btnWrapper {
      display: flex;
      flex-direction: column-reverse;
      gap: 0.75rem;
      button {
        width: 100%;
      }
    }
}
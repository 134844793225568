@import "src/index.scss";
.header {
  background-color: $bg_main;
  height: 10vh;
  width: 100vw;
  padding: 0.7rem 3.125rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  color: $gray-1;
  position: fixed;
  top: 0;
  z-index: 10001;


  .appointmentMenu {
    display: flex;
    align-items: center;
    padding-right: 1.25rem;
    gap: 0.5rem;
    color: $gray-1;
    cursor: pointer;
    position: relative;
    z-index: 1;

    div {
      display: flex;
    }

    .profilePicture {
      background-color: #AAE5DB;
      padding: 0.375rem;
      width: 1.5rem;
      height: 1.5rem;
    }

    .appointmentMenuOpen {
      width: 100%;
      min-width: fit-content;
      position: absolute;
      top: -0.625rem;
      right: 0.625rem;
      white-space: nowrap;
      border-radius: 0.9375rem;
      border: 0.0625rem solid #D5F8F3;
      padding: 5rem 1.25rem 1.25rem;
      display: flex;
      flex-direction: column;
      align-items: end;
      gap: 1rem;
      background: white;
      z-index: -1;

      span {
        display: flex;
        width: 100%;
        justify-content: end;
        padding-top: 0.5rem;
        border-top: 0.0625rem solid #D5F8F3;

      }
    }
  }

  .logo {
    cursor: pointer;
    height: 3.125rem;
  }

  .btnToSurvey {
    padding: 0.75rem 1.25rem;
    color: $gray-1;
    > [class*="Button_icon"] {
      margin-right: 0;
    }
  }

  .toLogin {
    span {
      cursor: pointer;
      font-weight: 600;
      color: $primary-1_dark;
    }
  }

  .chooseTherapistLogout {
    span {
      cursor: pointer;
      font-weight: 600;
      color: $primary-1_dark;
    }
  }

  .chooseTherapistMenu {
    display: none;
  }

  &[class*="landing-page-header"] {
    // width: clamp(15.625rem, 100%, 75rem);
    height: 10vh;
    // padding: 0 0.5rem;
    margin: 0 auto;
    flex-direction: row !important;
    justify-content: space-between !important;
    // .logo {
    //   cursor: pointer;
    //   height: 3.125rem;
    // }
    .btnWrapper {
      display: flex;
      gap: 0.75rem;
    }
    .navbar {
      list-style: none;
      // width: 21.9375rem;
      width: 100%;
      height: 1.25rem;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      color: $gray-1;
      font-weight: 600;
      li {
        cursor: pointer;
        position: relative;
        &:hover,
        &.pageSelected {
          &::after {
            content: "";
            display: block;
            width: 1.625rem;
            height: 0.25rem;
            left: calc(50% - 0.8125rem);
            position: absolute;
            background-color: #9ebbff;
          }
        }
      }
      li:not(:last-child) {
        margin-right: 1.5rem;
      }
    }

    .menuHeader {
      display: none;
    }

    @media only screen and (max-width: $tabletBreakpoint) {
      height: 5rem;
      border-bottom: 0.0625rem solid #ebebec;
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;

      .logo {
        height: 2.8125rem;
        // width: clamp(8.375rem, 41.8%, 9.8125rem);
      }
      .navbar {
        display: none;
      }
      .menuHeader {
        width: 3rem;
        height: 2.8125rem;
        display: flex;
        cursor: pointer;
        font-weight: 500;
        svg {
          width: 1.5rem;
          margin: 0 auto;
          path {
            width: 100%;
          }
        }

        .menuClose {
          display: flex;
          flex-direction: column;
        }

        .menuOpen {
          display: flex;
          margin: auto;
        }
      }

      .btnToSurvey {
        display: none;
      }
    }
  }

  &[class*="login-header"] {
    justify-content: center;
    width: 100%;
    height: 10vh;
    @media only screen and (max-width: $tabletBreakpoint) {
      height: 5rem;
    }
  }

  &[class*="survey-header"] {
    justify-content: center;
    width: 100%;
    height: 10vh;
    @media only screen and (max-width: $tabletBreakpoint) {
      height: 5rem;
    }
  }

  &[class*="survey-header-start"] {
    justify-content: space-between;
    width: 100%;
    height: 5rem;
    @media only screen and (max-width: $tabletBreakpoint) {
      height: 5rem;
    }
  }

  &[class*="first-appointment-header"] {
    justify-content: space-between;
    width: 100%;
    height: 5rem;
    @media only screen and (max-width: $tabletBreakpoint) {
      height: auto;
      padding: 1rem 1rem;
      justify-content: space-between !important;
      flex-wrap: wrap;
      gap: 0.5rem;
    }
    @media only screen and (max-width: $mobileBreakpoint) {
      img {
        height: 2.5rem;
      }
    }
  }
}
.menu {
  // display: none;
  margin: 0;
  color: $gray-1;

  ul {
    list-style: none;
    padding: 0;
    li {
      cursor: pointer;
      height: 5.5rem;
      margin-top: 0.5rem;
      line-height: 5.5rem;
      padding-bottom: 0.5rem;
      border-bottom: 0.0625rem solid #ebebec;
      font-size: 1.25rem;
      font-weight: 600;
      &:hover {
        background-color: #ebebec;
      }
    }
  }
  @media only screen and (max-width: $tabletBreakpoint) {
    display: flex;
    position: fixed;
    z-index: 9999;
    height: 100%;
    width: 100%;
    background-color: white;;
    .menuLinks {
      margin: 0;
      padding: 0.5rem 1.25rem;
      padding-top: calc(10vh + 0.5rem);
      background-color: white;
      position: relative;
      z-index: 9;
      width: 100vw;
      height: calc(100vh - 5rem);
      button {
        width: 100%;
        margin-top: 3rem;
      }
    }
  }
}

@media (max-width: $tabletBreakpoint) {
  .header {
    justify-content: space-around !important;

    &.chooseTherapistHeader {
      justify-content: space-between !important;
    }

    .chooseTherapistLogout {
      display: none;
    }

    .chooseTherapistMenu {
      display: flex;
      flex-direction: column;
      .menuClose {
        display: flex;
        flex-direction: column;

      }
    }

    .logo {
      height: 3.125rem;
    }
    .btnWrapper {
      display: none !important;
    }
    .homepageLogin {
      .logo {
        height: 3.125rem;
      }
      .toLogin {
        display: none;
      }
    }
    .toLogin {
      display: none;
    }
  }
}
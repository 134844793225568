@import "../../../index.scss";

.TherapistBillingDetails {
  padding: 2rem 1.5rem;
  text-align: left;
  flex-grow: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;

  .hiddenButton {
    display: none;
  }
  .title {
    color: $gray-1;
    font-size: 2rem;
    font-weight: 600;
    line-height: 2.375rem;
    margin-bottom: 0rem;
  }
  .billingDetailsForm {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
    gap: 0.9375rem;
    .billingDetailsInputContainer {
      display: flex;
      flex: 1;
      justify-content: space-between;
      gap: 0.9375rem;
      .billingDetailsInput {
        width: 100%;
      }
    }
  }
  .formButtonsContainer {
    display: flex;
    flex-direction: row;
    justify-content: end;
    gap: 0.75rem;
    margin-top: 2.5rem;
  }
}

@media (max-width: $tabletBreakpoint) {
  .TherapistBillingDetails {
    padding: 1.25rem;
    width: calc(100% - 2.5rem);
    gap: 1rem;
    .title {
      display: none;
    }
    .billingDetailsInputContainer {
      flex-direction: column;
    }
    .formButtonsContainer {
      flex-direction: column-reverse;
    }
  }
}
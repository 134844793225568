@import "../../../index.scss";

.userPaymentMethod {
  text-align: left;
  flex-grow: 1;
  overflow-y: auto;
  padding: 2rem 1.5rem;

  .dateWrapper {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    .label {
      font-size: 0.875rem;
      text-align: start;
      color: $gray-3;
      &.errorLabel {
        color: $error-text
      }
    }
    .dates {
      display: flex;
      gap: 0.9375rem;
      div {
        width: 100%;
      }
    }
  }
  .title {
    color: $gray-1;
    font-size: 2rem;
    font-weight: 600;
    line-height: 2.375rem;
    margin-bottom: 1.5rem;
  }
  .subtitle {
    color: $gray-1;
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.5rem;
    margin-bottom: 1.5rem;
  }
  .subtitleContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.375rem;
    .subtitle {
      margin-bottom: 0;
    }

    button {
      span {
        display: none;
        &:first-child {
          display: inline;
        }
      }
    }
  }
  .text {
    color: $gray-text-lighter;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.375rem;
    margin-bottom: 1.375rem;
  }
  .boldText {
    color: $gray-text-lighter;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.375rem;
  }
  .checkboxText {
    color: $gray-text-lighter;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.375rem;
  }
  .savedCardsContainer {
    margin-bottom: 1.25rem;
  }
  .savedCards {
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
  }
  .billingDetailsForm {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
    gap: 0.9375rem;
    .billingDetailsInputContainer {
      display: flex;
      flex: 1;
      justify-content: space-between;
      gap: 0.9375rem;
    }
    .billingDetailsInput {
      width: 100%;
    }
  }
  .expensesDeductionCheckboxContainer {
    display: flex;
    flex-direction: row;
    align-items: start;
    gap: 0.75rem;
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }
  .formButtonsContainer {
    display: flex;
    flex-direction: row;
    justify-content: end;
    gap: 0.75rem;
  }
}

@media (max-width: $tabletBreakpoint) {
  .userPaymentMethod {
    text-align: left;
    padding: 1.25rem;
    flex-grow: 1;
    // width:calc(100% - 2.5rem);
    overflow-y: auto;
    .dateWrapper {
      .dates {
        flex-direction: column;
      }
    }
    .title {
      display: none;
      color: $gray-1;
      font-size: 2rem;
      font-weight: 600;
      line-height: 2.375rem;
      margin-bottom: 1.5rem;
    }
    .subtitle {
      color: $gray-1;
      font-size: 1.25rem;
      font-weight: 600;
      line-height: 1.5rem;
      margin-bottom: 1.5rem;
    }
    .subtitleContainer {
      display: flex;
      flex-direction: column;
      align-items: start;
      gap: 1.25rem;
      margin-bottom: 1.375rem;
      .subtitle {
        margin-bottom: 0;
      }
      button {
        span {
          display: inline;
          &:first-child {
            display: none;
          }
        }
      }
    }
    .text {
      color: $gray-text-lighter;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.25rem;
      margin-bottom: 1.375rem;
    }
    .boldText {
      color: $gray-text-lighter;
      font-size: 1rem;
      font-weight: 600;
      line-height: 1.375rem;
    }
    .checkboxText {
      color: $gray-text-lighter;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.375rem;
    }
    .savedCardsContainer {
      margin-bottom: 1.25rem;
    }
    .savedCards {
      display: flex;
      flex-direction: column;
      gap: 0.625rem;
    }
    .billingDetailsForm {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: space-between;
      gap: 0.9375rem;
      .billingDetailsInputContainer {
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: space-between;
        gap: 0.9375rem;
      }
      .billingDetailsInput {
        width: 100%;
      }
    }
    .expensesDeductionCheckboxContainer {
      display: flex;
      flex-direction: row;
      align-items: start;
      gap: 0.75rem;
      margin-top: 1.25rem;
      margin-bottom: 1.25rem;
    }
    .formButtonsContainer {
      display: flex;
      flex-direction: column-reverse;
      justify-content: end;
      gap: 0.75rem;
    }
  }
}

@import "src/index.scss";

.retrievePassword {
  background-color: #f7fafd;
  display: flex;
  justify-content: center;
  align-items: center;
  .form {
    width: 50%;
    background-color: white;
  }
  .handleForm {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .pwInfo{
    font-size: 0.75rem;
    ul{
      margin: 0;
      padding-left: 0.75rem;
    }
  }
}

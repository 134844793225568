@import "../../../index.scss";

.therapistPreferences {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  flex-grow: 1;
  overflow-y: auto;
  padding: 2rem 1.5rem;

  .guidelinesWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 2rem;
    background-color: #f9f9f9;
    padding: 0.5625rem 1.0625rem;
    border-radius: 1rem;

    span {
      font-size: 1.25rem;
      font-weight: 600;
    }
  }

  .firmaWrapper {
    display: flex;
    flex-direction: column;
    align-items: start;
    background-color: #f9f9f9;
    padding: 0.5625rem 1.0625rem;
    border-radius: 1rem;
    gap: 1rem;

    .name {
      font-size: 1.25rem;
      font-weight: 600;
    }
  }

  a {
    text-decoration: none;
  }

  .stopNewMatchesModal {
    background-color: #ffffff;
    max-width: 60%;
    [class*="header"]{
      p{
        font-size: 1.75rem;
      }
    }
    [class*="btnWrapper"] {
      gap: 0.5rem;
    }
    p {
      font-size: 1rem;
    }
  }

  .deleteFirma {
    margin: 1.5rem 0;
  }

  .title {
    font-size: 2rem;
    font-weight: 600;
    line-height: 2.375rem;
    text-align: start;
  }
  .infoBox {
    display: flex;
    align-items: center;
    text-align: start;
    background-color: $gray_bg;
    padding: 0.5625rem 1.0625rem;
    border-radius: 1rem;
    .textTitle {
      font-size: 1.25rem;
      font-weight: 600;
      line-height: 1.5rem;
      margin-bottom: 0.3125rem;
    }
    .description {
      font-size: 0.875rem;
      line-height: 1.25rem;
    }
  }
  .stopBtn {
    color: $writing_error_color;
  }
  .resumeBtn {
    color: $writing_confirm_color;
  }
  .preferenceCard {
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
    text-align: start;
    padding: 0.125rem;
    .cpMultiselect {
      position: relative;
      width: 100%;
      [class*="chip"] {
        display: none;
      }
    }
    .closeIcon {
      position: absolute;
      width: 1.25rem;
      right: 0.625rem;
      top: 0.9375rem;
      // transform: translateY(-3rem);
    }
    .preferenceTitle {
      font-size: 1rem;
      font-weight: 600;
      line-height: 1.25rem;
    }
    .preferenceDescription {
      font-size: 0.875rem;
      line-height: 1.25rem;
    }
  }
  .btnWrapper {
    display: flex;
    justify-content: right;
    align-items: center;
    gap: 1.25rem;
  }
}

@media (max-width: $tabletBreakpoint) {
  .therapistPreferences {
    gap: 1.25rem;
    padding: 1.25rem;
    width: calc(100% - 2.5rem);
    .stopNewMatchesModal {
      max-width: 100%;
      margin: 0.5rem;
      [class*="btnWrapper"] {
        gap: 0.5rem;
      }
      p {
        font-size: 1rem;
      }
    }
    .title {
      display: none;
    }
    .infoBox {
      flex-direction: column;
      gap: 1.25rem;
    }
    .resumeBtn {
      width: 100%;
    }
    .stopBtn {
      width: 100%;
    }
    .btnWrapper {
      flex-direction: column-reverse;
      button {
        width: 100%;
      }
    }
  }
}

@import "src/index.scss";

.patientAppointments {
  overflow: hidden;
  padding: 2rem 1.5rem 1rem;
  background-color: $gray-6;
  border-radius: 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 2rem;

  .firstAppointmentButtons {
    display: flex;
    justify-content: end;

    .space {
      margin-right: 0.625rem;
    }
  }

  .patientAppointmentHeader {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .therapistTab {
      .customLabelWrapper {
        border-radius: 0.75rem;
        gap: 0.375rem;
      }
      .customActiveLabel {
        background-color: transparent;
        border: 0.0625rem solid;
        border-radius: 0.75rem;
      }
      .tabLabel {
        display: flex;
        align-items: center;
        gap: 0.25rem;
        .icon {
          width: 1.5rem;
          height: 1.5rem;
        }
      }
    }
  }
  .tabs {
    padding: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: start;
    .bottomPart {
      .chipsWrapper {
        display: flex;
        gap: 0.625rem;
        margin-bottom: 2.5rem;
      }
    }
    .bottomPart,
    .emptyStateContainer {
      height: fit-content;
      width: 100%;
      margin-top: 1rem;

      &.appointment {
        overflow-y: auto;
        height: 100%;
        scrollbar-width: none;
        &::-webkit-scrollbar {
          display: none;
        }

        .scrollButton {
          color: white;
          position: fixed;
          bottom: 2.75rem;
          right: 2.125rem;
          background-color: $primary-1_light;
          cursor: pointer;
          border: none;
          padding: 0.5rem;
          width: 2.75rem;
          height: 2.75rem;
          border-radius: 0.5rem;
          z-index: 101;

          &:hover {
            color: #1B1D1F;
            background-color: $secondary-1;
          }
        }

      }
      .appointmentsSection {
        text-align: start;
        margin-bottom: 1rem;
      }

      .appointmentWrapper {
        display: flex;
        flex-direction: column;

        .appointmentContainer {
          border-radius: 0.9375rem;
          border: 0.0625rem solid $secondary-1;
          padding: 1.25rem;
          width: auto;

        .appointmentHeader {
          display: flex;
          flex-direction: column;
          align-items: start;
          gap: 0.5rem;
          margin-bottom: 1rem;
          text-align: start;

          p {
            display: flex;
            font-size: medium;
            font-weight: bold;
            white-space: wrap;
          }
        }

        .legendWrapper {
          display: flex;
          align-items: center;
          gap: 1.5rem;
          margin-top: 1rem;
          margin-bottom: 1rem;
          border-radius: 0.9375rem;
          border: 0.0625rem solid #dfeaff;
          padding: 1rem;
          width: fit-content;

          .statusText {
            display: flex;
            align-items: center;
            gap: 0.5rem;
          }

          .circle {
            width: 1.5rem;
            height: 1.5rem;
            border-radius: 50%;
            display: inline-block;
          }

          .gray {
            background-color: $gray-5;
          }

          .white {
            background-color: white;
            border: 0.0625rem solid $gray-5;
          }

          .red {
            background-color: #be201c;
          }
        }

        .appointmentBlock {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 1.5rem;
          width: 100%;
          margin-bottom: 1.875rem;
        }

        .dateCard {
          display: flex;
          width: 100%;
          flex-direction: column;
          gap: 1rem;

          .dateCardTitle {
            display: flex;
            gap: 0.5rem;
            text-transform: capitalize;
            padding-bottom: 0.5rem;
            border-bottom: 0.0625rem solid $secondary-1;

            span {
              font-weight: 600;
            }
          }

          .slotsContainer {
            display: grid;
            grid-template-columns: repeat(4, minmax(5.625rem, 1fr));
            grid-column-gap: 1rem;
            grid-row-gap: 1rem;
          }
        }

        .slot {
          display: flex;
          justify-content: center;
          padding: 0.75rem 0;
          border: 0.0625rem solid $secondary-1;
          width: 100%;
          border-radius: 0.75rem;
          cursor: pointer;
          font-weight: 600;

          &:hover {
            background-color: $secondary-1;
          }
        }

        .selected {
          background-color: $primary-1_light;
          color: white;

          &:hover {
            background-color: $primary-1_light;
          }
        }

        .disabled {
          background-color: $gray-5;
          border-color: $gray-5;
          color: $gray-4;
          cursor: not-allowed;

          &:hover {
            background-color: $gray-5;
          }
        }

          .booked {
            background-color: #be201c;
            color: white;

            &:hover {
              background-color: #be201c;
            }
          }

          .appointmentButtonWrapper {
            position: sticky;
            bottom: -0.5rem;
            margin-left: -1.25rem;
            margin-bottom: -1.25rem;
            width: calc(100% + 2.375rem);
            background: white;
            box-shadow: 0rem 0.25rem 3.375rem 0rem #0000000f;
            border: unset;
            border: 0.0625rem solid #d1f2de;
            border-radius: 0.9375rem;
            padding: 1.5rem 0;
            z-index: 100;

            .appointmentButton {
              position: relative;
              margin: 0 auto;

            }
          }
        }
      }
    }
    .emptyStateContainer {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .tabsLabel {
    width: 50%;
    white-space: nowrap;
  }
  .nextAppointment {
    margin-bottom: 2.5rem;
  }
  .programmedAppointment {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .pastAppointmentTherapist {
      padding: 1.5rem;
      border: 0.0625rem solid $gray-5;
      border-radius: 1rem;
      .patientName {
        font-size: 1.25rem;
        font-weight: 600;
        line-height: 1.5rem;
        text-align: start;
      }
      .expired,
      .completed,
      .ready,
      .moved,
      .payed_moved,
      .waiting_for_confirm,
      .cancelled {
        display: none;
      }
      .waiting_for_confirm {
        display: none;
      }
      .appointmentDetail {
        display: flex;
        gap: 0.625rem;
        font-size: 1rem;
        font-weight: 600;
        line-height: 1.25rem;
        text-transform: capitalize;
        .expired,
        .completed,
        .ready,
        .moved,
        .payed_moved,
        .waiting_for_confirm,
        .cancelled {
          display: block;
          font-size: 0.8125rem;
          line-height: 1rem;
          padding: 0.1875rem 0.375rem;
          border-radius: 0.25rem;
        }
        .expired,
        .waiting_for_confirm,
        .cancelled {
          background-color: $bg_error_color;
          // background-color: #ffe1e1;
          color: $writing_error_color;
        }
        .completed {
          background-color: $bg_confirm_color;
          color: $writing_confirm_color;
        }
        .ready {
          background-color: $bg_confirm_color;
          color: $writing_confirm_color;
        }
        .moved {
          background-color: $bg_warning_color;
          color: $writing_warning_color;
        }

        .payed_moved {
          background-color: $bg_warning_color;
          color: $writing_warning_color;
        }
      }
    }
    .pastAppointmentHeader {
      display: flex;
      justify-content: space-between;
      .select {
        border: 0.09375rem solid $gray-5;
        border-radius: 0.5rem;
        padding: 0.6875rem 1rem;
        font-size: 1rem;
        line-height: 1.375rem;
        color: $gray-1;
      }
    }
  }

  .calendarWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: auto;

    .customCalendar {
      width: 100%;
      min-height: 40.625rem;
      margin-bottom: 1.25rem;
    }
  }
  .selectedAppointment {
    position: absolute;
    bottom: 0.9375rem;
    right: 0.9375rem;
    z-index: 99;
    .selectedAppointmentCard {
      width: 50vw;
      background-color: $bg_main;
    }
  }
}

.emptyState {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  width: 30%;
  height: 100%;
  .iconWrapper {
    padding: 1.875rem;
    background-color: $secondary-1;
    border-radius: 100%;
  }
  .emptyStateBtn {
    padding: 0 3rem;
  }
}

.modalAppointmentCard {
  width: 50vw;
  padding: 2.25rem 2.5rem;
  border-radius: 1rem;
  background-color: $bg_main;
  display: flex;
  flex-direction: column;
  gap: 1.09375rem;
  .datePicker {
    padding: 0;
    gap: 2rem;
  }
  .formWrapper {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  .btnWrapper {
    display: flex;
    gap: 1rem;
    button:first-child {
      width: 20%;
    }
    button:last-child {
      width: 100%;
    }
  }
}

@media (max-width: $tabletBreakpoint) {
  .patientAppointments {
    padding: 0 1.25rem;
    background-color: $gray-6;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 2rem;
    margin-top: 4.375rem;
    max-width: 100vw;
    box-sizing: border-box;

    .patientAppointmentHeader {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      h4 {
        display: none;
      }
      .therapistTab {
        display: none;
        //   position: fixed;
        //   top: 0.75rem;
        //   right: 0rem;
        //   z-index: 9;
        //   .customLabelWrapper {
        //     border-radius: 0.75rem;
        //     gap: 0.375rem;
        //   }
        //   .customActiveLabel {
        //     background-color: transparent;
        //     border: 0.0625rem solid;
        //     border-radius: 0.75rem;
        //   }
        //   .tabLabel {
        //     display: flex;
        //     align-items: center;
        //     gap: 0.25rem;
        //     .icon {
        //       width: 1.5rem;
        //       height: 1.5rem;
        //     }
        //     span {
        //       display: none;
        //     }
        //   }
      }
    }
    .tabs {
      padding: 0;
      width: 100%;
      height: 100dvh;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      align-items: start;
      .bottomPart {
        .chipsWrapper {
          display: flex;
          gap: 0.625rem;
          margin-bottom: 2.5rem;
          width: 100%;
          overflow-x: scroll;
          padding: 0.1875rem 0;
          div {
            display: flex;
            align-items: center;
          }
        }
      }
      .bottomPart,
      .emptyStateContainer {
        height: 100%;
        width: 100%;
        margin-top: 0.5rem;

        &.appointment {

          .scrollButton {
            bottom: 1.375rem;
          }

        }
        .appointmentsSection {
          text-align: start;
          margin-bottom: 1rem;
        }

        .appointmentWrapper {

          .appointmentContainer {

            .legendWrapper {
              flex-wrap: wrap;
            }

            .dateCard {
              .slotsContainer {
                grid-template-columns: repeat(auto-fill, minmax(5.625rem, 1fr));
              }
            }
          }
        }
      }
      .emptyStateContainer {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .tabsLabel {
      width: 100%;
    }
    .nextAppointment {
      margin-bottom: 2.5rem;
    }
    .programmedAppointment {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      .pastAppointmentTherapist {
        padding: 1.5rem;
        border: 0.0625rem solid $gray-5;
        border-radius: 1rem;
        display: flex;
        flex-direction: column;
        .patientName {
          font-size: 1.25rem;
          font-weight: 600;
          line-height: 1.5rem;
          text-align: start;
        }
        .expired,
        .completed,
        .ready,
        .moved,
        .payed_moved,
        .waiting_for_confirm,
        .cancelled {
          display: block;
          font-size: 0.8125rem;
          line-height: 1rem;
          font-weight: 600;
          padding: 0.1875rem 0.375rem;
          border-radius: 0.25rem;
          width: fit-content;
          margin-bottom: 1rem;
        }
        .expired,
        .waiting_for_confirm,
        .cancelled {
          background-color: $bg_error_color;
          // background-color: #ffe1e1;
          color: $writing_error_color;
        }
        .completed {
          background-color: $bg_confirm_color;
          color: $writing_confirm_color;
        }
        .ready {
          background-color: $bg_confirm_color;
          color: $writing_confirm_color;
        }
        .moved {
          background-color: $bg_warning_color;
          color: $writing_warning_color;
        }

        .payed_moved {
          background-color: $bg_warning_color;
          color: $writing_warning_color;
        }
        .appointmentDetail {
          display: flex;
          gap: 0.625rem;
          font-size: 1rem;
          font-weight: 600;
          line-height: 1.25rem;
          text-transform: capitalize;
          .expired,
          .completed,
          .ready,
          .moved,
          .payed_moved,
          .waiting_for_confirm,
          .cancelled {
            display: none;
            font-size: 0.8125rem;
            line-height: 1rem;
            padding: 0.1875rem 0.375rem;
            border-radius: 0.25rem;
          }
          .expired,
          .waiting_for_confirmm,
          .cancelled {
            background-color: $bg_error_color;
            // background-color: #ffe1e1;
            color: $writing_error_color;
          }
          .completed {
            background-color: $bg_confirm_color;
            color: $writing_confirm_color;
          }
          .ready {
            background-color: $bg_confirm_color;
            color: $writing_confirm_color;
          }
          .moved {
            background-color: $bg_warning_color;
            color: $writing_warning_color;
          }

          .payed_moved {
            background-color: $bg_warning_color;
            color: $writing_warning_color;
          }
        }
      }
      .pastAppointmentHeader {
        display: flex;
        justify-content: space-between;
        .select {
          border: 0.09375rem solid $gray-5;
          border-radius: 0.5rem;
          padding: 0.6875rem 1rem;
          font-size: 1rem;
          line-height: 1.375rem;
          color: $gray-1;
        }
      }
    }
    .customCalendar {
      width: 100%;
    }
    .selectedAppointment {
      position: absolute;
      bottom: 0.9375rem;
      right: 0.9375rem;
      z-index: 99;
      display: flex;
      justify-content: center;
      width: calc(100% - 2.5rem);
      .selectedAppointmentCard {
        width: 100%;
        background-color: $bg_main;
      }
    }
  }

  .emptyState {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
    width: 100%;
    .iconWrapper {
      padding: 1.875rem;
      background-color: $secondary-1;
      border-radius: 100%;
    }
    .emptyStateBtn {
      padding: 0 3rem;
    }
  }

  .modalAppointmentCard {
    width: 50vw;
    padding: 2.25rem 2.5rem;
    border-radius: 1rem;
    background-color: $bg_main;
    display: flex;
    flex-direction: column;
    gap: 1.09375rem;
    .datePicker {
      padding: 0;
      gap: 2rem;
    }
    .formWrapper {
      display: flex;
      flex-direction: column;
      gap: 2rem;
    }
    .btnWrapper {
      display: flex;
      gap: 1rem;
      button:first-child {
        width: 20%;
      }
      button:last-child {
        width: 100%;
      }
    }
  }
}

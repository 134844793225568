@import "src/index.scss";
.textContainer {
  display: flex;
  flex-direction: column;
  label {
    max-width: 13.25rem;
  }
  svg {
    width: 1.5rem;
    height: 1.25rem;
  }
  button {
    max-width: 9.25rem;
    display: flex;
    svg {
      margin: 0.6875rem 0 0.5625rem 0.625rem;
    }
  }

  .surveyFixedBtn {
    display: none;
    @media (max-width: $mobileBreakpoint) {
      display: block;
      position: fixed;
      bottom: 0;
      left: 0;
      z-index: 6;
      transition: transform 0.5s;
      width: 100%;
      height: 4.375rem;
      background-color: transparent;
      background-image: linear-gradient(to bottom,transparent,#fff 40%);
      opacity: 1;
      box-sizing: border-box;
      button {
        max-width: fit-content;
        margin: 0 auto;
      }
    }
  }

  ul {
    list-style: none;
  }

  .linkWrapper {
    display: flex;
    justify-content: space-between;
  }

  .linkIcons {
    display: flex;

    a {
      display: flex;
      align-items: center;
      text-decoration: none;
      color: $gray-1;
      &:active {
        color: $gray-1;
      }
    }
    > div {
      cursor: pointer;
      display: flex;
    }
    svg {
      display: inline-block;
      width: auto;
      margin: auto 0 0.0625rem 1.125rem;
    }
  }
  position: relative;
  .stain {
    position: absolute;
  }
  .linksContainer {
    margin-top: 1.25rem;
    ul {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }
  }
}

@media (max-width: $tabletBreakpoint) {
  .linkWrapper {
    display: flex;
    justify-content: normal;
    flex-direction: column;
    gap: 0.75rem;
  }
}

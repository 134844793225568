@import "src/index.scss";

.sidebar {
  width: 100%;
  display: flex;
  padding: 1rem 0;
  cursor: pointer;
  //height: 3.75rem;
  box-sizing: border-box;

  .leftSide {
    //height: 1.5rem;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 0.625rem;
    border-left: 0.25rem solid transparent;
    flex-grow: 1;
    gap: 0.5rem;
    &.active {
      border-left: 0.25rem solid $primary-1;
      .icon,
      .text {
        color: $gray-1;
      }
    }

    .icon {
      min-width: 1.25rem;
      color: $gray-3;
      object-fit: contain;
    }

    .text {
      display: flex;
      flex-grow: 1;
      line-height: 22px;
      align-self: start;
      text-align: left;
      color: $gray-3;
    }
  }
}

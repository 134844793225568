@import "src/index.scss";
.docInfo {
  padding-top: 1.25rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1.5rem;
  height: calc(100% - 1.25rem);


  .therapistTitle {
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
  }

  .reviewModal {
    display: flex;
    flex-direction: column;
    background-color: $bg_main;
    width: 50%;
    padding: 2.5rem 2rem;
    border-radius: 1rem;
    gap: 2.125rem;
    .description {
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.375rem;
      text-align: start;
    }
    .reviewForm {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      .starContainer {
        display: flex;
        .star {
          width: 1.25rem;
          height: 1.25rem;
          color: $primary-2;
        }
      }
      .btnWrapper {
        display: flex;
        justify-content: end;
        gap: 2.25rem;
      }
    }
  }

  .title {
    font-weight: 600;
    text-align: start;
  }
  .interventionArea {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .areasTitle {
      font-weight: 600;
      text-align: start;
    }

    .areasList {
      display: flex;
      flex-wrap: wrap;
      gap: 0.3125rem;
      button {
        text-transform: capitalize;
      }
    }

    .areasBtn {
      font-size: 0.875rem;
      background-color: $gray_bg;
      color: $gray-2;

      border: 0;
      border-radius: 1.5625rem;
      padding: 0.5rem 0.9375rem;
    }
  }

  .aboutDoc {
    text-align: start;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    .aboutTitle {
      font-weight: 600;
    }

    .aboutText {
      font-size: 0.875rem;
      // height: 13rem;
    }
  }

  .phoneNumber {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-align: start;
    span {
      font-weight: 600;
    }
    div {
      display: flex;
      gap: 0.5rem;
      font-size: 0.825rem;
      font-weight: 500;
      .whatsappIcon {
        width: 1.25rem;
        height: 1.25rem;
        color: $primary-2;
      }
    }
  }

  .insertReview {
    display: flex;
    align-items: center;
    color: $primary-1_dark;
    .icon {
      width: 1.5rem;
      height: 1.5rem;
    }
    button {
      color: inherit;
      text-decoration: underline;
    }
  }

  .buttonCenter {
    display: flex;
    justify-content: center;
    padding-bottom: 1.25rem;
  }
}

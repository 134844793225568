@import "src/index.scss";

.confirmCodeModal {
  max-width: 31.25rem !important;

  .confirmCodeForm {
    text-align: center;

    .alertError {
      text-align: left;
    }

    .underlinedLink {
      text-decoration: underline;
      cursor: pointer;
    }

    .formInputs {
      display: flex;
      flex-direction: column;
      gap: 0.75rem;
      text-align: left;
    }


    .confirmCodeBtn {
      width: 100%;
      text-align: center;
      margin-top: 2rem;

      .spanBtn {
        color: $primary-1_dark;
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
}
.App {
  text-align: center;
}

html, body, #root, .App, #publicRoot, .authRoot, #firstAppointmentRoot {
  height: 100%;
  overflow: hidden;
}

#publicRoot, #firstAppointmentRoot {
  display: flex;
  flex-direction: column;
}

#pubtech-cmp > div[title="Manage consent"] {
  display: none;
}

#pubtech-cmp > div[title="Gestisci consenso"] {
  display: none;
}

#pubtech-cmp > .pt-EnC .pt-58U .pt-6Eo  {
  display: none;
}
/* .info {
  position: fixed;
  top: 10px;
  right: 20%;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  gap: 1rem;
} */

#multiSelect {
  .optionListContainer {
    position: static !important;
  }
}

.pageWithHeader{
  box-sizing: border-box;
  /* height: 90vh; */
  flex-grow: 1;
  padding-top: 10vh;
  overflow-y: auto;
  scrollbar-gutter: stable;
  /* z-index: 2; */
}

@media (max-width: 1024px) {
  .pageWithHeader {
    padding-top: 5rem;
    /* height: calc(100vh - 5rem); */
  }
  .circleShape {
    display: none;
  }
  .purpleTriangle {
    display: none;
  }
  .greenTriangle {
    display: none;
  }
}

.purpleTriangle,
.greenTriangle,
.circleShape {
  position: absolute;
  z-index: 99;
}
.circleShape {
  z-index: 1;
  left: 40%;
  top: 15%;
  width: 1.55rem;
  height: 1.55rem;
}
.purpleTriangle {
  top: 83%;
  left: 15%;
  width: 2.125rem;
  height: 2.125rem;
}
.greenTriangle {
  top: 42%;
  left: 85%;
  width: 2.125rem;
  height: 2.125rem;
  transform: rotate(36deg);
}
@import "src/index.scss";

.introSurvey {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.5rem 0;
  position: relative;
  z-index: 999;
  background-color: $gray-6;
  gap: 1.625rem;

  .top {
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: $blue_bg;
    padding: 2.5rem;
    border-radius: 2rem;
    gap: 12.5rem;
    position: relative;

    .greenShape {
      position: absolute;
      top: 0;
      transform: translateY(-25%);
      img {
        width: 6.25rem;
      }
    }

    .card {
      display: flex;
      flex-direction: column;
      gap: 2.25rem;
      border-radius: 1rem;
      max-width: 26.875rem;
      text-align: left;

      .title {
        font-weight: 600;
        font-size: 2rem;
      }

      .contentWrapper {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        text-align: left;
      }

      .cardElement {
        display: flex;
        align-items: center;
        gap: 0.75rem;

        .icon {
          background-color: $secondary-1;
          color: $primary-1;
          min-height: 2.75rem;
          min-width: 2.75rem;
          max-height: 2.75rem;
          max-width: 2.75rem;
          padding: 0.625rem;
          box-sizing: border-box;
          border-radius: 100%;
        }
      }
      .startBtn {
        justify-content: center;
      }
    }
    .people {
      width: 36vw;
    }
  }
  .mobileReview {
    display: none;
  }
  .review {
    display: flex;
    justify-content: center;
    position: relative;

    .purpleShape {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(-40%, 40%);
      img {
        width: 6.25rem;
      }
    }

    .reviewCarouselWrapper {
      display: flex !important;
      justify-content: space-evenly;
      align-items: center;
      gap: 1.25rem;
      padding: 3.125rem 0rem;

      .cardReview {
        width: 35%;
        background-color: $bg_main;
        box-shadow: 0rem 0.25rem 3.375rem 0rem rgba(0, 0, 0, 0.0588235294)
      }
      .cardReviewInvisible {
        display: none;
        opacity: 0;
      }
    }
  }
  .bottom {
   display: none;
  }
}

@media (max-width: $tabletBreakpoint) {
  .introSurvey {
    display: flex;
    flex-direction: column;
    justify-content: start;
    position: relative;
    z-index: 999;
    background-color: $blue_bg;
    padding: 0 1rem 1rem 1rem;
    min-height: 100%;
    gap: 2.5rem;
    .top {
      display: flex;
      align-items: center;
      justify-content: space-around;
      padding: 0rem;
      margin-top: 0;
      max-width: 26.875rem;
      width: 100%;

      .greenShape {
        display: none;
      }
      .card {
        margin-top: 1.25rem;
        padding: 2rem 1rem;
        border: 0.125rem solid #9ebbff;
        border-radius: 1rem;
        background-color: transparent;
        display: flex;
        flex-direction: column;
        gap: 2.25rem;
        width: 100%;

        .title {
          font-weight: 600;
          font-size: 1.5rem;
          line-height: 1.75rem;
        }

        .contentWrapper {
          display: flex;
          flex-direction: column;
          gap: 1.5rem;
          text-align: left;
        }

        .cardElement {
          display: flex;
          align-items: center;
          gap: 0.75rem;

          .icon {
            background-color: $secondary-1;
            color: $primary-1;
            height: 1.5rem;
            width: 1.5rem;
            padding: 0.625rem;
            border-radius: 100%;
          }
        }
        .startBtn {
          justify-content: center;
        }
      }
      .people {
        display: none;
      }
    }

    .review {
      display: none;
    }
    .mobileReview {
      display: flex;
      justify-content: center;
      .reviewCarouselWrapper {
        display: flex !important;
        justify-content: space-evenly;
        align-items: center;
        gap: 1.25rem;
        .cardReview {
          background-color: $bg_main;
        }
        .cardReviewInvisible {
          opacity: 0;
        }
        ul {
          margin-top: 3.125rem;
        }
      }
    }
    .bottom {
      display: flex;
      max-width: 26.875rem;
      justify-content: center;
      width: 100%;
      padding-bottom: 1.25rem;
      .icon {
        background-color: $secondary-1;
        color: $primary-1;
        min-height: 2.75rem;
        min-width: 2.75rem;
        max-height: 2.75rem;
        max-width: 2.75rem;
        padding: 0.625rem;
        box-sizing: border-box;
        border-radius: 100%;
      }
      .bottomContent {
        padding: 2rem 1rem;
        border: 0.125rem solid #9ebbff;
        border-radius: 1rem;
        display: flex;
        width: 100%;
        align-items: center;
        gap: 0.75rem;
      }
    }
  }
}

@import "src/index.scss";


.btnWrapper {
    display: flex;
  gap: 0.75rem;
    button {
      width: 100%;
    }
    .moveButton {
      background-color: #9ebbff;
    }
    .cancelButton {
      background-color: #BE201C;
      color: #ffffff
    }
  }

@import "src/index.scss";


.therapistSubtitle {
  text-align: start;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;

  span {
    font-weight: 600;
    line-height: 1.0625rem;
  }
}

.appointmentCalendar {
  padding: 0;
  gap: 2rem;
}

.tabs {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 0;

  div:last-child {
    overflow-y: unset;
  }

  .move {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    p {
      text-align: start;
      font-size: 0.875rem;
    }

    .btnWrapper {
      display: flex;
      gap: 1rem;

      .cancelBtn,
      .confirmBtn {
        width: 100%;
        justify-content: center;
      }
    }
  }
}

.activeTab {
  background-color: #4BCAB1 !important;
}

@media (max-width: $tabletBreakpoint) {

  .therapistSubtitle {
    text-align: start;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;

    span {
      font-weight: 600;
      line-height: 1.0625rem;
    }
  }

  .appointmentCalendar {
    padding: 0;
    gap: 2rem;
  }

  .tabs {
    gap: 1rem;

    .activeTab {
      padding: 0 1rem;
    }

    div:last-child {
      overflow-y: unset;
    }

    .move {
      margin-top: 1.5rem;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;

      p {
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.25rem;
        text-align: left;

        span {
          font-weight: 600;
        }
      }

      .btnWrapper {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        gap: 1rem;

        .cancelBtn,
        .confirmBtn {
          width: 100%;
          justify-content: center;
        }
      }
    }
  }
}

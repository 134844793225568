@import "src/index.scss";

.userPersonalInfo {
  flex-grow: 1;
  overflow-y: auto;
  padding: 2rem 1.5rem;
  .hiddenButton {
    display: none;
  }

  padding-right: 1rem;
  // -ms-overflow-style: none;
  // scrollbar-width: none;
  // &::-webkit-scrollbar {
  //   display: none;
  // }
  .title {
    font-size: 2rem;
    font-weight: 600;
    line-height: 2.375rem;
    text-align: start;
    margin-bottom: 1.5rem;
  }
  .infoWrapper {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    .topPart {
      display: flex;
      flex-direction: column;
      gap: 1.25rem;
      padding: 0 0.125rem;
      .userName {
        display: flex;
        gap: 0.5rem;
        div {
          text-align: start;
          width: 100%;
          box-sizing: border-box;
        }
      }
      .splitInput {
        display: flex;
        gap: 0.5rem;
        text-align: left;
        div:nth-child(2) {
          flex-grow: 1;
        }
      }
    }
    .bottomPart {
      display: flex;
      flex-direction: column;
      gap: 2.5rem;
      .inputContainer {
        padding: 1.25rem;
        border-radius: 1.25rem;
        background-color: $gray_bg;
        .inputName {
          text-align: start;
          font-size: 1.25rem;
          font-weight: 600;
          line-height: 1.5rem;
          margin-bottom: 1.5rem;
        }
        .inputToModify {
          display: flex;
          align-items: flex-end;
          gap: 0.5rem;
          text-align: start;
          div {
            width: 100%;
            box-sizing: border-box;
          }
          button {
            text-decoration: underline;
          }
        }
      }
      .btnWrapper {
        display: flex;
        justify-content: end;
        gap: 0.75rem;
      }

      .checkboxWrapper {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        .checkbox {
          div {
            color: $gray-2;
            border: 0;
            padding: 0;
            text-align: start;
            height: fit-content;

            &:focus-within {
              border: 0;
              padding: 0;
              outline: 0;
            }
          }
        }
      }
    }
    .deleteProfile {
      color: $writing_error_color;
      text-decoration: underline;
    }
  }
  .modifyModalContent {
    background-color: $bg_main;
    padding: 2.5rem 2rem;
    border-radius: 1rem;
    width: 40%;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    .modalDescription {
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.25rem;
      text-align: start;
      span {
        font-size: 0.875rem;
        font-weight: 600;
        line-height: 1.0625rem;
      }
    }
    .motiveWrapper {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      .delMotive {
        padding: 0.6875rem 1rem;
        border-radius: 0.5rem;
        border: 0.09375rem solid $gray-5;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.375rem;
        text-align: start;
        &.active {
          background-color: #f9f8ff;
          border: 0.09375rem solid $primary-1;
        }
      }
    }
    .inputWrapper {
      border-radius: 1rem;
      background-color: $gray_bg;
      padding: 1rem;
      text-align: start;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }
    .infoBoxModal {
      border-radius: 0.5rem;
    }
    .btnWrapper {
      display: flex;
      gap: 0.75rem;
      button {
        width: 100%;
      }
    }
  }
}

@media (max-width: $tabletBreakpoint) {
  .userPersonalInfo {
    flex-grow: 1;
    padding: 1.25rem;
    background-color: white;
    // width: calc(100% - 2.5rem);
    overflow-y: auto;
    .title {
      display: none;
      font-size: 2rem;
      font-weight: 600;
      line-height: 2.375rem;
      text-align: start;
      margin-bottom: 1.5rem;
    }
    .infoWrapper {
      display: flex;
      flex-direction: column;
      gap: 2.5rem;
      .topPart {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding: 0 0.125rem;
        .userName {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          div {
            text-align: start;
            width: 100%;
            box-sizing: border-box;
          }
        }
        .splitInput {
          flex-direction: column;
        }
      }
      .bottomPart {
        display: flex;
        flex-direction: column;
        gap: 2.5rem;
        .inputContainer {
          padding: 1.25rem;
          border-radius: 1.25rem;
          background-color: $gray_bg;
          .inputName {
            text-align: start;
            font-size: 1.25rem;
            font-weight: 600;
            line-height: 1.5rem;
            margin-bottom: 1.5rem;
          }
          .inputToModify {
            display: flex;
            flex-direction: column;
            align-items: start;
            gap: 0.5rem;
            div {
              width: 100%;
              box-sizing: border-box;
            }
            button {
              text-decoration: underline;
            }
          }
        }
        .btnWrapper {
          display: flex;
          flex-direction: column-reverse;
          justify-content: end;
          gap: 0.75rem;
        }
      }
      .deleteProfile {
        color: $writing_error_color;
        text-decoration: underline;
      }
    }
    .modifyModalContent {
      background-color: $bg_main;
      padding: 2.5rem 2rem;
      border-radius: 1rem;
      width: 100%;
      margin: 0 0.5rem;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      .modalDescription {
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.25rem;
        text-align: start;
        span {
          font-size: 0.875rem;
          font-weight: 600;
          line-height: 1.0625rem;
        }
      }
      .motiveWrapper {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        .delMotive {
          padding: 0.6875rem 1rem;
          border-radius: 0.5rem;
          border: 0.09375rem solid $gray-5;
          font-size: 1rem;
          font-weight: 400;
          line-height: 1.375rem;
          text-align: start;
          &.active {
            background-color: #f9f8ff;
            border: 0.09375rem solid $primary-1;
          }
        }
      }
      .inputWrapper {
        border-radius: 1rem;
        background-color: $gray_bg;
        padding: 1rem;
        text-align: start;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
      }
      .infoBoxModal {
        border-radius: 0.5rem;
      }
      .btnWrapper {
        display: flex;
        gap: 0.75rem;
        button {
          width: 100%;
        }
      }
    }
  }
}

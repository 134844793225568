@import "src/index.scss";

.tabs {
  padding: 0 1.25rem;
  border-radius: 1rem;
  .label {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 3rem;
    background: $gray_bg;
    border-radius: 2.25rem;
    padding: 0 0.25rem;

    .tabHeader,
    .active {
      cursor: pointer;
      font-weight: 600;
      font-size: 0.875rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 0.5625rem;
      border-radius: 1.25rem;
      width: 100%;
      &.disabled{
        display: none;
      }
    }

    .active {
      background: $primary-1_light;
      border-radius: 1.25rem;
      .tabLabel {
        display: flex;
        align-items: center;
        gap: 0.25rem;

        .icon {
          display: flex;
          width: 1.5rem;
          height: 1.5rem;
        }
      }
    }
  }
  .activeTab {
    box-sizing: border-box;
    height: fit-content;
    width: 100%;
    -ms-overflow-style: none;
    overflow-y: auto;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    &.booking {
      overflow-y: hidden;
    }

    &[class*= "docSheet" ] {
      height: calc(100% - 3.75rem);
    }

    &[class*= "patientAppointments" ] {
      height: calc(100% - 9rem);
    }
  }
}

@media (max-width: $tabletBreakpoint) {
  .tabs {
    .activeTab {

      &[class*= "docSheet" ] {
        height: calc(100% - 3rem);
      }

      &[class*= "patientAppointments" ] {
        height: calc(100dvh - 11rem);
      }
    }
  }
}

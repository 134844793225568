@import "src/index.scss";

.chatWrapper {
  position: relative;
  background-color: $gray-6;
  display: flex;
  flex: 50%;
  border-radius: 1rem;

  .patientCardsWrapper {
    display: flex;
    flex-direction: column;
  }

  .firstAppointmentButtons {
    display: flex;
    justify-content: center;
    gap: 1.5rem;
  }

  .headerTherapistChat {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
    flex-wrap: wrap;
    margin-bottom: 0.5rem;

    .headerTherapistChatInfo {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;

      .headerTherapistChatName {
        display: flex;
        align-items: center;
        gap: 0.375rem;
        font-size: 2rem;
        font-weight: 600;
        text-align: left;

        .arrowIcon {
          width: 2.5rem;
          height: 2.5rem;
          padding: 0.625rem;
          box-sizing: border-box;
          cursor: pointer;
        }
      }

      .headerTherapistChatLastAppointment {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        font-size: 0.75rem;
        font-weight: 500;
        color: $gray-3;

        .icon {
          width: 1rem;
          height: 1rem;
        }
      }
    }

    .headerTherapistChatAction {
      display: flex;

      .headerTherapistChatButtonWrapper {
        display: flex;
        align-items: center;
        gap: 0.5rem;
      }

      .headerTherapistChatButtonWrapper :nth-child(2) {
        order: 1;
      }

      .headerTherapistChatNote {
        display: flex;
        align-items: center;
        font-weight: 600;

        .icon {
          width: 1.5rem;
          height: 1.5rem;
        }
      }

      .headerTherapistChatDetails {
        align-items: center;
      }
    }
  }

  .chatInfo {
    margin: 0 0 0.5rem 0;

    //&:first-child {
    //  margin-top: 0.5rem;
    //}

    &:last-child {
      margin-bottom: 0.5rem;
    }

    .chatInfoConsent {
      text-decoration: underline;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .chat {
    display: flex;
    flex-direction: column;
    flex: 75%;
    padding: 1rem 1.5rem;
    position: relative;

    .loadingChat {
      position: absolute;
      top: 50%;
      left: 50%;

      &.loadingChatPatient {
        top: 50%;
        left: 50%;
      }
    }
  }

  .patientDataWrapper {
    display: flex;
    justify-content: space-between;
    background-color: #f9f9f9;
    padding: 0.5625rem 1.0625rem;
    border-radius: 1rem;
    margin-bottom: 1rem;

    .patientDataContainer {
      display: flex;
      flex-direction: column;
      align-items: start;
      gap: 0.5rem;
      line-height: 1.25rem;
      span {
        font-weight: 500;
      }
    }

  }

  .buttonsWrapper {
    display: flex;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
    padding: 1rem;
    background-color: $gray_bg;
    border-radius: 0.75rem;
  }

  .surveyAnswers {
    overflow-y: auto;
    overflow-x: hidden;

    .title {
      font-size: 1.5rem;
      font-weight: 600;
      line-height: 1.8125rem;
      text-align: start;
      margin-bottom: 1.5rem;
    }

    .answersList {
      display: flex;
      flex-direction: column;
      gap: 0.75rem;

      .answerCard {
        width: 100%;
        text-align: start;
        border-bottom: 0.0625rem solid $gray-5;
        padding: 1rem 0;

        .question {
          font-size: 0.875rem;
          font-weight: 600;
          line-height: 1.0625rem;
        }

        .answer {
          font-size: 0.875rem;
          font-weight: 400;
          line-height: 1.25rem;
        }
      }
    }
  }

  .patientList {
    width: 17.5rem;
    border-right: 0.0625rem solid $gray-5;
    overflow-y: auto;

  }

  .conversation {
    height: 95%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding: 0.3125rem;

    &::-webkit-scrollbar {
      display: none;
    }

    .emptyChat {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 2rem;
      font-weight: 700;
    }
  }

  .emptyChat {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    font-weight: 700;
  }

  .infoNewAppointment {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: $bg_confirm_color;
    color: $writing_confirm_color;
    border-radius: 1.625rem;
    font-size: 0.75rem;
    padding: 0.25rem 0.75rem;
    margin-bottom: 0.625rem;

    .icon {
      color: $writing_confirm_color;
      height: 1.5rem;
      width: 1.5rem;
      margin-right: 0.5rem;
    }
  }
}

@media (max-width: $tabletBreakpoint) {
  .chatWrapper {
    position: relative;
    z-index: 1;
    background-color: $gray-6;
    display: flex;
    flex: 50%;
    border-radius: 1rem;

    .patientDataWrapper {
      flex-direction: column;
      gap: 0.5rem;
    }

    .patientData {
      flex-direction: column;
      gap: 0;
      padding-top: 1rem;
      padding-right: 1rem;
      padding-left: 1rem;
    }

    .surveyAnswers {
      padding: 1rem;
    }

    .confirmAcceptMessage {
      width: 100%;
      margin: 0 0.03125rem;
    }

    .patientCardsWrapper {
      display: flex;
      flex-direction: column;
    }

    .headerTherapistChat {
      padding: 0.5rem 1rem 1rem 1rem;
      flex-wrap: nowrap;
      justify-content: space-between;
      margin-bottom: 0;

      .headerTherapistChatInfo {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;

        .headerTherapistChatName {
          font-size: 1.25rem;
          font-weight: 600;
          text-align: left;
          flex-wrap: wrap;

          span {
            white-space: normal;
          }
        }

        .headerTherapistChatLastAppointment {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          font-size: 0.75rem;
          font-weight: 500;
          color: $gray-3;

          .icon {
            width: 1rem;
            height: 1rem;
          }
        }
      }

      .headerTherapistChatAction {
        justify-content: end;

        .headerTherapistChatNote {
          display: flex;
          align-items: center;
          font-weight: 600;

          .icon {
            width: 1.5rem;
            height: 1.5rem;
          }
        }

        .headerTherapistChatDetails {
          height: 50%;
          align-items: center;
        }

        .headerTherapistChatButtonWrapper {
          flex-wrap: wrap;
          justify-content: center;
          gap: 0.25rem;
          flex-shrink: 0;

          @media (max-width: 31.625rem) {
            width: 10.9375rem;
          }

          > button {
            font-size: 0.875rem;
          }
        }

        .headerTherapistChatButtonWrapper :nth-child(2) {
          order: unset;
        }
      }
    }

    .chatInfo {
      margin: 0 0.5rem 0.5rem 0.5rem;
    }

    .chat {
      padding: 0.5rem 0rem 1rem;
      width: 100%;

      &.hideChat {
        display: none;
      }
    }

    .patientList {
      min-width: 0rem;
      width: auto;
      flex: 1;
      border-right: 0.0625rem solid $gray-5;

      &.hideList {
        display: none;
      }
    }

    .conversation {
      height: 95%;
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;
      padding: 1.25rem 1rem;

      &::-webkit-scrollbar {
        display: none;
      }

      .emptyChat {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 2rem;
        font-weight: 700;
      }
    }
  }
}

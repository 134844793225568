@import "src/index.scss";

.questionSurvey {
  box-sizing: border-box;
  background-color: $blue_bg;
  position: relative;
  height: 100%;
  margin-top: 0;

  .loading {
    align-items: center;
  }

  .topAnchor {
    position: absolute;
    top: calc(-5vh - 3.125rem);
    visibility: hidden;
  }

  .checkboxAnswer {
    display: flex;
    width: 100%;
  }

  .QuestionIcon {
    box-sizing: border-box;
    width: 2.75rem;
    height: 2.75rem;
    padding: 0.625rem;
    margin-right: 0.5rem;
    border-radius: 6.25rem;
    background-color: $secondary-1;

    .icon {
      color: $primary-1;
    }
  }

  .checkboxInput {

    [class*="Input"] {
      padding-top: 0;
      height: auto !important;
      padding-bottom: 0;
    }

    [class*="checkboxLabel"] {
      padding: 0.5rem 0;
      cursor: pointer;
    }

    &.checkboxInputAnswer {
      [class*="Input"] {
        padding: 0 .5rem;
      }

      [class*="checkboxLabel"] {
        padding: 1.125rem 0;
      }

      [class*="label"] {
        padding: 0;
      }

      [class*="iconQuestion"] {
        padding: 0;
      }
    }
  }

  .privacyMobile {
    display: none;
  }

  .privacyDisclaimer {
    width: 100%;
    text-align: center;
    margin: 1rem 0;
    font-size: 0.75rem;

    span {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .progressBar {
    position: fixed;
    z-index: 3;
    top: 5rem;
    left: 0;
  }

  .questionWrapper {
    background-color: $blue_bg;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;

    .questionCard {
      position: relative;
      z-index: 2;
      margin: 0 auto;
      margin-top: 5vh;
    }

    .firstQuestionCard {
      margin: 0 auto;
      margin-top: 5vh;
    }

    .questionCard,
    .firstQuestionCard {
      box-shadow: 0rem 0.25rem 3.375rem 0rem #0000000f;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      padding: 2rem;
      background-color: $gray-6;
      border-radius: 1rem;
      width: 31.25rem;
      text-align: left;

      .titleWrapper {
        display: flex;
        flex-direction: column;

        &.confusione {
          gap: 1.25rem;
        }

        &.psychiatry {
          li {
            span {
              font-weight: bold;
            }
          }

          p {
            font-size: inherit;
          }
        }

        .questionCardTitle {
          font-size: 1.5rem;
          font-weight: 600;
        }

        .questionCardImage {
          margin: 0 auto;
          max-width: 75%;
          object-fit: cover;

          &.confusione {
            max-width: 50%;
          }
        }

        .note {
          color: $gray-2;
          font-weight: 400;
          margin-top: 0.5rem;

          p {
            font-size: 1rem;
          }

          p:not(:last-child) {
            padding-bottom: 1rem;
          }

          &.survey {
            margin-top: 0;
            text-align: center;
          }
        }
      }

      .stepWrapper {
        height: 0.25rem;
        display: flex;
        justify-content: center;
        gap: 0.5rem;

        .darkBlue,
        .lightBlue {
          height: 0.25rem;
          width: 20%;
          border-radius: 0.25rem;
        }

        .darkBlue {
          background-color: $primary-1;
        }

        .lightBlue {
          background-color: #ebf1f8;
        }
      }

      .questionWrapper {
        background-color: white;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        gap: 0.75rem;
      }

      .answersWrapper {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        .firstQuestion {
          cursor: pointer;
          box-sizing: border-box;
          padding: 1rem;
          display: flex;
          gap: 0.625rem;
          align-items: center;
          text-align: left;
          border: 0.0625rem solid $gray-5;
          border-radius: 0.5rem;

          &.activeAnswer {
            outline: 0.125rem solid $primary-1;
          }

          &:hover {
            outline: 0.0625rem solid $primary-1;
          }

          &:focus {
            outline: 0.125rem solid $primary-1;
          }

          .firstQuestionIcon {
            position: relative;
            width: 2.75rem;
            height: 2.75rem;
            border-radius: 6.25rem;
            background-color: $secondary-1;

            &.couple {
              background-color: #E0D9FD;
            }

            &.underage {
              background-color: #E7F5FF;
            }

            &.psychiatry {
              background-color: #CDF5EF;
            }

            .icon {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }

          .firstQuestionDescription {
            .firstQuestionTitle {
              font-weight: 600;
            }

            .firstQuestionSubtitle {
              font-size: 0.875rem;
              color: $gray-3;
            }
          }

          &[class*="disabled"] {
            opacity: 0.4;
            pointer-events: none;
            cursor: not-allowed;
          }
        }
      }
    }
  }
}

.icon {
  width: 1.5rem;
  height: 1.5rem;
}

.btnWrapper {

  .btnSurveyContainer {
    display: flex;
    gap: 1.5rem;
  }

  .btnContainer {
    display: flex;
    justify-content: space-between;
    gap: 0.75rem;
  }

  .backBtn {
    width: 30%;
  }

  .soloBtn {
    width: 100%;
  }

  button {
    width: 40%;
  }
}

.underageInfo {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  color: $gray-2;
  font-size: 1rem;
  margin-top: 1.5rem;
}

@media (max-width: $tabletBreakpoint) {
  .questionSurvey {
    z-index: 9999;
    box-sizing: border-box;
    position: relative;
    background-color: $gray-6;
    //height: calc(90vh - 9.0625rem);
    .privacyDisclaimer {
      display: none;
    }

    .privacyMobile {
      display: block;
      font-size: 0.75rem;

      span {
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .questionWrapper {
      z-index: 99;
      display: flex;
      align-items: center;
      justify-content: start;
      height: 100%;
      padding-top: 0rem;

      .cardWrapper {
        height: 100%;
      }

      .questionCard {
        margin-top: 0;
        border-radius: 0 !important;
        margin-bottom: 8.125rem;
      }

      .firstQuestionCard {
        margin-top: 0;
      }

      .questionCard,
      .firstQuestionCard {
        height: 100%;
        width: 100vw;
        box-sizing: border-box;
        box-shadow: 0rem 0.25rem 3.375rem 0rem #0000000f;
        display: flex;
        flex-direction: column;
        gap: 0.75rem;
        padding: 1.25rem 1rem 0;
        background-color: $gray-6;
        border-radius: 1rem;
        text-align: left;

        .titleWrapper {
          display: flex;
          flex-direction: column;

          .questionCardTitle {
            font-size: 1.5rem;
            font-weight: 600;
            text-align: start;
          }

          .questionCardImage {
            //margin: 0rem;
            max-width: 25rem;
            width: 100%;
            object-fit: cover;
          }

          .note {
            color: $gray-2;
            font-weight: 400;
          }
        }

        .questionWrapper {
          display: flex;
          flex-direction: column;
          align-items: stretch;
          gap: 0.75rem;
          padding: 0;
          padding-top: 0rem;
          padding-bottom: 0rem;
        }

        .answersWrapper {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
          padding-bottom: 9.0625rem;
          .firstQuestion {
            cursor: pointer;
            box-sizing: border-box;
            padding: 1rem;
            display: flex;
            gap: 0.625rem;
            align-items: center;
            text-align: left;
            border: 0.0625rem solid $gray-5;
            border-radius: 0.5rem;

            &:hover {
              border: 0.0625rem solid $primary-1;
            }

            &:focus {
              border: 0.0625rem solid $primary-1;
            }

            .firstQuestionIcon {
              box-sizing: border-box;
              width: 2.75rem;
              height: 2.75rem;
              padding: 0.625rem;
              border-radius: 6.25rem;
              background-color: $secondary-1;

              .icon {
                color: $primary-1;
              }
            }

            .firstQuestionDescription {
              .firstQuestionTitle {
                font-weight: 600;
              }

              .firstQuestionSubtitle {
                font-size: 0.875rem;
                color: $gray-3;
              }
            }
          }
        }
      }
    }
  }
  .icon {
    width: 1.5rem;
    height: 1.5rem;
  }

  .btnWrapper {
    z-index: 100;
    position: fixed;
    bottom: 0rem;
    left: 0rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.75rem;
    width: calc(100vw - 1.25rem) !important;
    border-top: 0.0625rem solid $gray-5;
    padding: 1rem 0.625rem;
    background-color: $gray-6;

    .btnContainer {
      display: flex;
      justify-content: space-between;
      gap: 0.75rem;
    }

    .backBtn {
      width: 30%;
    }

    .soloBtn {
      width: 100%;
    }

    button {
      width: 40%;
    }
  }

  .underageInfo {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    color: $gray-2;
    font-size: 1rem;
    margin-top: 1.5rem;
  }

  .underageAlert {
    padding-top: 5rem !important;
  }
}

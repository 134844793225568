@import "src/index.scss";

.videoCallButton {
  height: 4.375rem;
  box-sizing: border-box;
  padding: 0.8125rem 1rem;
  border-radius: 0.5rem;
  border: 0.125rem solid $primary-1_light;
  display: flex;
  gap: 0.625rem;
  .iconWrapper {
    width: 2.75rem;
    height: 2.75rem;
    padding: 0.625rem;
    border-radius: 100%;
    background-color: $secondary-1;
    color: $primary-1_dark;
    box-sizing: border-box;
  }
  .textWrapper {
    text-align: start;
    .text {
      font-size: 1rem;
      font-weight: 600;
      line-height: 1.25rem;
    }
    .subtext {
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.25rem;
      color: $gray-3;
    }
  }
}

@import "src/index.scss";

.inputWrapper {
  width: 100%;

  &[class*="searchInput"] {
    padding: 1rem;
    width: auto;

    .rightIcon {
      padding: 0 !important;
    }
  }

  &.coupon {
    width: auto;
  }

  &.medical {
    min-width: 8.125rem;
  }

  .label {
    font-size: 0.875rem;
    text-align: start;
    color: $gray-3;

    &.error {
      color: $error-text;
    }
  }

  .errorLabel {
    font-size: 0.875rem;
    text-align: start;
    color: $error-text;
  }

  .sublabel {
    display: flex;
    margin-bottom: 0.5rem;

    span {
      font-size: 0.7rem;
      color: $gray-3;
    }
  }

  .Input {
    padding: 0.5rem 0.5rem;
    display: flex;
    height: 2.75rem;
    box-sizing: border-box !important;
    flex-direction: row;
    align-items: center;
    border: 0.09375rem solid $secondary-1;
    border-radius: 0.5rem;
    background-color: $bg_main;

    &[class*="searchInput"] {
      border-radius: 1.5rem;
      border: 0.0625rem solid #808093;
      padding: 0.25rem 1rem;
      height: auto;

      ::placeholder {
        font-size: 0.875rem;
      }

      &:focus-within {
        outline: unset;
      }
    }

    &.textarea {
      height: auto;
    }

    &.disabled {
      background-color: $gray-5;
    }

    &.medical {
      padding: 0 0.5rem;
      height: auto;
      border-radius: 0.75rem;
      border: 0.125rem solid #9EBBFF;
      cursor: pointer;
      position: relative;

      select {
        color: #9EBBFF;
        cursor: pointer;
        background-color: transparent;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        width: fit-content;
        margin-right: 0.9375rem;
      }

      &:focus-within {
        outline: transparent none;
      }

      &:after {
        content: " ";
        position: absolute;
        top: 50%;
        margin-top: -0.125rem;
        right: 0.5rem;
        width: 0;
        height: 0;
        border-left: 0.3125rem solid transparent;
        border-right: 0.3125rem solid transparent;
        border-top: 0.3125rem solid #9EBBFF;
      }
    }

    &.coupon {
      border: 0.09375rem solid $secondary-2;

      ::placeholder {
        text-align: center;
        color: #4F9C8D;
      }

      input {
        text-align: center;
        color: #4F9C8D;

        &:focus::placeholder {
          color: transparent;
        }
      }

      &:focus-within {
        // border: 0.125rem solid $primary-1;
        outline: 0.125rem solid;
        outline-color: #4F9C8D;
      }

      &.disabled {
        background-color: $secondary-2;
      }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    textarea {
      font-family: "Poppins", sans-serif;
      font-size: 1rem;

      &:disabled {
        background-color: $gray-5;
        color: $gray-1;
        opacity: 1;
      }
    }

    select {
      font-family: "Poppins", sans-serif;
      font-size: 1rem;
      width: 100%;
      border: 0;

      &:focus-visible {
        outline: 0;
      }

      &:disabled {
        background-color: $gray-5;
        color: $gray-1;
        opacity: 1;
      }
    }

    .inputLabel {
      display: flex;
      gap: 0.5rem;
      align-items: baseline;
      width: 100%;

      textarea {
        outline: $bg_main;
        resize: none;
        width: 100%;
        border: none;
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
          display: none;
        }
      }

      .checkboxLabel {
        width: 100%;
      }

      &.coupon {
        justify-content: center;
      }
    }

    &:focus-within {
      outline: $primary-1 solid 0.125rem;
    }

    &.error {
      outline: $error-outline solid 0.0625rem;
      border-color: $error-border;
      color: $error-text;
      background-color: $error-background;

      input {
        color: $error-text;
      }

      select {
        background-color: $error-background;
      }

      textarea {
        background-color: $error-background;
      }

      label {
        color: $error-text;
      }
    }

    ::placeholder {
      color: $gray-3;
    }

    .rightIcon,
    .leftIcon {
      color: $gray-3;
      display: flex;
      align-items: center;
      width: 1.5rem;
      height: 100%;
    }

    .iconQuestion {
      width: fit-content !important;
    }

    .leftIcon {
      padding-left: 0.5rem;
    }

    .rightIcon {
      padding-right: 0.5rem;
    }

    input {
      font-family: "Poppins", sans-serif;
      width: 100%;
      background-color: transparent;
      color: $gray-1;
      border: 0;
      font-size: 1rem;

      &:focus {
        outline: none;
      }

      &[type="checkbox"] {
        width: fit-content;
        cursor: pointer;
      }

      &[type="file"] {
        cursor: pointer;
      }
    }
  }
}

@media only screen and (max-width: $tabletBreakpoint) {
  .inputWrapper {
    line-height: 140%;
    width: 100%;

    .Input {
      &.medical {
        select {
          font-size: 0.875rem;
        }
      }
    }
  }
}

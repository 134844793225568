@import "src/index.scss";

.userPreferences {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 1.5rem;
  padding: 2rem 1.5rem;

  .hiddenButton {
    display: none;
  }

  overflow-y: auto;

  .datePicker {
    display: block;
    width: auto !important;

    .label {
      font-size: 0.875rem;
      text-align: start;
      color: $gray-3;
    }

    &.error {
      .ant-picker {
        border-color: $error-border !important;
        border-width: 0.125rem !important;
        background-color: $error-background;
        color: $error-text;
      }

      .label {
        color: $error-text;
      }
    }
  }

  .underlinedText {
    text-decoration: underline;
    cursor: pointer;
  }

  .informedConsent {
    width: 40%;
    padding: 2.5rem 2rem;
    border-radius: 1rem;
    background-color: $bg_main;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    max-height: 80dvh;
    overflow-y: auto;

    .information {
      font-size: 1rem;
      line-height: 1.4375rem;
      text-align: start;
      color: $gray-text-lighter;
      height: 100%;
      overflow-y: auto;

      span {
        font-weight: 600;
        line-height: 1.25rem;
      }

      ul {
        padding-left: 1rem;
      }
    }

    .consentCheckbox {
      div {
        border: 0;
        padding: 0;
        text-align: start;
        font-size: 0.875rem;
        line-height: 1.25rem;
      }
    }

    .TherapistParty {
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.375rem;
      text-align: start;

      span {
        font-weight: 600;
      }
    }

    .inputWrapper {
      display: flex;
      flex-direction: column;
      gap: 1.25rem;

      .inputRow {
        display: flex;
        justify-content: space-between;
        gap: 0.5rem;

        div {
          width: 100%;
          box-sizing: border-box;
          text-align: start;
        }

        .smallerInput {
          width: 30%;
        }
      }
    }

    .btnWrapper {
      display: flex;
      gap: 0.75rem;

      button {
        width: 100%;
      }
    }
  }

  .title {
    font-size: 2rem;
    font-weight: 600;
    line-height: 2.375rem;
    text-align: start;
    margin-bottom: 0.5rem;
  }

  .preferencesContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1.5rem;
    border-radius: 1rem;
    background-color: $gray_bg;

    .consensInfo {
      z-index: 1;
      background-color: $bg_main;
    }

    .prefernce {
      text-align: start;

      .preferenceTitle {
        font-size: 1.25rem;
        font-weight: 600;
        line-height: 1.5rem;
        margin-bottom: 0.5rem;
      }

      .preferenceDescription {
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.25rem;
      }
    }

    .btnWrapper {
      display: flex;
      gap: 1rem;
    }
  }

  .changePreferences {
    width: 40%;
    padding: 2.5rem;
    background-color: $gray-6;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    .description {
      font-size: 0.875rem;
      line-height: 1.25rem;
      text-align: start;
      color: $gray-text-lighter;
    }

    .btnWrapper {
      display: flex;
      gap: 0.75rem;

      button {
        width: 100%;
      }
    }
  }
}

@media (max-width: $tabletBreakpoint) {
  .userPreferences {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 1.25rem;
    // padding-bottom: 3.125rem;
    flex-grow: 1;
    width: calc(100% - 2.5rem);

    .informedConsent {
      width: 100%;
      max-height: calc(100% - 4.375rem);
      height: fit-content;
      overflow-y: auto;
      margin: 0.5rem;
      padding: 1.5rem 1rem;
      flex: 1;

      .information {
        font-size: 1rem;
        line-height: 1.4375rem;
        text-align: start;
        color: $gray-text-lighter;

        span {
          font-weight: 600;
          line-height: 1.25rem;
        }

        ul {
          padding-left: 1rem;
        }
      }

      .consentCheckbox {
        div {
          height: auto;
          border: 0;
          padding: 0;
          text-align: start;
          font-size: 0.875rem;
          line-height: 1.25rem;
        }
      }

      .TherapistParty {
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.375rem;
        text-align: start;

        span {
          font-weight: 600;
        }
      }

      .inputWrapper {
        display: flex;
        flex-direction: column;
        gap: 1.25rem;

        .inputRow {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          gap: 0.5rem;

          div {
            width: 100%;
            box-sizing: border-box;
            text-align: start;
          }

          .smallerInput {
            width: 100%;
          }
        }
      }

      .btnWrapper {
        display: flex;
        flex-direction: column-reverse;
        gap: 0.75rem;

        button {
          width: 100%;
        }
      }
    }

    .title {
      display: none;
      font-size: 2rem;
      font-weight: 600;
      line-height: 2.375rem;
      text-align: start;
      margin-bottom: 0.5rem;
    }

    .preferencesContainer {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      padding: 1.5rem;
      border-radius: 1rem;
      background-color: $gray_bg;

      .consensInfo {
        background-color: $bg_main;
      }

      .prefernce {
        text-align: start;

        .preferenceTitle {
          font-size: 1.25rem;
          font-weight: 600;
          line-height: 1.5rem;
          margin-bottom: 0.5rem;
        }

        .preferenceDescription {
          font-size: 0.875rem;
          font-weight: 400;
          line-height: 1.25rem;
        }
      }

      .btnWrapper {
        display: flex;
        flex-direction: column;
        gap: 1rem;
      }
    }

    .changePreferences {
      width: 100%;
      margin: 0 0.5rem;
      padding: 2.5rem 1rem;
      background-color: $gray-6;
      border-radius: 1rem;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;

      .description {
        font-size: 0.875rem;
        line-height: 1.25rem;
        text-align: start;
        color: $gray-text-lighter;
      }

      .btnWrapper {
        display: flex;
        flex-direction: column-reverse;
        gap: 0.75rem;

        button {
          width: 100%;
        }
      }
    }
  }
}

@import "src/index.scss";
main {
  max-width: 100vw;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0 0.5rem;
  @media only screen and (max-width: $tabletBreakpoint) {
    padding: 0 1rem;
  }
}

.ourApproach {
  @media (max-width: 81.25rem) {
    margin: 0 3.125rem;
  }
  @media (max-width: $mobileBreakpoint) {
    margin: 0 auto;
  }
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  position: relative;
  * {
    text-align: initial;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  margin: 0 auto;
  max-width: 85rem;
  justify-content: center;
  box-sizing: border-box;
  background-color: white;

  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 85rem;
    margin: 0 auto;
  }

  @media (max-width: $mobileBreakpoint) {
	h3 {
      font-size: 2.25rem !important;
	}
  }
  > [class*="IntroductorySection"] {
    h1 {
      text-align: center;
    }
    max-width: 100%;
    > [class*="Card"] {
      @media (max-width: $mobileBreakpoint) {
        padding-bottom: 0 !important;
      }
      > [class*="TextContainer"] {
        width: clamp(23.4375rem, 83%, 62.1875rem);
      }
    }

    > [class*="stain"]:nth-last-child(2) {
      width: 10%;
      right: 1rem;
      bottom: 1rem;
      @media only screen and (max-width: $tabletBreakpoint) {
        width: 25.3%;
        max-width: 5.9375rem;
        bottom: 3.125rem;
        right: -2.5rem;
      }
    }
    > [class*="stain"]:last-child {
      width: 5rem;
      top: 3.75rem;
      left: -4.5%;
      bottom: 1rem;
      width: 5rem;
      @media only screen and (max-width: $tabletBreakpoint) {
        top: 3.125rem;
        left: -3.75rem;
      }
    }
    @media only screen and (max-width: $tabletBreakpoint) {
      > [class*="Card"] {
        > [class*="TextContainer"] {
          width: 100%;
        }
      }
    }
  }
  > [class*="Footer"] {
    margin: 0 auto;
    margin-bottom: 1.5rem;
    padding: 2.5rem 4.25rem;
    @media only screen and (max-width: $tabletBreakpoint) {
      padding: 1.25rem;
    }
  }
}

@import "src/index.scss";

.faqContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  .faq {
    height: calc(100% - 7rem);
    overflow-y: auto;
    padding: 3.5rem 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 2.25rem;
    background-color: $bg_main;
    border-radius: 1rem;
    .title {
      font-size: 2rem;
      font-weight: 600;
      line-height: 2.375rem;
      text-align: start;
    }
    .subtitle {
      font-size: 1.25rem;
      font-weight: 600;
      line-height: 1.5rem;
      text-align: start;
      margin-bottom: 1.5rem;
    }
    .faqContainer {
      .faqList {
        font-size: 1.125rem;
        font-weight: 500;
        line-height: 1.5625rem;
        text-align: left;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        button {
          width: 100%;
          height: 3.1875rem;
          justify-content: space-between;
          border-bottom: 0.09375rem solid $gray_bg;
          text-align: start;
        }
      }
    }
  }
  .faqAnswer {
    height: 100%;
    padding: 3.5rem 1.5rem;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 2.25rem;
    background-color: $bg_main;

    .backBtn {
      color: $primary-1_dark;
      text-decoration: underline;
      justify-content: start;
      padding: 0;
      [class*="icon"]{
        margin-left: 0;
      }
    }
    .answerContainer {
      p {
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.625rem;
      }
      .actualQuestion {
        font-size: 1.5rem;
        font-weight: 600;
        line-height: 1.8125rem;
        text-align: start;
        margin-bottom: 1.5rem;
      }
      .actualAnswer {
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.625rem;
        text-align: left;
        width: 70%;
      }
    }
    .contactUsInfoBox {
      background-color: $gray_bg;
      padding: 1.25rem;
      border-radius: 1.25rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      h6 {
        font-size: 1.25rem;
        font-weight: 600;
        line-height: 1.5rem;
        text-align: left;
      }
    }
  }
  .contactUsForm {
    background-color: $bg_main;
    padding: 2.5rem 2rem;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    gap: 2.125rem;
  }

  .contactUs {
    .inputContainer {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      button {
        width: fit-content;
      }
    }
  }
}

@media (max-width: $tabletBreakpoint) {
  .faqContainer {
    width: 100%;
    .faq {
      height: 80vh;
      padding: 15vh 1.25rem 10vh;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      gap: 2.25rem;
      background-color: $bg_main;
      border-radius: 1rem;
      .title {
        display: none;
        font-size: 2rem;
        font-weight: 600;
        line-height: 2.375rem;
        text-align: start;
      }
      .subtitle {
        font-size: 1.25rem;
        font-weight: 600;
        line-height: 1.5rem;
        text-align: start;
        margin-bottom: 1.5rem;
      }
      .faqContainer {
        .faqList {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
          button {
            font-size: 0.875rem;
            font-weight: 500;
            line-height: 1.375rem;
            text-align: left;
            width: 100%;
            height: 3.1875rem;
            justify-content: space-between;
            border-bottom: 0.09375rem solid $gray_bg;
            svg {
              height: 1.25rem;
            }
          }
        }
      }
    }
    .faqAnswer {
      height: 85vh;
      padding: 10vh 1.25rem 5vh;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      gap: 2.25rem;
      background-color: $bg_main;
      .backBtn {
        color: $primary-1_dark;
        text-decoration: underline;
        justify-content: start;
      }
      .answerContainer {
        .actualQuestion {
          font-size: 1.5rem;
          font-weight: 600;
          line-height: 1.8125rem;
          text-align: start;
          margin-bottom: 1.5rem;
        }
        .actualAnswer {
          font-size: 1rem;
          font-weight: 400;
          line-height: 1.625rem;
          text-align: left;
          width: 100%;
        }
      }
      .contactUsInfoBox {
        background-color: $gray_bg;
        padding: 1.25rem;
        border-radius: 1.25rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 1.25rem;
        h6 {
          font-size: 1.25rem;
          font-weight: 600;
          line-height: 1.5rem;
          text-align: center;
        }
        button {
          width: 100%;
        }
      }
    }
    .contactUsForm {
      background-color: $bg_main;
      padding: 2.5rem 2rem;
      border-radius: 1rem;
      display: flex;
      flex-direction: column;
      gap: 2.125rem;
    }

    .contactUs {
      .inputContainer {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        button {
          width: fit-content;
        }
      }
    }
  }
}

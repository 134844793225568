@import "src/index.scss";

.survey {
  background-color: $blue_bg;
  box-sizing: content-box;
  // height: 90vh;
  background-color: white;
  overflow-y: auto;
}

@media (max-width: $tabletBreakpoint) {
  .survey {
    background-color: $gray-6;
  }
}

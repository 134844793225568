@import "src/index.scss";

form {
  .billingDetailsForm {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
    gap: 0.9375rem;

    .billingDetailsInputContainer {
      display: flex;
      flex: 1;
      justify-content: space-between;
      gap: 0.9375rem;
    }

    .billingDetailsInput {
      width: 100%;
    }

    .infoPaymentText {
      color: #4F9C8D;
      font-size: 0.875rem;
      line-height: 1.375rem;
    }

    .dateWrapper {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;

      .label {
        font-size: 0.875rem;
        text-align: start;
        color: $gray-3;

        &.errorLabel {
          color: $error-text
        }
      }
    }
  }

  .expensesDeductionCheckboxContainer {
    display: flex;
    flex-direction: row;
    align-items: start;
    gap: 0.75rem;
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;

    .boldText {
      color: $gray-text-lighter;
      font-size: 1rem;
      font-weight: 600;
      line-height: 1.375rem;
    }

    .checkboxText {
      color: $gray-text-lighter;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.375rem;
    }
  }

  .formButtonsContainer {
    margin-right: 1.5rem;
    display: flex;
    flex-direction: row;
    justify-content: end;
    gap: 0.75rem;
  }

}

@media (max-width: $tabletBreakpoint) {

  form {
    .billingDetailsForm {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: space-between;
      gap: 0.9375rem;

      .billingDetailsInputContainer {
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: space-between;
        gap: 0.9375rem;
      }

      .billingDetailsInput {
        width: 100%;
      }
    }

    .expensesDeductionCheckboxContainer {
      display: flex;
      flex-direction: row;
      align-items: start;
      gap: 0.75rem;
      margin-top: 1.25rem;
      margin-bottom: 1.25rem;

      .checkboxText {
        color: $gray-text-lighter;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.375rem;
      }
    }

    .formButtonsContainer {
      display: flex;
      flex-direction: column-reverse;
      justify-content: end;
      gap: 0.75rem;
      margin-right: 0;

      &.modal {
        position: sticky;
        bottom: -1.125rem;
        margin-left: -1rem;
        width: calc(100% + 2rem);
        padding: 1rem 0.625rem;
        box-sizing: border-box;
        border: 0.0625rem solid rgb(223, 234, 255);
        border-radius: 0.3125rem;
        background-color: $gray-6;
        z-index: 100;
      }
    }
  }

}
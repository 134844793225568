@import "src/index.scss";

.simpleSection {
  margin-top: 5rem;
  > [class*="Card"] {
    padding: 2.5rem;
    background-color: #e7e1fe;

    > div[class*="TextContainer"] {
      width: clamp(31.75rem, 42.33%, 37.5rem);

      h3,
      h4,
      h6 {
        margin-bottom: 1rem;
      }

      h6 {
        font-size: 1rem;
      }

      p:nth-of-type(2) {
        margin-top: 2rem;
        margin-bottom: 2.5rem;
      }
    }
    @media only screen and (max-width: $tabletBreakpoint) {
      padding: 2rem 1.25rem;
      > div[class*="TextContainer"] {
        width: clamp(31.75rem, 42.33%, 37.5rem);
        h3 {
          font-size: 2.25rem;
        }
      }
    }
  }

  &.onlineTherapy {
    margin-bottom: 10rem;
    > [class*="Card"] {
      justify-content: space-between;
      background-color: #f9f8ff;
      > div[class*="ImageContainer"] {
        z-index: 4;
        > div {
          margin: auto 0;
          z-index: 3;
        }
        [class*="stain"] {
          width: 6.875rem;
          z-index: 2;
          right: -3.625rem;
          bottom: 3.75rem;
        }
      }
      span {
        color: $gray-1;
      }
    }
    @media only screen and (max-width: $tabletBreakpoint) {
      > div[class*="Card"] {
        flex-direction: column;
        justify-content: center;
        > div[class*="TextContainer"] {
          width: 100%;
          margin-bottom: 1.5rem;
        }
        > div[class*="ImageContainer"] {
          margin: 0 auto;
          width: auto;
          max-width: none;
        }
      }
    }
  }

  &.ourHistory {
    margin-bottom: 6.5rem;
    > [class*="Card"] {
      justify-content: space-between;
      flex-wrap: wrap;
      background-color: $secondary-1;
      > [class*="TextContainer"] {
        width: clamp(18.75rem, 48%, 31.75rem);
        justify-content: center;
        p:nth-of-type(2) {
          margin-bottom: 0;
        }
      }

      > [class*="ImageContainer"] {
        img {
          border-radius: 1rem;
          z-index: 99;
        }
        
        @media only screen and (max-width: $tabletBreakpoint) {
          max-width: none;
          img {
            z-index: 1;
          }
        }
        [class*="stain"] {
          width: 23.17%;
          top: -2.625rem;
          right: -3.75rem;
          z-index: 90;
          @media only screen and (max-width: $tabletBreakpoint) {
            display: none;
          }
        }
      }
    }

    @media only screen and (max-width: $tabletBreakpoint) {
      > [class*="Card"] {
        > [class*="TextContainer"] {
          width: 100%;
          justify-content: center;
          p:nth-of-type(2) {
            margin-bottom: 0;
          }
          margin-bottom: 1.5rem;
        }
        > [class*="ImageContainer"] {
          width: 100%;
          div {
            width: 100%;
          }
        }
      }
    }
  }

  &.doubleCard {
    max-width: 75rem;
    margin-bottom: 5rem;
    justify-content: space-between;
    gap: 2rem;
    flex-wrap: nowrap;
    > [class*="Card"] {
      justify-content: space-between;
      background-color: $gray_bg;
      &:last-child {
        background-color: #e7e1fe;
      }
      > div[class*="ImageContainer"] {
        img {
          width: 100%;
          height: 100%;
        }
      }

      > div[class*="TextContainer"] {
        width: 100%;

        h4 {
          margin-top: 2.5rem;
          margin-bottom: 1rem;
        }

        p {
          margin-bottom: 2.5rem;
        }
        span {
          color: $gray-1;
        }
      }
    }

    @media only screen and (max-width: $tabletBreakpoint) {
    flex-wrap: wrap;
    margin-bottom: 2.5rem;
      > div[class*="Card"] {
        width: 100%;
        margin-bottom: 1.5rem;
        > div[class*="ImageContainer"] {
          img {
            height: auto;
            width: auto;
          }
          margin: 0;
          justify-content: flex-start;
        }
        h4 {
          font-size: 1.5rem;
        }
      }
    }
  }

  &.ourStructure {
    margin-bottom: 3rem;
    > [class*="Card"] {
      min-height: 34.375rem;
      justify-content: space-between;
      padding: 2.5625rem clamp(2.5625rem, 12%, 9.5rem) 2.5625rem 2.5rem;
      background-color: #f9f8ff;
      > div[class*="TextContainer"] {
        order: 1;
        width: clamp(31.75rem, 42.33%, 37.5rem);
        justify-content: center;
        margin-left: 5.0625rem;
        h4,
        p,
        > div:first-of-type {
          margin-bottom: 1.25rem;
        }
        h6 {
          margin-bottom: 0;
        }
        ul {
          padding-left: 1.5rem;
          list-style: disc;
          li:not(:last-child) {
            margin-bottom: 0.125rem;
          }
        }
        [class*="stain"] {
          display: none;
          width: 3.4375rem;
          top: 11.8125rem;
          right: -1.75rem;
          @media only screen and (max-width: $tabletBreakpoint) {
            display: block;
          }
        }
      }
      > div[class*="ImageContainer"] {
        position: relative;
        max-width: 26.1875rem;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: end;
        > div {
          width: 12.625rem;
          &:first-child,
          &:nth-child(2) {
            margin-bottom: 0.9375rem;
          }
          &:nth-child(3),
          &:nth-child(4) {
            margin-bottom: auto;
          }
          &:nth-child(odd) {
            transform: translateY(-1.5rem);
          }
          &:nth-child(even) {
            transform: translateY(1.25rem);
          }
        }
        [class*="stain"] {
          width: clamp(10.625rem, 60%, 14.9375rem);
          top: 20rem;
          left: 4.4375rem;
          @media only screen and (max-width: $tabletBreakpoint) {
            width: 56.7%;
            top: initial;
            left: 3.1875rem;
            bottom: 1.75rem;
          }
        }
      }

      > img[class*="stain"] {
        width: 5rem;
        top: 16.875rem;
        right: -1.875rem;
        @media only screen and (max-width: $tabletBreakpoint) {
          display: none;
        }
      }

      @media only screen and (max-width: $tabletBreakpoint) {
        padding: 2rem 1.25rem;
        flex-wrap: wrap;
        > div[class*="TextContainer"] {
          width: 100%;
          margin-left: 0;
          h4 {
            font-size: 1.5rem;
          }
        }
        > div[class*="ImageContainer"] {
          width: 100%;
          margin: 0 auto 1.5rem auto;
          justify-content: center;
          > div {
            width: clamp(5rem, 48%, 13.75rem);
            &:first-child {
              margin-top: 1.5rem;
            }
            &:first-child,
            &:nth-child(3) {
              margin-bottom: 0.9375rem;
              margin-right: 0.5rem;
            }
          }
        }
      }
    }
  }

  &.labels {
    max-width: 75rem;
    margin-bottom: 5rem;

    > [class*="textContainer"] {
      width: 100%;

      ul {
        display: flex;
        flex-wrap: wrap;
        justify-self: center;

        li {
          cursor: pointer;
          background-color: $gray_bg;
          padding: 0.875rem 1rem;
          margin: 0.5rem;
          border-radius: 3.375rem;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    @media only screen and (max-width: $tabletBreakpoint) {
      width: 100%;
      margin-bottom: 3.0625rem;
      > [class*="textContainer"] {
        margin-right: auto;
        > div {
          overflow-x: auto;
          white-space: nowrap;
          ul {
            flex-wrap: nowrap;
          }
        }
      }
    }
  }
}

@import "src/index.scss";
$backgroundImage: url("../../../../../public/assets/images/landingPageImgs/bgCarousel.png");

section.reviewsContainer {
	margin-bottom: 2.5rem;
	max-width: 85rem;
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	> div:first-child {
		display: flex;
		width: 100%;
	}
	.columns {
		display: flex;
		width: clamp(63rem, 90%, 75.8125rem);
		margin: 0 auto;
		background-image: $backgroundImage;
		background-position: bottom;
		background-repeat: no-repeat;
		> .reviews {
			flex-wrap: nowrap;
			margin: 0 auto;
			width: clamp(18.75rem, 32%, 24.1875rem);
			display: flex;
			flex-direction: column;
			align-items: center;

			div[class*="cardReview"] {
				width: 100%;
				background-color: white;
				margin: 0.75rem;

				[class*="reviewTitle"] {
					display: contents;
				}

				box-shadow: 0rem 0.25rem 3.375rem 0rem #0000000f;
			}

			&:first-child,
			&:last-child {
				margin-top: 3.8125rem;
			}
		}
	}

	> div:last-child {
		display: flex;
		span {
			cursor: pointer;
			margin: 3.25rem auto;
			text-decoration: underline;
		}
	}

	@media only screen and (max-width: $tabletBreakpoint) {
		.columns {
			flex-direction: column;
			> .reviews {
				width: 100%;
				div[class*="cardReview"] {
					width: 100%;
					background-color: white;
					margin: 0.75rem;

					[class*="reviewTitle"] {
						display: contents;
					}

					box-shadow: 0rem 0.25rem 3.375rem 0rem #0000000f;
				}

				&:first-child,
				&:last-child {
					margin-top: 0;
				}
			}
		}
	}
}

@import "src/index.scss";

.informedConsent {
  background-color: $blue_bg;
  display: flex;
  justify-content: center;
  align-items: start;
  padding: 1.25rem 0.625rem;
  padding-top: calc(10vh + 2.5rem);

  .hiddenButton {
    display: none;
  }
  .datePicker {
    display: block;
    width: auto !important;

    .label {
      font-size: 0.875rem;
      text-align: start;
      color: $gray-3;
    }
    &.error {
      .ant-picker {
        border-color: $error-border !important;
        border-width: 0.125rem !important;
        background-color: $error-background;
        color: $error-text;
      }
      .label {
        color: $error-text;
      }
    }
  }
  .informedConsentResult {
    background-color: white;
    max-width: 31.25rem;
    width: 100%;
  }

  .informedConsentForm {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    background-color: white;
    max-width: 46.875rem;
    padding: 1.25rem;
    border-radius: 1.25rem;
    width: 100%;
    text-align: left;

    .TherapistParty {
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.375rem;
      text-align: start;
      span {
        font-weight: 600;
      }
    }
    .inputWrapper {
      display: flex;
      flex-direction: column;
      gap: 1.25rem;
      .inputRow {
        display: flex;
        justify-content: space-between;
        gap: 0.5rem;
        div {
          width: 100%;
          box-sizing: border-box;
          text-align: start;
        }
        .smallerInput {
          width: 30%;
        }
      }
    }
    .consentCheckbox {
      div {
        &:focus-within {
          outline: 0;
        }
        border: 0;
        padding: 0;
        text-align: start;
        font-size: 0.875rem;
        line-height: 1.25rem;
      }
    }
    .btnWrapper {
      display: flex;
      gap: 0.75rem;
      button {
        width: 100%;
      }
    }
  }
}

@media (max-width: $tabletBreakpoint) {
  .informedConsent {
    padding: 1.25rem 0.625rem;
    padding-top: 5vh;
    padding-top: calc(10vh + 2.5rem);
    align-items: start;

    .informedConsentForm {
      display: flex;
      flex-direction: column;
      gap: 1.25rem;
      background-color: white;
      padding: 1.25rem;
      border-radius: 1.25rem;
      text-align: left;

      .TherapistParty {
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.375rem;
        text-align: start;
        span {
          font-weight: 600;
        }
      }
      .inputWrapper {
        display: flex;
        flex-direction: column;
        gap: 1.25rem;
        .inputRow {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          gap: 0.5rem;
          div {
            width: 100%;
            box-sizing: border-box;
            text-align: start;
          }
          .smallerInput {
            width: 100%;
          }
        }
      }
      .btnWrapper {
        display: flex;
        flex-direction: column-reverse;
        gap: 0.75rem;
        button {
          width: 100%;
        }
      }
    }
  }
  form {
    overflow-y: unset !important;
  }
}

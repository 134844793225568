@import "src/index.scss";

.logoContainer {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  .logo {
    width: 11rem;
    height: 3.6875rem;
    margin-bottom: 1.5rem;
  }
}

.information {
  padding: 10vh 1rem;
  font-size: 1rem;
  line-height: 1.4375rem;
  text-align: start;
  border-bottom: 0.0625rem solid $gray-5;
  color: $gray-text-lighter;
  height: 100%;
  overflow-y: auto;

  span {
    font-weight: 600;
    line-height: 1.25rem;
  }

  ul {
    padding-left: 1rem;
  }

  .buttonWrapper {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
    margin-right: 1rem;

    a {
      color: #101010;
      text-decoration: none;
    }
  }
}

.TherapistParty {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.375rem;
  text-align: start;

  span {
    font-weight: 600;
  }
}


@media (max-width: $tabletBreakpoint) {
  .information {
    padding: 10vh 1rem;
    font-size: 1rem;
    line-height: 1.4375rem;
    text-align: start;
    border-bottom: 0.0625rem solid $gray-5;
    color: $gray-text-lighter;
    height: 100%;
    overflow-y: auto;

    span {
      font-weight: 600;
      line-height: 1.25rem;
    }

    ul {
      padding-left: 1rem;
    }
  }
  .TherapistParty {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.375rem;
    text-align: start;

    span {
      font-weight: 600;
    }
  }
}
@import "src/index.scss";


  .loadingModal {
    z-index: 9999;
    background-color: red;
    color: $bg_main;
  }
  .bottomPart {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .subtitle {
      font-size: 0.875rem;
      line-height: 1.25rem;
      font-weight: 400;
      text-align: start;
      span {
        font-weight: 600;
      }
    }
    .calendarContainer {
      display: flex;
      gap: 3.1875rem;
      .hoursContainer {
        height: 100%;
        max-height: 17.75rem;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        .selectedDay {
          text-transform: capitalize;
        }
        .hoursWrapper {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
          overflow-y: auto;
          button {
            width: 100%;
          }
          .bookedBtn {
            opacity: 100%;
            background-color: $gray-4;
            color: $gray-6;
            display: flex;
            flex-direction: column;
            padding: 0.53125rem 0;
            box-sizing: content-box;
          }
          .freeHourBtn {
            &.hourSelected {
              background-color: $secondary-1;
            }
          }
        }
        .goDown {
          height: 1.25rem;
          width: 1.25rem;
          margin: 0 auto;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
  .firstAppointmentMessage {
    padding: 0.625rem 0.75rem;
    border-radius: 0.75rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 400;
    text-align: start;
    background-color: $gray_bg;
  }
  .btnWrapper {
    display: flex;
    button:first-child {
      width: 20%;
    }
    button:last-child {
      width: 100%;
    }
  }

  .emptyBlock {
    height: 2.5rem;
  }

@media (max-width: $tabletBreakpoint) {

    .hideContainer {
      display: none !important;
    }

    .loadingModal {
      z-index: 9999;
      background-color: red;
      color: $bg_main;
    }
    .bottomPart {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      .subtitle {
        font-size: 0.875rem;
        line-height: 1.25rem;
        font-weight: 400;
        text-align: start;
        span {
          font-weight: 600;
        }
      }
      .calendarContainer {
        .dateContainer {
          width: 100%;
        }
        .hoursContainer {
          width: 100%;
          display: flex;
          flex-direction: column;
          margin-bottom: 1rem;
          .selectedDay {
            text-transform: capitalize;
          }
          .hoursWrapper {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            overflow-y: auto;
            button {
              width: 100%;
            }
            .bookedBtn {
              opacity: 100%;
              background-color: $gray-4;
              color: $gray-6;
              display: flex;
              flex-direction: column;
              padding: 0.53125rem 0;
              box-sizing: content-box;
            }
            .freeHourBtn {
              &.hourSelected {
                background-color: $secondary-1;
              }
            }
          }
          .goDown {
            height: 1.25rem;
            width: 1.25rem;
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
    .firstAppointmentMessage {
      padding: 0.625rem 0.75rem;
      border-radius: 0.75rem;
      font-size: 0.875rem;
      line-height: 1.25rem;
      font-weight: 400;
      text-align: start;
      background-color: $gray_bg;
    }
    .btnWrapper {
      display: flex;
      flex-direction: column-reverse;
      gap: 1.25rem;
      button:first-child {
        width: 100%;
      }
      button:last-child {
        width: 100%;
      }
    }
  }
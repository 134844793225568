@import "src/index.scss";

.docSheet {
  height: 100%;

  .docCard {
    background-color: $gray-6;
    max-height: 25%;
    height: 100%;
  }
  .tabs {
    background-color: $gray-6;
    height: 100%;
    max-height: calc(75% - 6rem);
    margin-top: 1rem;
    padding-top: 1rem;
    [class*="active"] {
      cursor: default;
    }

    .tabsWrapper {
      border-bottom: 0.0625rem solid $gray-6;
      background: transparent;
    }

    .activeLabel {
      background: transparent;
      border-bottom: 0.0625rem solid $gray-5;
      border-radius: unset;
      font-size: 1.5rem;
      font-weight: 600;
    }
  }
}

@media (max-width: $tabletBreakpoint) {
  .docSheet {
    .tabs {
      margin: 0;
      padding: 0;
      max-height: calc(75% - 4rem);
    }
  }
}

@import "src/index.scss";

.genericForm {
  box-shadow: 0rem 0.25rem 3.375rem 0rem #0000000f;
  text-align: start;
  border: 0.0625rem solid $gray-5;
  border-radius: 1rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  .card {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    .description {
      font-size: 1.125rem;
    }
  }
  .answersWrapper {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    align-items: center;
    .answer {
      width: 100%;
    }
  }
  .btnWrapper {
    display: flex;
    div {
      width: 100%;
      button {
        width: 100%;
      }
    }
  }
}

@media (max-width: $tabletBreakpoint) {
  .genericForm {
    box-shadow: 0rem 0.25rem 3.375rem 0rem #0000000f;
    text-align: start;
    border: 0.0625rem solid $gray-5;
    border-radius: 1rem;
    padding: 1rem;
    .card {
      display: flex;
      flex-direction: column;
      gap: 0.75rem;
      .title {
        font-size: 1.5rem;
      }
      .description {
        font-size: 1rem;
      }
    }
    .answersWrapper {
      display: flex;
      flex-direction: column;
      gap: 0.75rem;
      align-items: center;
      .answer {
        width: 100%;
      }
    }
  }
}

@import "src/index.scss";

.changePreferences {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  .description {
    font-size: 0.875rem;
    line-height: 1.25rem;
    text-align: start;
    color: $gray-text-lighter;
  }

  .radioWrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;


    label {
      cursor: pointer;
      padding: 0.5rem 0.5rem;
      border: 0.09375rem solid #dfeaff;
      border-radius: 0.5rem;
      text-align: start;
      display: flex;
      align-items: center;
      gap: 0.5rem;

      &.checked {
        outline: 0.125rem solid;
        outline-color: $primary-1;
      }
    }
  }

  .btnWrapper {
    display: flex;
    gap: 0.75rem;

    button {
      width: 100%;
    }
  }
}

@media (max-width: $tabletBreakpoint) {

  .changePreferences {
    width: auto;
    padding: 0;

    .description {
      font-size: 0.875rem;
      line-height: 1.25rem;
      text-align: start;
      color: $gray-text-lighter;
    }

    .btnWrapper {
      display: flex;
      flex-direction: column-reverse;
      gap: 0.75rem;

      button {
        width: 100%;
      }
    }
  }
}

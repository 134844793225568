@import "src/index.scss";

.mobileHeader {
  display: none;
}

@media (max-width: $tabletBreakpoint) {
  .mobileHeader {
    position: fixed;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
    padding: 0 1.25rem;
    box-sizing: border-box;
    height: 4.375rem;
    width: 100vw;
    z-index: 9;
    border-bottom: 0.0625rem solid #c5c5c5;
    background-color: $bg_main;

    .iconWrapper {
      height: 2rem;
      width: 2rem;
      padding: 0.5rem;
      color: #808093;
      cursor: pointer;
      background-color: $secondary-1;
      border-radius: 100%;
    }

    .logo {
      height: 2.8125rem;
      cursor: pointer;
    }

    .therapistTabWrapper {
      min-width: 6.25rem;

      .therapistTab {
        position: fixed;
        top: 0.75rem;
        right: 0rem;
        z-index: 9;

        .customLabelWrapper {
          border-radius: 0.75rem;
          gap: 0.375rem;
        }

        .customActiveLabel {
          background-color: transparent;
          border: 0.0625rem solid;
          border-radius: 0.75rem;
        }

        .tabLabel {
          display: flex;
          align-items: center;
          gap: 0.25rem;

          .icon {
            width: 1.5rem;
            height: 1.5rem;
          }

          span {
            display: none;
          }
        }
      }
    }

    &.incraseZindex {
      z-index: 10;
    }

    .openMobileMenu,
    .closeMobileMenu {
      display: flex;
      transition: transform 0.4s ease;
      top: 0;
      left: 0;
    }

    .openMobileMenu {
      transform: translateX(0);
    }

    .closeMobileMenu {
      transform: translateX(-100%);
    }

    .menuIconWrapper {
      position: relative;
      cursor: pointer;
      .icon {
        border-bottom: 0.125rem solid;
        border-top: 0.125rem solid;
        height: 0.375rem;
        margin-bottom: 0.375rem;
      }
      .word {
        font-weight: 600;
        font-size: 0.875rem;
      }
    }
    .username {
      font-weight: 600;
      text-align: center;
    }

    .profileIcon {
      height: 2.75rem;
      width: 2.75rem;
    }
  }
}

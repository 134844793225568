@import "src/index.scss";

.chip {
  margin-left: 0.0625rem;
  background-color: $gray_bg;
  color: $gray-2;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 0.875rem;
  border-radius: 3.375rem;
  width: fit-content;
  cursor: pointer;

  &.small {
    padding: 0.4375rem 1rem;
  }

  &.medium {
    padding: 0.6875rem 1rem;
  }

  &.large {
    padding: 0.875rem 1rem;
    font-size: 1rem;
    line-height: 1rem;
  }

  &.active {
    outline: 0.0625rem solid $gray-1;
    color: $gray-1;
  }
}

@import "../../../index.scss";

.therapistPayment {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 2rem 1.5rem;

  .message {
    text-align: left;
    font-size: 0.875rem;
    color: $gray-text-lighter;
  }
  .loadingSpinner {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      font-size: 1.5rem;
    }
    span {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .title {
    font-size: 2rem;
    font-weight: 600;
    line-height: 2.375rem;
    text-align: start;
    margin-bottom: 1.5rem;
  }
  .paymentContainer {
    height: calc(100% - 4rem);
    .topPart {
      margin-bottom: 2rem;
      .availableBalance {
        background-color: $bg_confirm_color;
        color: $writing_confirm_color;
        width: fit-content;
        padding: 0.625rem;
        border-radius: 0.75rem;
        display: flex;
        flex-direction: column;
        gap: 0.3125rem;
        text-align: left;
        margin-bottom: 1rem;
        span {
          font-size: 0.8125rem;
          font-weight: 600;
          line-height: 1rem;
          text-transform: uppercase;
        }
        h6 {
          line-height: 1.5rem;
          font-weight: 600;
        }
      }
      .infoBox {
        width: fit-content;
        .infoWithLink {
          a {
            cursor: pointer;
            font-weight: 700;
            text-decoration: underline;
            color: $gray-1;
            &:visited {
              color: inherit;
            }
          }
          span {
            cursor: pointer;
            font-weight: 700;
            text-decoration: underline;
            color: $gray-1;
            &:visited {
              color: inherit;
            }
          }
        }
      }

      .infoBoxWarning {
        margin-bottom: 0.625rem;
      }
      .infoBoxWarningNote {
        background-color: $bg_warning_color;
        color: $writing_warning_color
      }
    }
    .bottomPart {
      display: flex;
      flex-direction: column;
      height: 70%;
      .paymentHeader {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 1.25rem;
        .title {
          font-size: 1.5rem;
          font-weight: 600;
          line-height: 1.8125rem;
          text-align: left;
        }
        .myMentisInvoicing {
          font-size: 1rem;
          font-weight: 600;
          line-height: 1.25rem;
          text-decoration: underline;
        }
      }
      .paymentList {
        display: flex;
        flex-direction: column;
        gap: 1.25rem;
        height: 100%;
        overflow-y: auto;
        .paymentCard {
          border-bottom: 0.09375rem solid $gray-5;
          .paymentPeriod {
            text-transform: uppercase;
            text-align: start;
            font-size: 0.8125rem;
            font-weight: 600;
            line-height: 1rem;
          }
          .paymentDetail {
            display: flex;
            justify-content: space-between;
            padding: 1.25rem 1rem;
            .paymentAmmount {
              display: flex;
              flex-direction: column;
              gap: 0.3125rem;
              font-size: 1rem;
              font-weight: 400;
              line-height: 1.375rem;
              span {
                font-size: 0.875rem;
                font-weight: 500;
                line-height: 1.25rem;
                color: $gray-3;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: $tabletBreakpoint) {
  .therapistPayment {
    padding: 1.25rem;
    width: calc(100% - 2.5rem);
    overflow-y: auto;
    .mainTitle {
      display: none;
    }
    .bottomPart {
      height: fit-content !important;
      .paymentList {
        overflow-y: auto !important;
        .paymentDetail {
          flex-direction: column;
          gap: 1.25rem;
          text-align: start;
        }
      }
    }
  }
}

@import "src/index.scss";

.changePassword {
  background-color: #f7fafd;
  padding: 20vh 30vw;
  display: flex;
  justify-content: center;
  .form {
    background-color: $gray-6;
    text-align: center;
    display: flex;
    flex-direction: column;
    height: fit-content;
    gap: 2rem;
    .confirmBtn {
      width: 100%;
    }
  }
  .changePasswordForm {
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
    button {
      margin-top: 0.625rem;
    }
  }
}

.pwInfo {
  text-align: left;
  font-size: 0.75rem;
  ul {
    margin: 0;
    padding-left: 0.75rem;
  }
}

.password {
  position: relative;
  .showPw {
    cursor: pointer;
    color: $primary-1_dark;
    font-weight: 600;
    font-size: 0.75rem;
    position: absolute;
    right: 1rem;
  }
}

@media (max-width: $tabletBreakpoint) {
  .changePassword {
    padding-left: 0;
    padding-right: 0;
    .form {
      width: 100%;
      z-index: 100;
    }
  }
}

@import "src/index.scss";

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    margin: 0;
    font-size: 1.5rem;
    font-weight: 600;
    text-align: start;
  }
  .closeBtn {
    height: 2rem;
    width: 2rem;
    cursor: pointer;
  }
}

@media (max-width: $tabletBreakpoint) {
  .header {
    p {
      font-size: 1.25rem;
      font-weight: 600;
      line-height: 1.5rem;
    }
    .closeBtn {
      width: 2rem;
    }
  }
}
